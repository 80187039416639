import React, { useEffect } from 'react'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box, Button } from 'asimov'
import UserTable from 'components/UserTable/UserTable'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'

const Root = styled.div`
  display: block;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  margin-left: 240px;
  margin-right: 240px;
`

const TableContainer = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
`

export const UserManagementPage = observer(() => {
  const history = useHistory()
  const { userManagementStore } = useStores()

  useEffect(() => {
    userManagementStore.fetchUsers()
  }, [userManagementStore])

  const handleEditUser = (userID) => {
    history.push(`/user-management/edit-user/${userID}`)
  }

  const handleAddUser = () => {
    history.push('/user-management/add-user')
  }

  return (
    <Root>
      <Header type={'userManagement'} />
      <Container>
        <Box>
          <Button
            buttonColor="#3E474F"
            ml="auto"
            mt="20px"
            mb="20px"
            onClick={handleAddUser}
          >
            + Add user
          </Button>
        </Box>
        <TableContainer>
          <UserTable
            users={userManagementStore.users}
            handleEdit={handleEditUser}
          />
        </TableContainer>
      </Container>
    </Root>
  )
})

export default UserManagementPage
