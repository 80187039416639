import React from 'react'
import { Text, Box, Button } from 'asimov'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'
import Loader from 'react-loader-spinner'
import { useHistory } from 'react-router-dom'

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`

const Subtitle = styled.div`
  color: grey;
  font-size: 15px;
  padding-bottom: 10px;
`
const StyledLink = styled(Link)`
  color: grey;
  margin-left: 5px;
`

const RecommendContent = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`

const SimilarRTAs = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
`
const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 90%;
  margin-top: 15px;
  padding: 20px 40px 20px 40px;
  background-color: white;
`

const Score = styled.div`
  margin-left: auto;
  min-width: 45px;
  height: 32px;
  padding: auto 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  background-color: #2a8c82;
  font-size: 14px;
  font-weight: bold;
`

const RecommendedCards = styled.div`
  display: flex;
  flex-direction: column;
`

const ScoringContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45vw;
  margin-right: 48px;
  overflow-y: auto;
`

const StyledUL = styled.ul`
  font-size: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
`
const StyledOL = styled.ol`
  font-size: 20px;
  margin-top: 5px;
  margin-left: 20px;
`
const StyledLI = styled.li`
  margin-top: 4px;
`
const Similarity = () => (
  <Box px={5} border="2px solid" borderColor="#DBDBDB" py={5} pb={4} mx={2}>
    <Text fontWeight="bold" color="#403E3F" fontSize="20px">
      Similarity Scoring
    </Text>{' '}
    <Text color="#403E3F" fontSize={'20px'}>
      Summary & Definitions
    </Text>{' '}
    <Box pl={3} pt={3}>
      <Text pt={1} color="#403E3F" fontSize={'20px'}>
        <strong> Interpreting the Score: </strong> The score ranges from 0 to
        100, wherein:
      </Text>
      <StyledUL>
        <StyledLI>Low Similarity - 0</StyledLI>
        <StyledLI>Moderate Similarity - 50</StyledLI>
        <StyledLI>High Similarity - 100</StyledLI>
      </StyledUL>
      <Text pt={2} color="#403E3F" fontSize={'20px'}>
        <strong> Method: </strong> Similarity is computed by measuring the
        percentage of overlap between two full documents in relation to the
        entire document
      </Text>
      <Text pt={3} color="#403E3F" fontSize={'20px'}>
        <strong> Computation for RTAs with multiple languages:</strong> When
        comparing RTAs with multiple languages, the similarity score first
        checks if the 1st RTA and the 2nd RTA currently viewed in the website
        are in the same official language, English, French, or Spanish.
      </Text>
      <StyledUL>
        <StyledLI>
          If yes, the score is calculated based on that official language
          version.
        </StyledLI>
        <StyledLI>
          If no, the score is based on the English version of the RTAs in the
          following order:
        </StyledLI>
      </StyledUL>
      <StyledOL>
        <StyledLI>Official English version - Official English version</StyledLI>
        <StyledLI>
          Official English version - Translated English version
        </StyledLI>
        <StyledLI>
          Translated English version - Translated English version{' '}
        </StyledLI>
      </StyledOL>
    </Box>
  </Box>
)

export const RecommendationTab = observer(({ rtaId, setTab }) => {
  const history = useHistory()
  const { rtaCompareStore, rtaViewStore } = useStores()

  const handleCompare = (result) => {
    rtaCompareStore.setLang({ label: 'English', value: 'EN' })
    rtaCompareStore.setKeywordMatches1([])
    rtaCompareStore.setKeywordMatches2([])
    history.push(`/compare?rta_id_1=${rtaId}&rta_id_2=${result.rtaID}`)
    setTab(0)
    rtaCompareStore.clearMetaData()
  }

  return (
    <Root>
      <Text color="#3E474F" fontWeight="bold" fontSize="26px" pr={2} pb={2}>
        Recommendations
      </Text>
      <Subtitle>
        <Text>
          The following recommendations are based on the top 10 RTAs with the
          highest full-text similarity scores to
          <strong> {rtaCompareStore.metadata1.name}</strong>. To adjust filters
          entirely,
        </Text>
        <StyledLink to="/">return to search</StyledLink>.
      </Subtitle>
      <RecommendContent>
        <RecommendedCards>
          <Text fontSize="20px" mb={2}>
            10 SIMILAR RTAs
          </Text>
          <SimilarRTAs>
            {rtaViewStore.isSimilarRTAListLoading ? (
              <Box alignItems="center">
                <Loader
                  type="ThreeDots"
                  color="#5E6D8C"
                  height={50}
                  width={50}
                />
              </Box>
            ) : (
              rtaViewStore.similarRTAList.map((result, index) => (
                <ResultContainer key={index}>
                  <Box mr="3" maxWidth={'50%'}>
                    <Text fontSize="20px" color="#3E474F" fontWeight="bold">
                      {result.rtaName}
                    </Text>
                  </Box>
                  <Box marginLeft="auto" display="flex" flexDirection="row">
                    <Button
                      onClick={() => handleCompare(result)}
                      buttonColor="#2a8c82"
                      mr="12px"
                      size="small"
                    >
                      Compare
                    </Button>

                    <Score>{result.score.toFixed(2)}</Score>
                  </Box>
                </ResultContainer>
              ))
            )}
          </SimilarRTAs>
        </RecommendedCards>
        <Box border="1px solid" borderColor="#DBDBDB" mx={5}></Box>
        <ScoringContainer>
          <Similarity />
        </ScoringContainer>
      </RecommendContent>
    </Root>
  )
})

export default RecommendationTab
