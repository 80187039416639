import axios from 'axios'

class RTAMetadataService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }

  getRTAMetadata(rtaID) {
    const metadataEndpoint = `${this.baseURL}/rtametadata/${rtaID}`
    return axios.get(metadataEndpoint, this.baseConfig)
  }

  downloadRTA(rtaID) {
    const metadataEndpoint = `${this.baseURL}/rtametadata/${rtaID}/download`
    return axios.get(metadataEndpoint, this.baseConfig)
  }

  getNewRTAMetadata() {
    const newMetadataEndpoint = `${this.baseURL}/rtametadata/new`
    return axios.get(newMetadataEndpoint, this.baseConfig)
  }

  getIngestedRTAMetadata() {
    const ingestedMetadataEndpoint = `${this.baseURL}/rtametadata/ingested`
    return axios.get(ingestedMetadataEndpoint, this.baseConfig)
  }
  validateRTA(rtaID) {
    const validateEndpoint = `${this.baseURL}/rtametadata/${rtaID}/validate`
    return axios.post(validateEndpoint, {}, this.baseConfig)
  }
}

export default RTAMetadataService
