import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import ProjectRTADocument from '../components/ProjectRTADocument/ProjectRTADocument'
import ProjectFilterCard from '../components/ProjectFilterCard/ProjectFilterCard'
import { Box, Text, Input, Button } from 'asimov'
import Sidebar from 'components/Sidebar/Sidebar'
import Header from 'components/Header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStores } from 'hooks/useStores'
import { useLocation } from 'react-router'
import { observer } from 'mobx-react-lite'
import { ClickAwayListener } from '@material-ui/core/'
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import { faTrash, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  background-color: #f2f1f0;
  display: flex;
  flex-direction: column;
`

const ContentContainer = styled.div`
  padding: 30px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Documents = styled.div`
  width: 50vw;
  height: 65vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

const SearchResults = styled.div`
  margin-left: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
`

const SidebarContainer = styled.div`
  display: flex;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: -150px;
  padding: 20px;
  width: 300px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.7);
  z-index: 100;
`

const PopperButtons = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
  size: 900px;
`

const ButtonIcon = styled.button`
  margin-left: 8px;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & > svg:hover {
    color: black;
  }
`

const Icon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
  }
`

export const ProjectDetailPage = observer(() => {
  const { projectStore } = useStores()
  const history = useHistory()
  const location = useLocation()
  const projectId = location.pathname.split('/')[2]
  const [title, setTitle] = useState('')
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const handleClickAway = () => {
    setOpen(false)
  }
  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  useLayoutEffect(() => {
    setLoading(true)
    projectStore.getProject(projectId).then(() => {
      projectStore.getRTADetails().then(() => {
        setLoading(false)
      })
    })
  }, [projectStore, projectId])

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      projectStore.editProjectTitle(title, projectId)
      setIsEditing(false)
    }
  }

  const projectDetails = projectStore.projectDetails
  const rtaListDetails = projectStore.rtaListDetails

  if (!projectStore.projectDetails) {
    return <div></div>
  }

  return (
    <Root>
      <Header type={'projectFolder'} />
      <SidebarContainer>
        <Sidebar />
        <ContentContainer>
          {loading ? (
            <Box alignSelf="center">
              <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
            </Box>
          ) : (
            <>
              <Top>
                <ClickAwayListener
                  onClickAway={() => {
                    setIsEditing(false)
                  }}
                >
                  <Title>
                    {isEditing ? (
                      <StyledInput
                        defaultValue={projectDetails.title}
                        onKeyDown={handleInputKeyDown}
                        onChange={(e) => setTitle(e.target.value)}
                        after={
                          <Icon
                            icon={faTimes}
                            color="#3E474F"
                            onClick={() => setIsEditing(false)}
                          />
                        }
                      ></StyledInput>
                    ) : (
                      <Text
                        pr={2}
                        fontSize={2}
                        color="#3E474F"
                        fontWeight="bold"
                        lineHeight="38px"
                      >
                        {projectDetails.title}
                      </Text>
                    )}
                    {!isEditing ? (
                      <ButtonIcon onClick={() => setIsEditing(true)}>
                        <FontAwesomeIcon icon={faEdit} color="#3E474F" />
                      </ButtonIcon>
                    ) : null}
                  </Title>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleClickAway}>
                  <div>
                    <ButtonIcon onClick={() => handleClick()}>
                      <FontAwesomeIcon icon={faTrash} color="#3E474F" />
                    </ButtonIcon>
                    {open ? (
                      <PopperContainer>
                        <Text>
                          Are you sure you want to delete this folder?
                        </Text>
                        <PopperButtons>
                          <Button buttonColor="#403E3F" mr="2">
                            CANCEL
                          </Button>
                          <Button
                            buttonColor="#D94E41"
                            onClick={() => {
                              projectStore.deleteProject(projectDetails.id)
                              history.push('/projects')
                            }}
                          >
                            DELETE
                          </Button>
                        </PopperButtons>
                      </PopperContainer>
                    ) : null}
                  </div>
                </ClickAwayListener>
              </Top>
              <>
                {projectStore.rtaIdList.length} saved search results |{' '}
                {projectStore.filters.length} saved search filter
              </>
              <Content>
                <Documents>
                  <Text
                    pb={1}
                    fontSize="15px"
                    fontWeight="bold"
                    color="#403E3F"
                  >
                    SAVED DOCUMENTS
                  </Text>
                  {rtaListDetails && rtaListDetails.length > 0 ? (
                    rtaListDetails.map((rta, index) => (
                      <ProjectRTADocument key={index} rta={rta} />
                    ))
                  ) : (
                    <Text pt="25px"> No projects found</Text>
                  )}
                </Documents>
                <SearchResults>
                  <Text
                    pb={1}
                    fontSize="15px"
                    fontWeight="bold"
                    color="#403E3F"
                  >
                    SAVED SEARCH FILTER
                  </Text>
                  {projectStore.filters.length > 0 ? (
                    projectStore.filters.map((filter, index) => (
                      <ProjectFilterCard filter={filter} key={index} />
                    ))
                  ) : (
                    <Text pt="25px"> No filters found </Text>
                  )}
                </SearchResults>
              </Content>
            </>
          )}
        </ContentContainer>
      </SidebarContainer>
    </Root>
  )
})

export default ProjectDetailPage
