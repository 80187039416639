import React, { useState } from 'react'
import styled from 'styled-components'
import WTOLogo from 'images/wto-logo.svg'
import { Box, Button, Text } from 'asimov'
import { ClickAwayListener } from '@material-ui/core/'
import UserService from 'services/UserService'
import { useStores } from 'hooks/useStores'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import iconSettings from '../../assets/icon-cog.svg'
import iconLogout from '../../assets/icon-door-exit.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
`

const LogoWrapper = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
`
const HeaderButtons = styled.div`
  margin-right: 112px;
  display: flex;
  flex-direction: row;
`

const PopperContainer = styled.ul`
  list-style-type: none;
  background-color: #ffffff;
  margin-top: 65px;
  margin-left: -152px;
  width: 250px;
  padding: 0px 10px;
  position: absolute;
  z-index: 5;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
`
const BackButtons = styled.div`
  margin-left: 40px;
`
const StyledButton = styled(Button)`
  text-transform: none;
  color: grey;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const ListItem = styled.li`
  padding: 12px;
  margin: 8px 0px;
  ${(props) => (props.isSection ? 'border-bottom: solid 1px #ebebe9;' : '')}
  ${(props) =>
    props.onClick
      ? `
    &:hover {
      cursor: pointer;
      background-color: #f2f1f0;
    }
  `
      : ''}
`

const Icon = styled.img`
  height: 16px;
  width: auto;
  margin-right: 8px;
`
const TitleHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

const CompareButtons = styled.div`
  display: flex;
  flex-direction: row;
`

export const Header = ({ type }) => {
  const { userStore, rtaViewStore, rtaManagementStore } = useStores()
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  const viewRTAManagementPage = () => {
    rtaManagementStore.setTab(0)
    history.push('/rta-management')
  }

  const viewUserManagementPage = () => {
    history.push('/user-management')
  }

  const viewAccountSettingsPage = () => {
    history.push('/account-settings')
  }

  const handleLogout = () => {
    const userService = new UserService()
    userService.logout()
    history.push('/login')
    window.location.reload(false)
  }
  let header
  const returnToSearch = () => (
    <StyledButton
      variant="tertiary"
      buttonColor={'black'}
      onClick={() => history.push('/')}
    >
      <Text mr={2}>
        {type === 'projectFolder' || type === 'rtaManagement'
          ? 'Back to home page'
          : 'Back to search results'}
      </Text>
      <FontAwesomeIcon icon={faArrowRight} color="grey" />
    </StyledButton>
  )

  const returnToRTA = () => (
    <div>
      {rtaViewStore.rtaLink && (
        <StyledButton
          variant="tertiary"
          buttonColor={'black'}
          onClick={() => history.push(rtaViewStore.rtaLink)}
        >
          <Text fontSize={15} mr={2}>
            Back to RTA
          </Text>
          <FontAwesomeIcon icon={faArrowRight} color="grey" />
        </StyledButton>
      )}
    </div>
  )

  const goBack = () => (
    <StyledButton
      variant="tertiary"
      buttonColor="black"
      onClick={() => history.goBack()}
    >
      <FontAwesomeIcon icon={faArrowLeft} color="grey" />
      <Text mr={2} ml={2}>
        Back to previous page
      </Text>
    </StyledButton>
  )

  const titleHeader = (title) => (
    <TitleHeader>
      <Text fontSize="2" ml={'10px'}>
        {title}
      </Text>
      {title === 'Compare' ? (
        <CompareButtons>
          {returnToRTA()}
          {returnToSearch()}
        </CompareButtons>
      ) : (
        returnToSearch()
      )}
    </TitleHeader>
  )

  const genericHeader = (title) => (
    <TitleHeader>
      <Text fontSize="2" ml={'10px'}>
        {title}
      </Text>
      {goBack()}
    </TitleHeader>
  )

  if (type === 'rtaView') {
    header = returnToSearch()
  } else if (type === 'compare') {
    header = titleHeader('Compare')
  } else if (type === 'projectFolder') {
    header = titleHeader('Projects')
  } else if (type === 'rtaManagement') {
    header = titleHeader('RTA Management')
  } else if (type === 'userManagement') {
    header = genericHeader('User Management')
  }

  return (
    <Root>
      <LogoWrapper>
        <Link to="/">
          <img src={WTOLogo} alt="WTO Logo" width={205} height={61} />
        </Link>

        <BackButtons>{header}</BackButtons>
      </LogoWrapper>
      <ClickAwayListener onClickAway={handleClickAway}>
        <HeaderButtons>
          {open ? (
            <PopperContainer>
              <ListItem isSection={true}>
                <Box>
                  {userStore.user.firstname} {userStore.user.lastname}
                  <Text
                    fontWeight="bold"
                    fontSize="14px"
                    color="#3E474F"
                    mt="1"
                  >
                    {userStore.user.role}
                  </Text>
                </Box>
              </ListItem>
              {userStore.isSuperUser() ? (
                <ListItem onClick={viewRTAManagementPage}>
                  <Box flexDirection="row" alignItems="center">
                    <Icon src={iconSettings} alt="" />
                    Manage RTAs
                  </Box>
                </ListItem>
              ) : null}
              {userStore.isSuperUser() ? (
                <ListItem onClick={viewUserManagementPage}>
                  <Box flexDirection="row" alignItems="center">
                    <Icon src={iconSettings} alt="" />
                    Manage Users
                  </Box>
                </ListItem>
              ) : null}
              <ListItem onClick={viewAccountSettingsPage}>
                <Box flexDirection="row" alignItems="center">
                  <Icon src={iconSettings} alt="" />
                  Account Settings
                </Box>
              </ListItem>
              <ListItem onClick={handleLogout}>
                <Box flexDirection="row" alignItems="center">
                  <Icon src={iconLogout} alt="" />
                  Logout
                </Box>
              </ListItem>
            </PopperContainer>
          ) : null}

          <Button
            color="#FFFFFF"
            buttonColor="#3E474F"
            borderColor="#3E474F"
            variant="primary"
            ml="2"
            p="3"
            onClick={handleClick}
          >
            Account
          </Button>
        </HeaderButtons>
      </ClickAwayListener>
    </Root>
  )
}

export default Header
