import React, { useEffect } from 'react'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box } from 'asimov'
import { Tab, Tabs, TabPanel } from 'components/Tabs'
import RTATable from 'components/RTATable/RTATable'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'

const Root = styled.div`
  display: block;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
`

const TableContainer = styled.div`
  display: flex;
  margin: 30px;
  height: 100%;
  overflow: auto;
`

export const RTAManagementPage = observer(() => {
  const history = useHistory()
  const { rtaManagementStore } = useStores()

  useEffect(() => {
    rtaManagementStore.fetchNewRTAList()
    rtaManagementStore.fetchIngestedRTAList()
  }, [rtaManagementStore])

  const handleConfigure = (rtaID) => {
    const newLoc = `/rta-management/sections/${rtaID}`
    history.push(newLoc)
  }

  const handleStatus = (rtaID) => {
    const newLoc = `/rta-management/status/${rtaID}`
    history.push(newLoc)
  }

  return (
    <Root>
      <Header type={'rtaManagement'} />
      <Container>
        <Box bg="#DBDBDB">
          <Tabs
            value={rtaManagementStore.tab}
            onChange={(_, value) => rtaManagementStore.setTab(value)}
          >
            <Tab label="New RTAs" />
            <Tab label="All RTAs" />
          </Tabs>
        </Box>
        <TabPanel
          value={rtaManagementStore.tab}
          index={0}
          width="100%"
          maxHeight="calc(100vh - 164px)"
        >
          <TableContainer>
            <RTATable
              data={rtaManagementStore.newRTAList}
              handleStatus={handleStatus}
              handleConfigure={handleConfigure}
              isLoading={rtaManagementStore.isNewRTALoading}
            />
          </TableContainer>
        </TabPanel>
        <TabPanel
          value={rtaManagementStore.tab}
          index={1}
          width="100%"
          maxHeight="calc(100vh - 164px)"
        >
          <TableContainer>
            <RTATable
              data={rtaManagementStore.ingestedRTAList}
              handleStatus={handleStatus}
              handleConfigure={handleConfigure}
              isLoading={rtaManagementStore.isIngestedRTALoading}
            />
          </TableContainer>
        </TabPanel>
      </Container>
    </Root>
  )
})

export default RTAManagementPage
