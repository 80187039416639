import React from 'react'
import { layout } from 'styled-system'
import styled from 'styled-components'
import { Box, Text } from 'asimov'

import Select from 'react-select'

const SelectWrapper = styled.div`
  ${layout}
  width: ${(props) => props.width};
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;

  & input {
    font-family: 'Source Sans Pro';
  }

  & button {
    width: 100%;
  }
`

const Label = styled(Text)`
  flex-shrink: 0;
`

const customStyles = {
  dropdownIndicator: (provided) => ({
    ...provided,
    backgroundColor: '#F2F1F0',
    fontFamily: 'Source Sans Pro',
    fontSize: '12px',
    display: 'flex',
    color: '#515151',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
  control: (provided) => ({
    ...provided,
    color: '#515151',
    backgroundColor: '#F2F1F0',
    border: 'none',
    width: '100%',
    marginLeft: '-1px',
    '.select__control--is-focused': {
      border: 'none',
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#515151',
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '2px',
    border: 'none',
    fontFamily: 'Source Sans Pro',
    boxShadow: '-2px -2px 156px -38px rgba(0, 0, 0, 0.50)',
  }),
}

export const CustomSingleSelect = ({
  placeholder,
  data,
  width,
  onSelect,
  value,
}) => {
  const onChange = (selected) => {
    if (onSelect) onSelect(selected)
  }

  return (
    <SelectWrapper width={width}>
      <Box width="40%">
        <Label mr={2}>{placeholder}:</Label>
      </Box>
      <Box width="60%">
        <Select
          isClearable
          options={data}
          onChange={onChange}
          value={value}
          styles={customStyles}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </Box>
    </SelectWrapper>
  )
}

export default CustomSingleSelect
