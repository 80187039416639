import axios from 'axios'

class ActionsService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }

  scrapeLinks(links) {
    const metadataEndpoint = `${this.baseURL}/actions/scrape_links`
    const payload = { links }
    return axios.post(metadataEndpoint, payload, this.baseConfig)
  }
}

export default ActionsService
