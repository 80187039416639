import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Input, Card, Button } from 'asimov'
import { faEdit, faTrash, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'
import { useStores } from 'hooks/useStores'
import { ClickAwayListener } from '@material-ui/core/'
import Popper from '@material-ui/core/Popper'

const StyledCard = styled(Card)`
  width: calc(100% / 3 - 20px);
  padding: 20px;
  margin-bottom: 15px;
  border-style: none;
`

const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledSubtitle = styled(Text)`
  font-size: 13px;
`

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
`

const StyledButton = styled(Button)`
  align-self: flex-end;
  font-size: 14px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const FooterText = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
  size: 900px;
`

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: -150px;
  padding: 20px;
  width: 300px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.7);
  z-index: 100;
`

const PopperButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  flex-direction: row;
`

const ButtonIcon = styled.button`
  margin-left: 8px;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & > svg:hover {
    color: black;
  }
`

const Icon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
  }
`

const BtnText = styled(Text)`
  display: block;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ProjectCard = ({ projectDetails }) => {
  const history = useHistory()
  const { projectStore } = useStores()
  const handleClickAway = () => {
    setAnchorEl(null)
  }
  const [title, setTitle] = useState('')

  const [isEditing, setIsEditing] = useState(false)

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      projectStore.editProjectTitle(title, projectDetails.id)
      setIsEditing(false)
    }
  }
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const viewProject = () => {
    projectStore.clearRTAs()
    history.push(`/project/${projectDetails.id}`)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <StyledCard backgroundColor="white">
      <Header>
        <Title>
          {isEditing ? (
            <StyledInput
              py="2px"
              defaultValue={projectDetails.title}
              onKeyDown={handleInputKeyDown}
              onChange={(e) => setTitle(e.target.value)}
              after={
                <Icon
                  icon={faTimes}
                  color="#3E474F"
                  size="xs"
                  onClick={() => setIsEditing(false)}
                />
              }
            />
          ) : (
            <BtnText
              pr={2}
              fontSize="18px"
              fontWeight="bold"
              color="#403E3F"
              lineHeight={1.5}
              onClick={viewProject}
            >
              {projectDetails.title}
            </BtnText>
          )}
          {!isEditing ? (
            <ButtonIcon onClick={() => setIsEditing(true)}>
              <FontAwesomeIcon icon={faEdit} color="#3E474F" />
            </ButtonIcon>
          ) : null}
        </Title>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <ButtonIcon onClick={handleClick}>
              <FontAwesomeIcon icon={faTrash} color="#3E474F" />
            </ButtonIcon>

            <Popper id={id} open={open} anchorEl={anchorEl}>
              <PopperContainer>
                <Text>Are you sure you want to delete this folder?</Text>
                <PopperButtons>
                  <StyledButton
                    buttonColor="#403E3F"
                    onClick={handleClick}
                    mr="2"
                  >
                    CANCEL
                  </StyledButton>
                  <StyledButton
                    buttonColor="#D94E41"
                    onClick={() => {
                      projectStore.deleteProject(projectDetails.id)
                      handleClick()
                    }}
                  >
                    DELETE
                  </StyledButton>
                </PopperButtons>
              </PopperContainer>
            </Popper>
          </div>
        </ClickAwayListener>
      </Header>
      <Footer>
        <FooterText>
          <SubtitleContainer>
            <StyledSubtitle fontWeight="bold">
              {projectDetails.results}
            </StyledSubtitle>

            <StyledSubtitle>
              {projectDetails.rta_list.length} {'  '}
              saved search results
            </StyledSubtitle>
            <StyledSubtitle mx="1">|</StyledSubtitle>
            <StyledSubtitle>
              {projectDetails.filter_list.length} saved search filter
            </StyledSubtitle>
          </SubtitleContainer>
        </FooterText>
      </Footer>
    </StyledCard>
  )
}

export default ProjectCard
