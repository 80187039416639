import { countries } from './countries'

export const coverageOptions = [
  {
    label: 'Goods only',
    value: 1,
  },
  {
    label: 'Services only',
    value: 2,
  },
  {
    label: 'Goods & Services',
    value: 3,
  },
]

export const typeOptions = [
  {
    label: 'Customs Union',
    value: [1, 7],
  },
  {
    label: 'Free Trade Agreement',
    value: [2, 5],
  },
  {
    label: 'Partial Scope Agreement',
    value: [3, 6],
  },
  {
    label: 'Economic Integration Agreement',
    value: [4, 5, 6, 7],
  },
]

export const notificationUnderOptions = [
  {
    label: 'GATT Art. XXIV',
    value: [1, 4, 6, 7],
  },
  {
    label: 'Enabling Clause',
    value: [2, 5, 6, 7],
  },
  {
    label: 'GATS Art. V',
    value: [3, 4, 5, 7],
  },
]

export const typeExactOptions = [
  {
    label: 'Customs Union',
    value: 1,
  },
  {
    label: 'Free Trade Agreement',
    value: 2,
  },
  {
    label: 'Partial Scope Agreement',
    value: 3,
  },
  {
    label: 'Economic Integration Agreement',
    value: 4,
  },
  {
    label: 'Free Trade Agreement & Economic Integration Agreement',
    value: 5,
  },
  {
    label: 'Partial Scope Agreement & Economic Integration Agreement',
    value: 6,
  },
  {
    label: 'Customs Union & Economic Integration Agreement',
    value: 7,
  },
]

export const notificationUnderExactOptions = [
  {
    label: 'GATT Art. XXIV',
    value: 1,
  },
  {
    label: 'Enabling Clause',
    value: 2,
  },
  {
    label: 'GATS Art. V',
    value: 3,
  },
  {
    label: 'GATT Art. XXIV & GATS Art. V',
    value: 4,
  },
  {
    label: 'Enabling Clause & GATS Art. V',
    value: 5,
  },
  {
    label: 'GATT Art. XXIV & Enabling Clause',
    value: 6,
  },
  {
    label: 'GATT Art. XXIV, Enabling Clause & GATS Art. V',
    value: 7,
  },
]

export const regionOptions = [
  {
    label: 'Africa',
    value: 'Africa',
  },
  {
    label: 'Caribbean',
    value: 'Caribbean',
  },
  {
    label: 'Central America',
    value: 'Central America',
  },
  {
    label:
      'Commonwealth of Independent States (CIS), including associate and former member States',
    value:
      'Commonwealth of Independent States (CIS), including associate and former member States',
  },
  {
    label: 'East Asia',
    value: 'East Asia',
  },
  {
    label: 'Europe',
    value: 'Europe',
  },
  {
    label: 'Middle East',
    value: 'Middle East',
  },
  {
    label: 'North America',
    value: 'North America',
  },
  {
    label: 'Oceania',
    value: 'Oceania',
  },
  {
    label: 'South America',
    value: 'South America',
  },
  {
    label: 'West Asia',
    value: 'West Asia',
  },
]

export const countryOptions = countries.map((country) => {
  const option = {
    label: country.label,
    value: country.label,
  }
  return option
})

export const currentSignatoriesOptions = countries.sort((a, b) => {
  if (a.label < b.label) {
    return -1
  }
  if (a.label > b.label) {
    return 1
  }
  return 0
})

export const languageOptions = [
  {
    label: 'English',
    value: 'EN',
  },
  {
    label: 'Spanish',
    value: 'ES',
  },
  {
    label: 'French',
    value: 'FR',
  },
]

const languageTabs = ['ALL', 'EN', 'ES', 'FR']

const languageCodeOptions = [
  { label: 'EN', value: 'EN' },
  { label: 'ES', value: 'ES' },
  { label: 'FR', value: 'FR' },
]

const RTA_STATUSES = {
  PENDING: { id: 'PENDING', label: 'Pending', color: '#5E6D8C' },
  VALIDATED: { id: 'VALIDATED', label: 'Validated', color: '#41D9CA' },
  IN_PROGRESS: { id: 'IN_PROGRESS', label: 'In Progress', color: '#403E3F' },
  FAILED: { id: 'FAILED', label: 'Failed', color: '#D94E41' },
  INGESTED: { id: 'INGESTED', label: 'Ingested', color: '#2A8C82' },
}

const SECTION_STATUSES = {
  PENDING: { id: 'PENDING', label: 'Pending', color: '#5E6D8C' },
  IN_PROGRESS: { id: 'IN_PROGRESS', label: 'In Progress', color: '#403E3F' },
  DOWNLOADING: { id: 'DOWNLOADING', label: 'Downloading', color: '#403E3F' },
  EXTRACTING: { id: 'EXTRACTING', label: 'Extracting', color: '#403E3F' },
  TRANSLATING: { id: 'TRANSLATING', label: 'Translating', color: '#403E3F' },
  FAILED_DOWNLOAD: {
    id: 'FAILED_DOWNLOAD',
    label: 'Failed Download',
    color: '#D94E41',
  },
  FAILED_EXTRACTION: {
    id: 'FAILED_EXTRACTION',
    label: 'Failed Extraction',
    color: '#D94E41',
  },
  FAILED_TRANSLATE: {
    id: 'FAILED_TRANSLATE',
    label: 'Failed Translate',
    color: '#D94E41',
  },
  INGESTED: { id: 'INGESTED', label: 'Ingested', color: '#2A8C82' },
}

const roleOptions = [
  {
    label: 'Superuser',
    value: 'SUPERUSER',
  },
  {
    label: 'User',
    value: 'USER',
  },
]

const filterLabels = {
  lang: 'Language',
  CoverageID: 'Coverage',
  TypeID: 'Type',
  LegalCoverageID: 'Legal Coverage ID',
  Region: 'Region',
  RTASignatories: 'RTA Signatories',
  SignatureDate: 'Signature Date',
  EntryIntoForceDate: 'Entry Into Force Date',
  is_legal_text: 'Is Legal Text',
}

export const constants = {
  coverageOptions,
  typeOptions,
  notificationUnderOptions,
  typeExactOptions,
  notificationUnderExactOptions,
  regionOptions,
  currentSignatoriesOptions,
  countryOptions,
  languageOptions,
  languageTabs,
  languageCodeOptions,
  roleOptions,
  RTA_STATUSES,
  SECTION_STATUSES,
  filterLabels,
}
export default constants
