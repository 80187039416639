import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Box, Input, Button, Text, Checkbox } from 'asimov'
import CustomSelect from '../CustomSelect/CustomSelect'
import CustomSingleSelect from '../CustomSingleSelect/CustomSingleSelect'
import YearSlider from './YearSlider'
import { useStores } from 'hooks/useStores'
import constants from '../../utils/constants'
import Loader from 'react-loader-spinner'
import { ClickAwayListener } from '@material-ui/core/'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  height: min-content;
  width: 80%;
`

const ProjectDropDown = styled.div`
  width: 80%;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }

  & input {
    font-family: 'Source Sans Pro';
  }
`

const Content = styled.div`
  padding: 40px 50px;
  background-color: white;
`
const Spinner = styled.div`
  width: 940px;
  display: flex;
  justify-content: center;
`
const SearchInput = styled.div`
  display: flex;
  margin-top: 16px;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const YearContainer = styled.div`
  width: 47%;
  margin-bottom: 10px;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: -250px;
  margin-left: -230px;
  padding: 20px;
  width: 301px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.34);
  z-index: 100;
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`
const RefineSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 16px;
`

const RefineSearchGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Label = styled(Text)`
  flex-shrink: 0;
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: '#A9AAAC',
    width: width,
    backgroundColor: '#F2F1F0',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '240px',
    fontFamily: 'Source Sans Pro',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`

const SaveFilterPopUp = ({
  projectId,
  setProjectId,
  filterTitle,
  setFilterTitle,
  handleClick,
  searchText,
}) => {
  const { searchStore, projectStore } = useStores()
  const reformatProjectList = () => {
    return projectStore.projects.map((project) => {
      return { label: project.title, value: project.id }
    })
  }

  return (
    <PopperContainer>
      <Text mb={3} fontSize="16px" fontWeight="bold" color="#2A8C82">
        ADD SEARCH FILTER
      </Text>
      <Box flexDirection="row" mb="2">
        <Box width="20%" justifyContent="center">
          Title
        </Box>
        <CustomInput
          width="80%"
          onChange={(e) => setFilterTitle(e.target.value)}
        />
      </Box>
      <Box flexDirection="row" alignItems="center">
        <Box width="20%" justifyContent="center">
          Project
        </Box>
        <ProjectDropDown>
          <Select
            width="100%"
            placeholder="Select a project"
            styles={customStyles}
            options={reformatProjectList()}
            onChange={(selected) => {
              setProjectId(selected.value)
            }}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        </ProjectDropDown>
      </Box>
      <ButtonContainer>
        <Button buttonColor="#403E3F" variant="tertiary" onClick={handleClick}>
          Cancel
        </Button>
        <Button
          ml={2}
          variant="primary"
          buttonColor="#2A8C82"
          onClick={() => {
            projectStore.addFilterToProject(projectId, {
              title: filterTitle,
              query: searchText,
              filters: searchStore.formFilters,
            })
            handleClick()
          }}
        >
          Save
        </Button>
      </ButtonContainer>
    </PopperContainer>
  )
}

export const SearchForm = observer(() => {
  const { userStore, searchStore } = useStores()
  const [searchText, setSearchText] = useState(searchStore.searchTerm)
  const [projectId, setProjectId] = useState(null)
  const [filterTitle, setFilterTitle] = useState('')
  const formFilters = searchStore.formFilters

  const onSearch = () => {
    searchStore.setSearchTerm(searchText)
    searchStore.setUiPageNum(1)
    searchStore.search({ page: 1 })
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  const onClearFilters = (e) => {
    searchStore.clearFormFilters()
  }

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }

  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <Root>
      <Content>
        <Text fontSize="28px" fontWeight="bold" my="3" color="#403E3F">
          Hi {userStore.user.firstname}, what are you looking for today?
        </Text>
        <SearchInput>
          <CustomInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            width="100%"
            onKeyDown={handleInputKeyDown}
            after={
              <Icon
                icon={faTimes}
                color="#403E3F"
                onClick={() => setSearchText('')}
              />
            }
          />
          <Button
            marginLeft={20}
            onClick={onSearch}
            variant="primary"
            buttonColor="#5E6D8C"
          >
            Search
          </Button>
        </SearchInput>
        <Box flexDirection="row" justifyContent="space-between" mt="3">
          <CustomSelect
            placeholder="Language"
            data={constants.languageOptions}
            width="30%"
            onSelect={(selected) => {
              searchStore.setFormFilters({ ...formFilters, lang: selected })
            }}
            value={formFilters.lang}
          />
          <CustomSelect
            placeholder="Coverage"
            data={constants.coverageOptions}
            width="30%"
            onSelect={(selected) => {
              searchStore.setFormFilters({
                ...formFilters,
                CoverageID: selected,
              })
            }}
            value={formFilters.CoverageID}
          />
          <CustomSingleSelect
            placeholder="Type"
            data={constants.typeOptions}
            width="30%"
            onSelect={(value) => {
              searchStore.setFormFilters({ ...formFilters, TypeID: value })
            }}
            value={formFilters.TypeID}
          />
        </Box>
        <Box flexDirection="row" justifyContent="space-between" mt="3">
          <CustomSingleSelect
            placeholder="Notification under"
            data={constants.notificationUnderOptions}
            width="30%"
            onSelect={(value) => {
              searchStore.setFormFilters({
                ...formFilters,
                LegalCoverageID: value,
              })
            }}
            value={formFilters.LegalCoverageID}
          />
          <CustomSelect
            placeholder="Region"
            data={constants.regionOptions}
            width="30%"
            onSelect={(value) => {
              searchStore.setFormFilters({ ...formFilters, Region: value })
            }}
            value={formFilters.Region}
          />
          <CustomSelect
            placeholder="Signatories"
            data={constants.currentSignatoriesOptions}
            width="30%"
            onSelect={(value) => {
              searchStore.setFormFilters({
                ...formFilters,
                RTASignatories: value,
              })
            }}
            value={formFilters.RTASignatories}
          />
        </Box>
        <Box flexDirection="row" mt="3" justifyContent="space-between">
          <YearContainer>
            <Box width="40%">
              <Label mr={2}>Date of Signature:</Label>
            </Box>
            <Box width="60%">
              <YearSlider
                title="Date of Signature"
                yearRange={formFilters.SignatureDate}
                setYearRange={(yearRange) => {
                  searchStore.setFormFilters({
                    ...formFilters,
                    SignatureDate: yearRange,
                  })
                }}
              />
            </Box>
          </YearContainer>
          <YearContainer>
            <Box width="40%">
              <Label mr={2}>Date of Entry into Force: </Label>
            </Box>
            <Box width="60%">
              <YearSlider
                title="Date of Entry into Force"
                yearRange={formFilters.EntryIntoForceDate}
                setYearRange={(yearRange) => {
                  searchStore.setFormFilters({
                    ...formFilters,
                    EntryIntoForceDate: yearRange,
                  })
                }}
              />
            </Box>
          </YearContainer>
        </Box>
        <RefineSearchContainer>
          <RefineSearchGroup>
            <Checkbox
              color="#2A8C82"
              id="include-wto-legal-text"
              checked={searchStore.formFilters.is_legal_text}
              onChange={(e) => {
                searchStore.setFormFilters({
                  ...formFilters,
                  is_legal_text: e.target.checked,
                })
              }}
            >
              Search the WTO Legal Texts
            </Checkbox>
          </RefineSearchGroup>
        </RefineSearchContainer>

        <Footer>
          <Button
            variant="tertiary"
            buttonColor="#403E3F"
            onClick={onClearFilters}
          >
            Clear filters
          </Button>
          <Box borderRight="solid 1px #EBEBE9" mx="2" height="30px" />
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <Button
                variant="tertiary"
                onClick={handleClick}
                buttonColor="#2A8C82"
              >
                Save Filters
              </Button>
              {open ? (
                <SaveFilterPopUp
                  {...{
                    projectId,
                    setProjectId,
                    filterTitle,
                    setFilterTitle,
                    handleClick,
                    searchText,
                  }}
                />
              ) : null}
            </div>
          </ClickAwayListener>
        </Footer>
      </Content>
      <Spinner>
        {searchStore.isSearchLoading ? (
          <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
        ) : null}
      </Spinner>
    </Root>
  )
})

export default SearchForm
