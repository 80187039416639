import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import PrivateRoute from 'components/PrivateRoute/PrivateRoute'
import LoginPage from './domain/LoginPage'
import SearchPage from './domain/SearchPage'
import RTAViewPage from './domain/RTAViewPage'
import ComparePage from './domain/ComparePage'
import ProjectDetailPage from './domain/ProjectDetailPage'
import ProjectsPage from './domain/ProjectsPage'
import RTAManagementPage from './domain/RTAManagementPage'
import RTAManagementStatusPage from 'domain/RTAManagementStatusPage'
import RTAManagementSectionsPage from 'domain/RTAManagementSectionsPage'
import AccountSettingsPage from 'domain/AccountSettingsPage'
import ForgotPasswordPage from 'domain/ForgotPasswordPage'
import ResetPasswordPage from 'domain/ResetPasswordPage'
import UserManagementPage from 'domain/UserManagementPage'
import UserManagementAddUserPage from 'domain/UserManagementAddUserPage'
import UserManagementEditUserPage from 'domain/UserManagementEditUserPage'
import { ThemeProvider } from 'styled-components'
import { theme as tmTheme } from 'asimov'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const theme = JSON.parse(JSON.stringify(tmTheme))

const newTheme = {
  ...theme,
  fonts: {
    h1: 'Source Sans Pro',
    h2: 'Source Sans Pro',
    h3: 'Source Sans Pro',
    h4: 'Source Sans Pro',
    h5: 'Source Sans Pro',
    h6: 'Source Sans Pro',
    body: 'Source Sans Pro',
    sansSerif: 'Source Sans Pro',
    serif: 'Source Sans Pro',
  },
}

function App() {
  return (
    <ThemeProvider theme={newTheme}>
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/forgot-password">
              <ForgotPasswordPage />
            </Route>
            <Route path="/reset-password">
              <ResetPasswordPage />
            </Route>
            <PrivateRoute exact path="/" component={SearchPage} />
            <PrivateRoute path="/rta-view/:id" component={RTAViewPage} />
            <PrivateRoute path="/compare" component={ComparePage} />
            <PrivateRoute path="/project" component={ProjectDetailPage} />
            <PrivateRoute path="/projects" component={ProjectsPage} />
            <PrivateRoute
              path="/rta-management/status/:id"
              component={RTAManagementStatusPage}
            />
            <PrivateRoute
              path="/rta-management/sections/:id"
              component={RTAManagementSectionsPage}
            />
            <PrivateRoute
              path="/rta-management"
              component={RTAManagementPage}
            />
            <PrivateRoute
              path="/account-settings"
              component={AccountSettingsPage}
            />
            <PrivateRoute
              path="/user-management/add-user"
              component={UserManagementAddUserPage}
            />
            <PrivateRoute
              path="/user-management/edit-user/:id"
              component={UserManagementEditUserPage}
            />
            <PrivateRoute
              path="/user-management"
              component={UserManagementPage}
            />
          </Switch>
        </BrowserRouter>
      </DndProvider>
    </ThemeProvider>
  )
}

export default App
