import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Button } from 'asimov'
import { ProjectPopper } from './ProjectPopper'
import { useHistory } from 'react-router-dom'
import { formatRTADate } from 'utils/dateFormatter'
import { useStores } from 'hooks/useStores'
import Snackbar from 'components/Snackbar/Snackbar'
import { default as BaseDownloadBtn } from 'components/DownloadButton/DownloadButton'
import constants from '../../../utils/constants'

const Root = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro';
`

const Result = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 50px 20px 50px;
`

const Footer = styled.div`
  background-color: #ebebe9;
  padding: 15px 50px 15px 50px;
  display: flex;
`

const Title = styled.div`
  max-width: 80%;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Description = styled.div`
  padding-top: 10px;
  padding-right: 50px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  em {
    font-style: normal;
    font-weight: 600;
    color: #2a8c82;
  }
`

const Score = styled.div`
  min-width: 45px;
  height: 32px;
  padding: auto 2px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #ffffff;
  background-color: #5e6d8c;
  font-size: 14px;
  font-weight: bold;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const BtnText = styled(Text)`
  display: block;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

const DownloadButton = styled(BaseDownloadBtn)`
  margin-right: 12px;
`

export const SearchResult = ({ result }) => {
  const { rtaCompareStore, rtaViewStore, searchStore } = useStores()
  const [snackbarMessage, setSnackBarMessage] = useState(
    `${result.name} has been added into compare `
  )
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }

  const history = useHistory()
  const signatureDate = formatRTADate(
    result.GSignatureDate,
    result.SSignatureDate
  )
  const entryIntoForceDate = formatRTADate(
    result.GEntryIntoForceDate,
    result.SEntryIntoForceDate
  )
  const lastUpdate = formatRTADate(result.last_updated)

  const handleClick = () => {
    const newLoc = `/rta-view/${result.rta_id}?section_id=${result.section_id}&page_num=${result.page_num}`
    history.push(newLoc)
    rtaViewStore.setRTALink(newLoc)
    rtaViewStore.setCurrLang(
      constants.languageOptions.find((lang) => lang.value === result.lang)
    )
    rtaViewStore.setKeyword(searchStore.searchTerm)
  }

  const handleCompare = () => {
    const rtaDoc = {
      title: result.name,
      id: result.rta_id,
    }
    if (Object.keys(rtaCompareStore.compareBucket1).length === 0) {
      rtaCompareStore.setCompareBucket1(rtaDoc)
      rtaCompareStore.setIsCompareBucketOpen(true)
    } else if (Object.keys(rtaCompareStore.compareBucket2).length === 0) {
      rtaCompareStore.setCompareBucket2(rtaDoc)
      rtaCompareStore.setIsCompareBucketOpen(true)
    } else {
      setSnackBarMessage('Compare bucket full!')
    }
    setOpenSnackBar(true)
  }

  function createMarkup(text) {
    return { __html: text }
  }
  return (
    <>
      <Root>
        <Result>
          <Header>
            <Text color={'#A9AAAC'} fontSize={10}>
              {result.category}
            </Text>
            <Title>
              <BtnText
                fontSize="18px"
                fontWeight="bold"
                color="#403E3F"
                onClick={handleClick}
              >
                {result.name}
              </BtnText>
            </Title>
            <Box flexDirection="row" alignItems="center" ml="auto" mr="16px">
              <Button
                onClick={handleCompare}
                buttonColor="#5E6D8C"
                mr="12px"
                size="small"
              >
                Compare
              </Button>
              <DownloadButton title={result.name} id={result.rta_id} />
              <ProjectPopper rtaId={result.rta_id} />
            </Box>
            <Score visible={!searchStore.isSearchDefault()}>
              {result.meta.score.toFixed(1)}
            </Score>
          </Header>
          <Description>
            <Text fontSize={15} lineHeight={1.5}>
              <Text
                dangerouslySetInnerHTML={createMarkup(result.meta.snippet)}
              />
            </Text>
          </Description>
        </Result>
        <Footer>
          <Text mr={4} fontSize="14px" color="#403E3F">
            <Text fontWeight="bold" fontSize="14px">
              Date of Signature:{' '}
            </Text>
            {signatureDate}
          </Text>
          <Text mr={4} fontSize="14px" color="#403E3F">
            <Text fontWeight="bold" fontSize="14px">
              Date of Entry into Force:{' '}
            </Text>
            {entryIntoForceDate}
          </Text>
          <Text mr={4} fontSize="14px" color="#403E3F">
            <Text fontWeight="bold" fontSize="14px">
              Date of last update:{' '}
            </Text>
            {lastUpdate}
          </Text>
          <Text mr={4} fontSize="14px" color="#403E3F">
            <Text fontWeight="bold" fontSize="14px">
              Exact Search Term Hits:{' '}
            </Text>
            {result.meta.total_term_count}
          </Text>
        </Footer>
      </Root>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        message={snackbarMessage}
      />
    </>
  )
}

export default SearchResult
