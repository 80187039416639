import React from 'react'
import styled from 'styled-components'
import { Text } from 'asimov'
import Loader from 'react-loader-spinner'
import constants from 'utils/constants'

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`

const Row = styled.tr`
  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;
  }
`

const ColumnHeader = styled.th`
  background-color: #dbdbdb;
  color: #403e3f;
  top: 0;
  padding: 16px;
  position: sticky;
  text-align: ${(props) => props.align || 'left'};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
`

const ColumnData = styled.td`
  padding: 16px;
  text-align: ${(props) => props.align || 'left'};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: black;
  ${(props) =>
    props.disabled
      ? `
    &:hover {
      cursor: auto;
      color: black;
    }
  `
      : `
    color: ${props.color};
  `};
`

const RTATable = ({ data, handleConfigure, handleStatus, isLoading }) => {
  return (
    <Table>
      <tbody>
        <Row>
          <ColumnHeader align="center" width="10%">
            RTA ID
          </ColumnHeader>
          <ColumnHeader width="60%">RTA Name</ColumnHeader>
          <ColumnHeader width="15%">Status</ColumnHeader>
          <ColumnHeader width="15%">Actions</ColumnHeader>
        </Row>
        {isLoading ? (
          <Row>
            <ColumnData colSpan="4" align="center">
              <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
            </ColumnData>
          </Row>
        ) : null}
        {!isLoading && data.length === 0 ? (
          <Row>
            <ColumnData colSpan="4" align="center">
              <Text>No RTAs found</Text>
            </ColumnData>
          </Row>
        ) : null}
        {!isLoading &&
          data.map((row, index) => (
            <Row key={index}>
              <ColumnData align="center" width="10%">
                {row.rta_id}
              </ColumnData>
              <ColumnData width="60%">{row.name}</ColumnData>
              <ColumnData width="15%">
                <Button
                  onClick={() => handleStatus(row.rta_id)}
                  color={constants.RTA_STATUSES[row.status].color}
                >
                  {constants.RTA_STATUSES[row.status].label}
                </Button>
              </ColumnData>
              <ColumnData width="15%">
                <Button
                  onClick={() => handleConfigure(row.rta_id)}
                  color="#5E6D8C"
                >
                  Configure
                </Button>
              </ColumnData>
            </Row>
          ))}
      </tbody>
    </Table>
  )
}

export default RTATable
