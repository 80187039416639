import React, { useState } from 'react'
import { Input, Button, Box } from 'asimov'
import styled from 'styled-components'
import WTOLogo from 'images/wto-logo.svg'
import Spacer from 'components/Spacer/Spacer'
import UserService from 'services/UserService'
import { useLocation } from 'react-router'
import qs from 'qs'
import Loader from 'react-loader-spinner'

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`
const ErrorMessage = styled.p`
  color: red;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

export const ResetPasswordPage = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [UIState, setUIState] = useState('PASSWORD_INPUT')
  const [errorMessage, setErrorMessage] = useState('')
  const location = useLocation()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const token = queryParams.token

  const resetPassword = () => {
    setErrorMessage('')
    if (password !== confirmPassword) {
      setErrorMessage('Passwords must match')
      return
    }

    const userService = new UserService()
    userService.resetPassword({ new_password: password }, token).then(
      () => {
        setUIState('SUCCESS_MESSAGE')
        setTimeout(() => {
          window.location.replace('/login')
        }, 3000)
      },
      (error) => {
        setErrorMessage('Network error, please try again')
      }
    )
  }

  let currentUI = null

  if (UIState === 'PASSWORD_INPUT') {
    currentUI = (
      <React.Fragment>
        <h2>Reset Password</h2>
        <p>New password</p>
        <CustomInput
          type="password"
          width="100%"
          onChange={(e) => setPassword(e.target.value)}
        />
        <p>Confirm new password</p>
        <CustomInput
          type="password"
          width="100%"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Spacer mb={6} />
        <Button onClick={resetPassword}>Reset Password</Button>
        <Spacer mb={2} />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </React.Fragment>
    )
  } else if (UIState === 'LOADING') {
    currentUI = (
      <Box alignItems="center">
        <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
      </Box>
    )
  } else if (UIState === 'SUCCESS_MESSAGE') {
    currentUI = (
      <React.Fragment>
        <h2>Password changed!</h2>
        <p>Redirecting to login page ...</p>
      </React.Fragment>
    )
  }

  return (
    <Root>
      <Box width="500px" bg="white" p={5}>
        <Box alignItems="center">
          <img src={WTOLogo} alt="WTO Logo" width="205px" height="61px" />
        </Box>
        {currentUI}
      </Box>
    </Root>
  )
}

export default ResetPasswordPage
