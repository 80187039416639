import React from 'react'
import styled from 'styled-components'
import constants from '../../utils/constants'
import { formatRTADate } from '../../utils/dateFormatter'

const MetaDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 2px black;
  height: 255px;
  overflow-y: auto;
  padding: 16px;
`

const RowHeader = styled.td`
  min-width: 200px;
  font-weight: bold;
  color: #3e474f;
`

const RowValue = styled.td`
  color: #3e474f;
`

const findValue = (arr, value) => {
  const obj = arr.find((o) => o.value === value)
  if (obj) {
    return obj.label
  } else {
    return ''
  }
}

const getRegions = (regions) => {
  if (!regions) {
    return ''
  }

  const values = regions.split('|')
  const uniqueValues = values.filter((item, i, ar) => ar.indexOf(item) === i)
  return uniqueValues.join(', ')
}

const getCurrentSignatories = (signatories) => {
  if (!signatories) {
    return ''
  }

  const values = signatories.split('|')
  const signatoryLabels = []

  values.forEach((value) => {
    const label = findValue(constants.currentSignatoriesOptions, value)
    signatoryLabels.push(label)
  })

  return signatoryLabels.join(', ')
}

const MetaDataBox = ({ metadata }) => {
  return (
    <MetaDataContainer>
      <table>
        <tbody>
          <tr>
            <RowHeader>Agreement Name</RowHeader>
            <RowValue>{metadata.name}</RowValue>
          </tr>
          <tr>
            <RowHeader>Coverage</RowHeader>
            <RowValue>
              {findValue(constants.coverageOptions, metadata.coverage_id)}
            </RowValue>
          </tr>
          <tr>
            <RowHeader>Type</RowHeader>
            <RowValue>
              {findValue(constants.typeExactOptions, metadata.type_id)}
            </RowValue>
          </tr>
          <tr>
            <RowHeader>Notification Under</RowHeader>
            <RowValue>
              {findValue(
                constants.notificationUnderExactOptions,
                metadata.legal_coverage_id
              )}
            </RowValue>
          </tr>
          <tr>
            <RowHeader>Date of Signature (G)</RowHeader>
            <RowValue>{formatRTADate(metadata.g_signature_date)}</RowValue>
          </tr>
          <tr>
            <RowHeader>Date of Signature (S)</RowHeader>
            <RowValue>{formatRTADate(metadata.s_signature_date)}</RowValue>
          </tr>
          <tr>
            <RowHeader>Date of Entry Into Force (G)</RowHeader>
            <RowValue>
              {formatRTADate(metadata.g_entry_into_force_date)}
            </RowValue>
          </tr>
          <tr>
            <RowHeader>Date of Entry Into Force (S)</RowHeader>
            <RowValue>
              {formatRTADate(metadata.s_entry_into_force_date)}
            </RowValue>
          </tr>
          <tr>
            <RowHeader>Region</RowHeader>
            <RowValue>{getRegions(metadata.region)}</RowValue>
          </tr>
          <tr>
            <RowHeader>Current Signatories</RowHeader>
            <RowValue>
              {getCurrentSignatories(metadata.rta_signatories)}
            </RowValue>
          </tr>
        </tbody>
      </table>
    </MetaDataContainer>
  )
}

export default MetaDataBox
