import React, { useState, useLayoutEffect } from 'react'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import { Box, Button, Card, Input } from 'asimov'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/useStores'
import UserService from 'services/UserService'
import Snackbar from 'components/Snackbar/Snackbar'
import Loader from 'react-loader-spinner'

const Root = styled.div`
  display: block;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const ErrorMessage = styled.p`
  color: red;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const ProfileSettings = ({
  createSnackbarMsg,
  firstname,
  lastname,
  setFirstname,
  setLastname,
}) => {
  const updateProfile = async () => {
    const userService = new UserService()
    const payload = {
      firstname,
      lastname,
    }
    await userService.updateCurrentUser(payload)
    createSnackbarMsg('Profile settings updated')
  }

  return (
    <div>
      <h2>Profile Settings</h2>
      <p>First name</p>
      <CustomInput
        width={400}
        value={firstname}
        onChange={(e) => setFirstname(e.target.value)}
      />
      <p>Last name</p>
      <CustomInput
        width={400}
        mb={5}
        value={lastname}
        onChange={(e) => setLastname(e.target.value)}
      />
      <Button
        variant="primary"
        buttonColor="#5E6D8C"
        alignSelf="flex-start"
        width={100}
        onClick={updateProfile}
      >
        Update Profile
      </Button>
    </div>
  )
}

const ChangePasswordForm = ({ createSnackbarMsg }) => {
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errorText, setErrorText] = useState('')

  const changePassword = async () => {
    const oldPass = oldPassword.trim()
    const newPass = newPassword.trim()
    const confirmPass = confirmNewPassword.trim()

    if (oldPass === '' || newPass === '' || confirmPass === '') {
      setErrorText('Passwords cannot be empty')
      return
    }

    if (newPass !== confirmPass) {
      setErrorText('Passwords must match')
      return
    }

    setErrorText('')

    const userService = new UserService()
    try {
      await userService.changePassword({
        old_password: oldPass,
        new_password: newPass,
      })
    } catch (error) {
      const responseData = error.response.data
      if (responseData.error_code === 'INCORRECT_OLD_PASSWORD') {
        setErrorText('Incorrect old password')
      }
      return
    }

    createSnackbarMsg('Password changed')
  }

  return (
    <Box pt="3">
      <h2>Change Password</h2>
      <p>Old password</p>
      <CustomInput
        type="password"
        width={400}
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />

      <p>New Password</p>
      <CustomInput
        type="password"
        width={400}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <p>Confirm new password</p>
      <CustomInput
        type="password"
        width={400}
        mb={5}
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />

      <Button
        variant="primary"
        buttonColor="#5E6D8C"
        alignSelf="flex-start"
        width={100}
        onClick={changePassword}
      >
        Change Password
      </Button>
      <ErrorMessage>{errorText}</ErrorMessage>
    </Box>
  )
}

export const AccountSettingsPage = observer(() => {
  const { userStore } = useStores()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    async function fetchUser() {
      setLoading(true)
      await userStore.getUser()
      setFirstname(userStore.user.firstname)
      setLastname(userStore.user.lastname)
      setLoading(false)
    }
    fetchUser()
  }, [userStore])

  const createSnackbarMsg = (message) => {
    setSnackbarOpen(true)
    setSnackbarMsg(message)
    setTimeout(() => {
      setSnackbarOpen(false)
    }, 2000)
  }

  return (
    <Root>
      <Header />
      <Container>
        {!loading ? (
          <Card width="800px" backgroundColor="white" m="5" border="none">
            <ProfileSettings
              createSnackbarMsg={createSnackbarMsg}
              {...{ firstname, lastname, setFirstname, setLastname }}
            />
            <ChangePasswordForm createSnackbarMsg={createSnackbarMsg} />
          </Card>
        ) : (
          <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
        )}
      </Container>
      <Snackbar
        open={snackbarOpen}
        message={snackbarMsg}
        onClose={() => setSnackbarOpen(false)}
      />
    </Root>
  )
})

export default AccountSettingsPage
