import { makeAutoObservable, runInAction } from 'mobx'
import RTAService from 'services/RTAService'
import RTAMetadataService from 'services/RTAMetadataService'

export class RTACompareStore {
  rtaID1 = 0
  rtaSections1 = []
  rtaPageCount1 = 1
  rtaText1 = ''
  rtaNav1 = { sectionID: null, pageNum: 1 }
  metadata1 = {}

  rtaID2 = 0
  rtaSections2 = []
  rtaPageCount2 = 1
  rtaText2 = ''
  rtaNav2 = { sectionID: null, pageNum: 1 }
  metadata2 = {}

  isCompareBucketOpen = false
  compareBucket1 = {}
  compareBucket2 = {}

  isRTATextLoading = 0
  sectionScore = 0.0
  fullScore = 0.0
  isFullScoreLoading = false
  isSectionScoreLoading = false
  lang = { label: 'English', value: 'EN' }

  keywordMatches1 = []
  keywordMatches2 = []

  rta1SelectedSection = { value: null, label: '' }
  rta1SelectedSection = { value: null, label: '' }

  constructor() {
    makeAutoObservable(this)
  }

  setCompareBucket1(details) {
    this.compareBucket1 = details
  }

  setCompareBucket2(details) {
    this.compareBucket2 = details
  }

  setRTAIDs(rtaID1, rtaID2) {
    this.rtaID1 = rtaID1
    this.rtaID2 = rtaID2
  }

  async fetchRTASections(rtaID1, rtaID2, language) {
    const rtaService = new RTAService()
    const sections1 = await rtaService.getSections(rtaID1, language)
    this.rtaSections1 = this.makeRTASections(sections1)
    const sections2 = await rtaService.getSections(rtaID2, language)
    this.rtaSections2 = this.makeRTASections(sections2)
    return [this.rtaSections1[0], this.rtaSections2[0]]
  }

  async fetchMetadata(rtaID1, rtaID2) {
    const metadataService = new RTAMetadataService()
    await metadataService.getRTAMetadata(rtaID1).then((response) => {
      runInAction(() => {
        this.metadata1 = response.data.result
      })
    })

    await metadataService.getRTAMetadata(rtaID2).then((response) => {
      runInAction(() => {
        this.metadata2 = response.data.result
      })
    })
  }

  makeRTASections(response) {
    const sections = response.data.result.map((section) => {
      return {
        sectionID: section.section_id,
        sectionName: section.section_name,
      }
    })
    return sections
  }

  getSectionOptions(sectionFor) {
    if (sectionFor === 'RTA_ID_1') {
      return this.makeSectionOptions(this.rtaSections1)
    } else if (sectionFor === 'RTA_ID_2') {
      return this.makeSectionOptions(this.rtaSections2)
    }
  }

  makeSectionOptions(sections) {
    return sections.map((section) => {
      const label = section.sectionName
        ? section.sectionName
        : section.sectionID
      return {
        value: section.sectionID,
        label,
      }
    })
  }

  async fetchPageRange(fetchFor, pageRange, sectionID) {
    const rtaService = new RTAService()
    const navInfo = { sectionID, pageRange }

    let rtaID
    if (fetchFor === 'RTA_ID_1') {
      rtaID = this.rtaID1
      this.isRTATextLoading = 1
    } else {
      rtaID = this.rtaID2
      this.isRTATextLoading = 2
    }
    const resSection = await rtaService.getPageCount(rtaID, sectionID)
    let endPage = pageRange[1]
    let rtaTextPromises = []
    for (let i = pageRange[0]; i < endPage + 1; i++) {
      if (i > resSection.data.page_count) {
        break
      } else {
        rtaTextPromises = rtaTextPromises.concat(
          rtaService.getRTAText(rtaID, sectionID, i)
        )
      }
    }
    const rtaText = (await Promise.all(rtaTextPromises)).reduce(
      (acc, response) => {
        return acc + ' ' + response.data.raw_text
      },
      ''
    )
    if (fetchFor === 'RTA_ID_1') {
      this.rtaPageCount1 = resSection.data.page_count
      this.rtaText1 = rtaText
      this.setRTANav1(navInfo)
      this.isRTATextLoading = 0
    } else if (fetchFor === 'RTA_ID_2') {
      this.rtaPageCount2 = resSection.data.page_count
      this.rtaText2 = rtaText
      this.setRTANav2(navInfo)
      this.isRTATextLoading = 0
    }
  }

  async fetchSimilarityScore(section1, section2) {
    this.fetchFullTextScore(section1, section2)
    this.fetchSectionScore(section1, section2)
  }

  async fetchFullTextScore(section1, section2) {
    const rtaService = new RTAService()
    const rta1 = { rtaID: this.rtaID1, sectionID: section1 }
    const rta2 = { rtaID: this.rtaID2, sectionID: section2 }
    await rtaService.compareFullRTAs(rta1, rta2).then((response) => {
      runInAction(() => {
        this.fullScore = parseFloat(response.data)
        this.setFullScoreLoading(false)
      })
    })
  }

  async fetchSectionScore(section1, section2) {
    const rtaService = new RTAService()
    const rta1 = { rtaID: this.rtaID1, sectionID: section1 }
    const rta2 = { rtaID: this.rtaID2, sectionID: section2 }
    await rtaService.compareSectionRTAs(rta1, rta2).then((response) => {
      runInAction(() => {
        this.sectionScore = parseFloat(response.data)
        this.setSectionScoreLoading(false)
      })
    })
  }

  fetchKeywordMatches(keyword) {
    const rtaService = new RTAService()
    this.setKeywordMatches1([])
    this.setKeywordMatches2([])

    rtaService
      .navigateKeywords(keyword, this.rtaNav1.sectionID)
      .then((response) => {
        runInAction(() => {
          this.setKeywordMatches1(response.data)
        })
      })

    rtaService
      .navigateKeywords(keyword, this.rtaNav2.sectionID)
      .then((response) => {
        runInAction(() => {
          this.setKeywordMatches2(response.data)
        })
      })
  }

  fetchHighlightedRTAText(pageNum, start, end, id) {
    const rtaID = id === 1 ? this.rtaID1 : this.rtaID2
    const sectionID = id === 1 ? this.rtaNav1.sectionID : this.rtaNav2.sectionID
    const rtaService = new RTAService()

    rtaService.getRTAText(rtaID, sectionID, pageNum).then((response) => {
      const rawText = response.data.raw_text
      const preText = rawText.slice(0, start)
      const highlightedText =
        `<span id='highlight-${id}'>` + rawText.slice(start, end) + `</span>`
      const postText = rawText.slice(end)
      if (id === 1) {
        this.rtaText1 = preText + highlightedText + postText
      } else {
        this.rtaText2 = preText + highlightedText + postText
      }
    })
  }

  reformatText(text) {
    const newlineTrimmed = text.replace(/^\n+|\n+$/g, '')
    const withLineBreaks = newlineTrimmed.replaceAll('\n', '<br/>')
    return withLineBreaks
  }

  setRTANav1(nav) {
    this.rtaNav1 = nav
  }

  setRTANav2(nav) {
    this.rtaNav2 = nav
  }

  setIsCompareBucketOpen(isOpen) {
    this.isCompareBucketOpen = isOpen
  }

  setKeywordMatches1(keywordMatches) {
    this.keywordMatches1 = keywordMatches
  }

  setKeywordMatches2(keywordMatches) {
    this.keywordMatches2 = keywordMatches
  }

  setFullScoreLoading(loading) {
    this.isFullScoreLoading = loading
  }

  setSectionScoreLoading(loading) {
    this.isSectionScoreLoading = loading
  }

  setRTA1SelectedSection(section) {
    this.rta1SelectedSection = section
  }

  setRTA2SelectedSection(section) {
    this.rta2SelectedSection = section
  }

  setFullScore(score) {
    this.fullScore = score
  }

  setSectionScore(score) {
    this.sectionScore = score
  }

  setLang(lang) {
    this.lang = lang
  }

  getRTAText1() {
    return this.rtaText1
  }

  getRTAText2() {
    return this.rtaText2
  }

  clearRTAText() {
    this.rtaText1 = ''
    this.rtaText2 = ''
  }

  clearRTAText1() {
    this.rtaText1 = ''
  }

  clearRTAText2() {
    this.rtaText2 = ''
  }

  clearMetaData() {
    this.metadata1 = {}
    this.metadata2 = {}
  }

  clearRTAScores() {
    this.sectionScore = 0.0
    this.fullScore = 0.0
  }

  clearSectionIDs(id) {
    id === 1
      ? (this.rtaNav1 = { sectionID: null, pageNum: 1 })
      : (this.rtaNav2 = { sectionID: null, pageNum: 1 })
  }

  clearKeywordMatches() {
    this.setKeywordMatches1([])
    this.setKeywordMatches2([])
  }
}
