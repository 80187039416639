import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Text } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronUp,
  faTimes,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { useStores } from 'hooks/useStores'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Snackbar from 'components/Snackbar/Snackbar'

const StyledButton = styled(Button)`
  width: 100%;
  text-transform: none;
`
const CompareContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto 12px;
`
const CompareButton = styled.div`
  color: white;
  display: flex;
  font-weight: bold;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const CompareBucketContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
`

const CompareDocument = styled.div`
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const FieldText = styled(Text)`
  color: ${(props) => (props.hasText ? 'black' : 'gray')};
`

const CloseBtn = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-left: 8px;
  margin-right: 4px;
`

export const CompareBucket = observer(() => {
  const { rtaCompareStore } = useStores()

  const history = useHistory()

  const handleGo = () => {
    rtaCompareStore.setLang({ label: 'English', value: 'EN' })
    rtaCompareStore.setKeywordMatches1([])
    rtaCompareStore.setKeywordMatches2([])
    const doesTitlesExist =
      rtaCompareStore.compareBucket1.title &&
      rtaCompareStore.compareBucket2.title
    doesTitlesExist
      ? history.push(
          `/compare?rta_id_1=${rtaCompareStore.compareBucket1.id}&rta_id_2=${rtaCompareStore.compareBucket2.id}`
        )
      : setOpenCompareSnackbar(true)
  }

  const [openCompareSnackbar, setOpenCompareSnackbar] = useState(false)
  const handleCompareClose = () => {
    setOpenCompareSnackbar(false)
  }
  return (
    <CompareContainer>
      <StyledButton
        variant="primary"
        borderRadius="0"
        buttonColor="#5E6D8C"
        mt={2}
        onClick={() =>
          rtaCompareStore.setIsCompareBucketOpen(
            !rtaCompareStore.isCompareBucketOpen
          )
        }
      >
        <CompareButton>
          Compare
          {rtaCompareStore.isCompareBucketOpen ? (
            <FontAwesomeIcon icon={faChevronUp} color="white" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} color="white" />
          )}
        </CompareButton>
      </StyledButton>
      {rtaCompareStore.isCompareBucketOpen && (
        <CompareBucketContent>
          <Text mb={2} fontSize="14px">
            COMPARE
          </Text>
          <CompareDocument>
            <FieldText ml={2} hasText={rtaCompareStore.compareBucket1.title}>
              {rtaCompareStore.compareBucket1.title
                ? rtaCompareStore.compareBucket1.title
                : 'Choose a document'}
            </FieldText>
            <CloseBtn onClick={() => rtaCompareStore.setCompareBucket1({})}>
              <FontAwesomeIcon icon={faTimes} color="#403E3F" />
            </CloseBtn>
          </CompareDocument>
          <Text my={2} fontSize="14px">
            WITH
          </Text>
          <CompareDocument>
            <FieldText ml={2} hasText={rtaCompareStore.compareBucket2.title}>
              {rtaCompareStore.compareBucket2.title
                ? rtaCompareStore.compareBucket2.title
                : 'Choose a document'}
            </FieldText>
            <CloseBtn onClick={() => rtaCompareStore.setCompareBucket2({})}>
              <FontAwesomeIcon icon={faTimes} color="#403E3F" />
            </CloseBtn>
          </CompareDocument>{' '}
          <Button
            mt="2"
            buttonColor="#2A8C82"
            variant="primary"
            onClick={handleGo}
            alignSelf="flex-end"
          >
            GO
          </Button>
        </CompareBucketContent>
      )}
      <Snackbar
        open={openCompareSnackbar}
        onClose={handleCompareClose}
        message="Not enough titles entered"
      />
    </CompareContainer>
  )
})

export default CompareBucket
