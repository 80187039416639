export function formatRTADate(date1, date2) {
  const euDateConvention = (date) => {
    const dateList = date.split('-')
    return `${dateList[2]} - ${dateList[1]} - ${dateList[0]}`
  }

  if (!date1 && !date2) {
    return 'No Date'
  }

  if (!date1) {
    return euDateConvention(date2.split('T')[0])
  }

  if (!date2) {
    return euDateConvention(date1.split('T')[0])
  }

  const d1 = new Date(date1)
  const d2 = new Date(date2)
  const timestampInSeconds1 = Math.round(d1.getTime() / 1000)
  const timestampInSeconds2 = Math.round(d2.getTime() / 1000)

  if (timestampInSeconds1 > timestampInSeconds2) {
    return euDateConvention(date2.split('T')[0])
  } else {
    return euDateConvention(date1.split('T')[0])
  }
}
