import React from 'react'
import { Button } from 'asimov'
import { withStyles } from '@material-ui/core/styles'
import { default as BaseMaterialUISnackbar } from '@material-ui/core/Snackbar'

const MaterialUISnackbar = withStyles({
  root: {
    '& .MuiSnackbarContent-root': {
      fontFamily: 'Source Sans Pro',
      color: 'white',
      backgroundColor: '#2a8c82',
      boxShadow: '-2px -2px 156px -38px rgba(0, 0, 0, 0.5)',
    },
  },
})(BaseMaterialUISnackbar)

export const Snackbar = ({ open, onClose, message }) => {
  return (
    <MaterialUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
      action={
        <Button
          variant="tertiary"
          buttonColor="white"
          onClick={() => {
            if (onClose) onClose()
          }}
        >
          close
        </Button>
      }
    />
  )
}

export default Snackbar
