import React, { useState } from 'react'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import Select from 'react-select'
import { Button, Input, Box, Text } from 'asimov'
import { observer } from 'mobx-react-lite'
import UserService from 'services/UserService'
import constants from 'utils/constants'
import { useHistory } from 'react-router-dom'

const Root = styled.div`
  display: block;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: '#A9AAAC',
    width: width,
    backgroundColor: '#F2F1F0',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '240px',
    fontFamily: 'Source Sans Pro',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const UserManagementAddUserPage = observer(() => {
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [pass, setPass] = useState('')
  const [role, setRole] = useState(constants.roleOptions[1])
  const [confirmPass, setConfirmPass] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const history = useHistory()

  const addUser = async () => {
    setErrorMessage('')

    const userService = new UserService()

    if (
      firstname === '' ||
      lastname === '' ||
      email === '' ||
      pass === '' ||
      confirmPass === ''
    ) {
      setErrorMessage('Required fields must not be empty')
    }

    if (pass !== confirmPass) {
      setErrorMessage('Passwords must match')
      return
    }

    const payload = {
      email,
      firstname,
      lastname,
      role: role.value,
      password: pass,
    }

    await userService.createUser(payload)

    history.push('/user-management')
  }

  return (
    <Root>
      <Header type="userManagement" />
      <Container>
        <Box width="500px" bg="white" p={5} my={5}>
          {errorMessage && (
            <Box bg="#D94E41" p="2" mb="3">
              <Text fontWeight="bold" color="white" fontSize="14px">
                {errorMessage}
              </Text>
            </Box>
          )}
          <h2>Add User</h2>
          <p>First name *</p>
          <CustomInput
            width={400}
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <p>Last name *</p>
          <CustomInput
            width={400}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <p>Email *</p>
          <CustomInput
            width={400}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p>Password *</p>
          <CustomInput
            type="password"
            width={400}
            value={pass}
            onChange={(e) => setPass(e.target.value)}
          />
          <p>Confirm Password *</p>
          <CustomInput
            type="password"
            width={400}
            value={confirmPass}
            onChange={(e) => setConfirmPass(e.target.value)}
          />
          <p>Role *</p>
          <Select
            width="240px"
            value={role}
            color="#5E6D8C"
            styles={customStyles}
            options={constants.roleOptions}
            onChange={(selected) => setRole(selected.value)}
          />
          <Button
            mt={5}
            variant="primary"
            buttonColor="#5E6D8C"
            alignSelf="flex-start"
            width={100}
            onClick={addUser}
          >
            Add User
          </Button>
        </Box>
      </Container>
    </Root>
  )
})

export default UserManagementAddUserPage
