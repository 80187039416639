import React, { useState } from 'react'
import styled from 'styled-components'
import { ClickAwayListener } from '@material-ui/core/'
import { Button, Text } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'
import Snackbar from 'components/Snackbar/Snackbar'

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: -120px;
  padding: 20px;
  min-width: 210px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.5);
  z-index: 100;
`

const ProjectDropDown = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;

  & > div {
    width: 100%;
  }
`

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`

const ButtonIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & > svg:hover {
    color: black;
  }
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: '#A9AAAC',
    width: width,
    backgroundColor: '#F2F1F0',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
  }),
  menu: (provided, state) => ({
    ...provided,
    fontFamily: 'Source Sans Pro',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const ProjectPopper = observer((rtaId) => {
  const { projectStore, userStore } = useStores()

  const [open, setOpen] = useState(false)
  const [projectId, setProjectId] = useState(null)
  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  const reformatProjectList = () => {
    const filteredProjects = projectStore.projects.filter(
      (project) => parseInt(project.user_id) === parseInt(userStore.user.id)
    )
    return filteredProjects.map((project) => {
      return { label: project.title, value: project.id }
    })
  }
  const saveRTAToProject = () => {
    projectStore.addRTAToProject(projectId, rtaId.rtaId)
  }

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const handleOpen = () => {
    setOpenSnackBar(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <ButtonIcon onClick={handleClick}>
            <FontAwesomeIcon icon={faFolder} color="#403E3F" />
          </ButtonIcon>

          {open ? (
            <PopperContainer>
              <Text fontSize="16px" fontWeight="bold" color="#403E3F">
                SAVE TO PROJECT
              </Text>
              <ProjectDropDown>
                <Select
                  width="100%"
                  placeholder="Select a project"
                  options={reformatProjectList()}
                  styles={customStyles}
                  onChange={(selected) => {
                    setProjectId(selected.value)
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              </ProjectDropDown>
              <ButtonContainer>
                <Button
                  buttonColor="#403E3F"
                  variant="tertiary"
                  onClick={handleClick}
                >
                  Cancel
                </Button>
                <Button
                  ml={2}
                  variant="primary"
                  buttonColor="#5E6D8C"
                  onClick={() => {
                    saveRTAToProject()
                    handleClick()
                    handleOpen()
                  }}
                >
                  Save
                </Button>
              </ButtonContainer>
            </PopperContainer>
          ) : null}
        </div>
      </ClickAwayListener>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        message="RTA Document Added"
      />
    </div>
  )
})
export default ProjectPopper
