import { makeAutoObservable, runInAction, computed } from 'mobx'
import RTAService from 'services/RTAService'

export class RTAViewStore {
  selectedSection = { sectionID: 1, sectionName: '' }
  currPageNum = 1
  currPageCount = 1
  currText = ''
  currLang = { label: 'English', value: 'EN' }
  rtaID = 0
  rtaInfo = {}
  rtaSections = []
  rtaList = []
  similarRTAList = []
  isSimilarRTAListLoading = false
  rtaLink = null
  currPageRange = []
  isTranslated = false
  keyword = ''
  keywordMatches = []

  // Page range variables
  pageIndex = 0
  pageRange = { pages: { start: 1, end: 1 }, shouldUpdate: true }

  // Loading variables
  isSectionLoading = false
  isTextLoading = false
  isTitleLoading = false

  constructor() {
    makeAutoObservable(this, {
      rtaOptions: computed,
    })
  }
  get rtaOptions() {
    const options = this.rtaList.map((rta) => {
      return { value: rta.rta_id, label: rta.name }
    })
    return options
  }

  fetchRTAs() {
    const rtaService = new RTAService()
    rtaService.getAllRTAs().then((response) => {
      runInAction(() => {
        const result = response.data.result
        this.rtaList = result.sort((rta1, rta2) => {
          if (rta1.name < rta2.name) {
            return -1
          }
          if (rta1.name > rta2.name) {
            return 1
          }
          return 0
        })
      })
    })
  }

  async fetchRTAInfo(lang) {
    const rtaService = new RTAService()
    this.setIsSectionLoading(true)
    this.setSectionList([])

    await rtaService.getRTAInfo(this.rtaID).then((response) => {
      this.setRTAInfo(response.data)
    })

    await rtaService.getSections(this.rtaID, lang).then((response) => {
      this.setSectionList(response.data.result)
      this.setIsSectionLoading(false)
    })
  }

  async fetchSectionInfo(sectionID) {
    const rtaService = new RTAService()

    const resp1 = await rtaService.getSectionInfo(this.rtaID, sectionID)
    const resp2 = await rtaService.getPageCount(this.rtaID, sectionID)

    this.setSelectedSection({
      sectionID: sectionID,
      sectionName: resp1.data.section_name,
    })
    this.setIsTranslated(resp1.data.is_translated)

    this.setCurrPageCount(resp2.data.page_count)
  }

  fetchRTAText({ sectionID, pageNum }) {
    const rtaService = new RTAService()
    rtaService.getRTAText(this.rtaID, sectionID, pageNum).then((response) => {
      this.setCurrText(response.data.raw_text)
      this.setCurrPageNum(pageNum)
    })
  }

  async fetchPageRange(pageRange) {
    const rtaService = new RTAService()

    let { start, end } = pageRange.pages
    if (this.currPageCount <= 10 || this.currPageCount < end) {
      end = this.currPageCount
    }
    let rtaTextPromises = []
    for (let i = start; i < end + 1; i++) {
      rtaTextPromises = rtaTextPromises.concat(
        rtaService.getRTAText(this.rtaID, this.selectedSection.sectionID, i)
      )
    }
    const rtaText = (await Promise.all(rtaTextPromises)).reduce(
      (acc, response) => {
        return acc + ' ' + response.data.raw_text
      },
      ''
    )
    this.setCurrText(rtaText)
    this.setPageRange({ pages: { start, end }, shouldUpdate: false })
    this.setCurrPageRange(pageRange)
  }

  async fetchSimilarRTAs(rtaID) {
    this.setIsLoadingSimilarRTAList(true)
    const rtaService = new RTAService()

    let newRTAList = []
    const rtas = await rtaService.getSimilarRTAs(rtaID).then((res) => res.data)

    for (const rta of rtas) {
      const firstSection = await rtaService
        .getSections(rta.rta_id)
        .then((response) =>
          response.data.result.find((section) => section.lang === 'EN')
        )
      newRTAList.push({
        rank: rta.rank,
        rtaID: rta.rta_id,
        rtaName: rta.name,
        sectionID: firstSection.section_id,
        sectionName: firstSection.section_name,
        score: rta.score,
      })
    }

    this.setSimilarRTAList(newRTAList)
    this.setIsLoadingSimilarRTAList(false)
  }

  fetchKeywordMatches(keyword) {
    const rtaService = new RTAService()
    this.setKeywordMatches([])
    return rtaService
      .navigateKeywords(keyword, this.selectedSection.sectionID)
      .then((response) => {
        this.setKeywordMatches(response.data)
      })
  }

  fetchHighlightedRTAText(pageNum, start, end) {
    const rtaService = new RTAService()
    rtaService
      .getRTAText(this.rtaID, this.selectedSection.sectionID, pageNum)
      .then((response) => {
        const rawText = response.data.raw_text
        const preText = rawText.slice(0, start)
        const highlightedText =
          '<span id="highlight">' + rawText.slice(start, end) + '</span>'
        const postText = rawText.slice(end)
        this.setCurrText(preText + highlightedText + postText)
        this.setPageRange({
          pages: { start: pageNum, end: pageNum },
          shouldUpdate: false,
        })
        this.setCurrPageNum(pageNum)
      })
  }

  computePageRange(pageNum) {
    let pageRange = {}
    let start = 0
    let end = 0
    let remainder = pageNum % 10
    if (pageNum % 10 === 0) {
      pageRange = { start: pageNum - 9, end: pageNum }
    } else {
      if (pageNum === 1) {
        pageRange = { start: 1, end: 10 }
      } else {
        start = pageNum - remainder
        end = start + 10
        pageRange = { start: start + 1, end }
      }
    }
    return pageRange
  }

  computePageIndex(pageNum) {
    const range = 10
    return Math.floor(pageNum / range)
  }

  setRTAID(rtaID) {
    this.rtaID = rtaID
  }

  setRTAInfo(responseData) {
    const result = responseData.result
    this.rtaInfo = {
      rtaID: result.rta_id,
      rtaName: result.name,
    }
  }

  setSectionList(sections) {
    this.rtaSections = sections.map((section) => {
      return {
        sectionID: section.section_id,
        sectionName: section.section_name,
      }
    })
  }

  setCurrPageRange(pageRange) {
    this.currPageRange = pageRange
  }
  setSelectedSection(section) {
    this.selectedSection = section
  }

  setCurrText(text) {
    const newlineTrimmed = text.replace(/^\n+|\n+$/g, '')
    const withLineBreaks = newlineTrimmed.replaceAll('\n', '<br/>')
    if (newlineTrimmed.includes('<table')) {
      this.currText = newlineTrimmed
    } else {
      this.currText = withLineBreaks
    }
  }

  setCurrPageNum(pageNum) {
    this.currPageNum = pageNum
  }

  setCurrPageCount(pageCount) {
    this.currPageCount = pageCount
  }

  setSimilarRTAList(rtaList) {
    this.similarRTAList = rtaList
  }

  setIsLoadingSimilarRTAList(loading) {
    this.isSimilarRTAListLoading = loading
  }

  setRTALink(link) {
    this.rtaLink = link
  }

  setIsTranslated(translated) {
    this.isTranslated = translated
  }

  setKeywordMatches(keywordMatches) {
    this.keywordMatches = keywordMatches
  }

  setPageRange(pageRange) {
    this.pageRange = pageRange
  }

  setPageIndex(pageIndex) {
    this.pageIndex = pageIndex
  }

  setKeyword(keyword) {
    this.keyword = keyword
  }

  setIsSectionLoading(loading) {
    this.isSectionLoading = loading
  }

  setIsTextLoading(loading) {
    this.isTextLoading = loading
  }

  setIsTitleLoading(loading) {
    this.isTitleLoading = loading
  }

  setCurrLang(lang) {
    this.currLang = lang
  }

  getPageText(sectionID, pageNum) {
    const textKey = this.getRTATextKey(this.rtaID, sectionID, pageNum)
    return this.rtaTextMap[textKey]
  }

  getSectionInfo(sectionID) {
    const key = this.getSectionInfoKey(this.rtaID, sectionID)
    return this.rtaSectionInfoMap[key]
  }

  getRTATextKey(rtaID, sectionID, pageNum) {
    return `rtaID=${rtaID}&sectionID=${sectionID}&pageNum=${pageNum}`
  }

  getSectionInfoKey(rtaID, sectionID) {
    return `rtaID=${rtaID}&sectionID=${sectionID}`
  }

  getKeyword() {
    return this.keyword
  }
}
