import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Text, Button } from 'asimov'
import { Tab, Tabs, TabPanel } from 'components/Tabs'
import Header from 'components/Header/Header'
import StatusTable from 'components/StatusTable/StatusTable'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useStores } from 'hooks/useStores'
import constants from 'utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  display: block;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  padding: 40px 50px 0px;
`

const TableContainer = styled.div`
  display: block;
  background-color: white;
  height: calc(100% - 40px);
  padding: 20px;
`
const StyledButton = styled(Button)`
  text-transform: none;
  color: grey;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
const CustomTab = (props) => <Tab selectedcolor="white" pad="5px" {...props} />

export const RTAManagementStatusPage = observer(() => {
  const location = useLocation()
  const history = useHistory()
  const { rtaManagementStore } = useStores()
  const [tab, setTab] = useState(0)

  const handleEdit = (lang, sectionID) => {
    const newLoc = `/rta-management/sections/${rtaManagementStore.currRTAID}?lang=${lang}&section=${sectionID}`
    history.push(newLoc)
  }

  useEffect(() => {
    rtaManagementStore.setCurrRTAMetadata(null)
    const pathname = location.pathname.split('/')
    const rtaID = pathname[3]
    rtaManagementStore.setCurrRTAID(rtaID)
    rtaManagementStore.fetchRTAMetadata(rtaID)
    rtaManagementStore.fetchRTASections(rtaID)
  }, [location, rtaManagementStore])

  return (
    <Root>
      <Header />
      <Container>
        <div>
          <Box flexDirection="row" alignItems="center">
            <Text fontSize="22px" ml={2} mb={1}>
              Ingest status of{' '}
              {rtaManagementStore.currRTAMetadata
                ? rtaManagementStore.currRTAMetadata.name
                : ''}
            </Text>
          </Box>
          <StyledButton
            variant="tertiary"
            buttonColor={'black'}
            onClick={() => history.push('/rta-management')}
          >
            <Text fontSize="15px" mr={2}>
              Back to RTA list
            </Text>
            <FontAwesomeIcon icon={faArrowRight} color="grey" />
          </StyledButton>
        </div>
        <Box bg="#DBDBDB" mt="32px">
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <CustomTab label="ENGLISH" />
            <CustomTab label="SPANISH" />
            <CustomTab label="FRENCH" />
          </Tabs>
        </Box>
        {constants.languageTabs
          .filter((tab) => tab !== 'ALL')
          .map((tabSection, index) =>
            tab === index ? (
              <TabPanel
                key={index}
                value={tab}
                index={index}
                height="calc(100% - 140px)"
              >
                <TableContainer>
                  <StatusTable langCode={tabSection} handleEdit={handleEdit} />
                </TableContainer>
              </TabPanel>
            ) : null
          )}
      </Container>
    </Root>
  )
})

export default RTAManagementStatusPage
