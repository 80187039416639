import { makeAutoObservable, runInAction } from 'mobx'
import UserService from 'services/UserService'

export class UserManagementStore {
  users = []
  user = {}

  constructor() {
    makeAutoObservable(this)
  }

  async fetchUsers() {
    runInAction(() => {
      this.users = []
    })

    const userService = new UserService()
    const response = await userService.getUsers()

    runInAction(() => {
      this.users = response.data.result
    })
  }

  async fetchUser(userID) {
    runInAction(() => {
      this.user = {}
    })

    const userService = new UserService()
    const response = await userService.getUser(userID)

    runInAction(() => {
      this.user = response.data
    })
  }
}
