import React, { useEffect } from 'react'
import { Text, Box } from 'asimov'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import Loader from 'react-loader-spinner'

import qs from 'qs'
import { useDebounce } from 'react-use'

import { useStores } from 'hooks/useStores'
import PageSlider from 'components/PageSlider/PageSlider'

const Root = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  padding: 50px 45px 10px 45px;
  left: 310px;
  width: 65%;
`

const DocumentContainer = styled.div`
  overflow: auto;
  height: 75vh;
  background-color: white;
  padding: 20px;
`

const Document = styled.div`
  margin-bottom: 20px;
  background-color: white;

  #highlight {
    background-color: yellow;
  }
`

const Spinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const SectionTitle = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 27px;
`

const SectionName = styled(Text)`
  padding-top: 4px;
  padding-bottom: 4px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TranslatedContainer = styled(Box)`
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  padding: 2px 4px;
  border: solid 1px #d94e41;
  border-radius: 4px;
`

export const RTAViewer = observer(() => {
  const { rtaViewStore } = useStores()
  const location = useLocation()

  const [, cancel] = useDebounce(
    () => {
      if (rtaViewStore.pageRange.shouldUpdate) {
        rtaViewStore
          .fetchPageRange(rtaViewStore.pageRange)
          .then(() => rtaViewStore.setIsTextLoading(false))
      }
    },
    2000,
    [rtaViewStore.pageRange]
  )

  const setPages = (index) => {
    let end = (index + 1) * 10
    let start = 0

    if (end % 10 === 0) {
      start = end - 9
    } else {
      start = end - (end % 10) + 1
    }
    rtaViewStore.setIsTextLoading(true)
    rtaViewStore.setPageRange({ pages: { start, end }, shouldUpdate: true })
  }

  useEffect(() => {
    const el = document.getElementById('highlight')
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [rtaViewStore, rtaViewStore.currText])

  useEffect(() => {
    async function fetchData() {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
      const sectionID = queryParams.section_id
      const pageNum = parseInt(queryParams.page_num)

      rtaViewStore.setIsTitleLoading(true)
      rtaViewStore.setIsTextLoading(true)
      rtaViewStore.setSelectedSection({ sectionID: null, sectionName: '' })
      rtaViewStore.setIsTranslated(parseInt(queryParams.translated))
      rtaViewStore.fetchRTAInfo(rtaViewStore.currLang.value)

      if (sectionID && pageNum) {
        await rtaViewStore.fetchSectionInfo(sectionID).then(() => {
          rtaViewStore.setIsTitleLoading(false)
        })
        const pageRange = rtaViewStore.computePageRange(pageNum)
        const pageIndex = rtaViewStore.computePageIndex(pageNum)
        rtaViewStore.setPageIndex(pageIndex)
        rtaViewStore.setPageRange({ pages: pageRange, shouldUpdate: true })
        cancel()
      }
    }
    fetchData()
  }, [location, rtaViewStore, cancel])

  return (
    <Root>
      {!rtaViewStore.isTitleLoading ? (
        <>
          <Header>
            <TextContainer>
              <TitleHeader>
                <Text fontSize={2} textWeight={500}>
                  {rtaViewStore.rtaInfo.rtaName}
                </Text>
              </TitleHeader>
              <SectionTitle>
                <SectionName visible={rtaViewStore.selectedSection.sectionName}>
                  {rtaViewStore.selectedSection.sectionName}
                </SectionName>
                {rtaViewStore.isTranslated &&
                  rtaViewStore.selectedSection.sectionName && (
                    <TranslatedContainer>
                      <Text color="#D94E41" fontSize="12px" fontWeight="bold">
                        Translated English Version
                      </Text>
                    </TranslatedContainer>
                  )}
                <Box marginLeft="auto">
                  <DownloadButton
                    title={rtaViewStore.rtaInfo.rtaName}
                    id={rtaViewStore.rtaID}
                    location="rta"
                  />
                </Box>
              </SectionTitle>
            </TextContainer>
          </Header>
          <PageSlider
            totalPages={rtaViewStore.currPageCount}
            pageIndex={rtaViewStore.pageIndex}
            setPageIndex={(index) => rtaViewStore.setPageIndex(index)}
            startPage={rtaViewStore.pageRange.pages.start}
            endPage={rtaViewStore.pageRange.pages.end}
            pageSlide={(index) => {
              setPages(index)
            }}
            hasReset={null}
            visible={!!rtaViewStore.currText}
          />
        </>
      ) : (
        <Box width="100%" alignItems="center">
          <Loader type="ThreeDots" color="#5E6D8C" height={60} width={60} />
        </Box>
      )}

      {rtaViewStore.isTextLoading ? (
        <Spinner>
          <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
        </Spinner>
      ) : null}
      {!rtaViewStore.isTextLoading && rtaViewStore.currText ? (
        <DocumentContainer>
          <Document
            dangerouslySetInnerHTML={{ __html: rtaViewStore.currText }}
          />
        </DocumentContainer>
      ) : null}
    </Root>
  )
})

export default RTAViewer
