import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Box, Text, Link } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashRestore,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'
import Loader from 'react-loader-spinner'
import Snackbar from 'components/Snackbar/Snackbar'
import RTAService from 'services/RTAService'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ScrollableBox = styled.div`
  display: block;
  overflow: auto;
  height: 90%;
`

const Header = styled.div`
  background-color: #f2f1f0;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-align: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 900;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  opacity: ${(props) => props.opacity};
`

const RowEntry = styled(Box)`
  flex-direction: row;
  padding: 8px 0px;
  width: ${(props) => props.width};
  align-items: center;
  justify-content: center;
`

const Icon = styled(FontAwesomeIcon)`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  &:hover {
    cursor: pointer;
  }
`

const SectionRow = ({ name, lang, link, id, handleRestore }) => {
  return (
    <Row id={id}>
      <RowEntry width="5%" />
      <RowEntry width="40%">
        <Text textAlign="center">{name}</Text>
      </RowEntry>
      <RowEntry width="15%">
        <Text textAlign="center">{lang}</Text>
      </RowEntry>
      <RowEntry width="30%">
        <Text textAlign="center" mr="2">
          {link}
        </Text>
        <Link href={link} target="_blank" color="#3E474F">
          <Icon icon={faExternalLinkAlt} />
        </Link>
      </RowEntry>
      <RowEntry width="10%" align="center">
        <Icon icon={faTrashRestore} onClick={() => handleRestore(id)} />
      </RowEntry>
    </Row>
  )
}

const RTADeletedSectionsTable = observer(({ sectionID }) => {
  const { rtaManagementStore } = useStores()
  const rtaService = new RTAService()
  const [openSnackBar, setOpenSnackBar] = useState(false)

  useEffect(() => {
    rtaManagementStore.fetchDeletedSections(rtaManagementStore.currRTAID) // eslint-disable-next-line
  }, [])

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return
    setOpenSnackBar(false)
  }

  const restoreSection = (sectionID) => {
    rtaService.restoreSection(sectionID).then(() => {
      setOpenSnackBar(true)
      rtaManagementStore.fetchDeletedSections(rtaManagementStore.currRTAID)
      rtaManagementStore.fetchRTASections(rtaManagementStore.currRTAID)
    })
  }

  return (
    <Container>
      <ScrollableBox>
        <Header>
          <Box py="8px" width="5%" />
          <Box py="8px" width="40%">
            Section Name
          </Box>
          <Box py="8px" width="15%">
            Language
          </Box>
          <Box py="8px" width="30%">
            Link
          </Box>
          <Box py="8px" width="10%">
            Actions
          </Box>
        </Header>
        {rtaManagementStore.isSectionsLoading ? (
          <Box width="100%" alignItems="center">
            <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
          </Box>
        ) : null}
        {!rtaManagementStore.isSectionsLoading &&
        rtaManagementStore.deletedSections.length === 0 ? (
          <Box alignItems="center" p="16px">
            <Text>No sections found for this language</Text>
          </Box>
        ) : null}
        {!rtaManagementStore.isSectionsLoading &&
          rtaManagementStore.deletedSections.map((section, index) => (
            <SectionRow
              key={index}
              id={section.section_id}
              name={section.section_name}
              lang={section.lang}
              link={section.link}
              handleRestore={restoreSection}
            />
          ))}
      </ScrollableBox>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        message={`${rtaManagementStore.currRTAMetadata.name} has been updated.`}
      />
    </Container>
  )
})

export default RTADeletedSectionsTable
