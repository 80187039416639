import React from 'react'
import { layout } from 'styled-system'
import styled from 'styled-components'
import { Box, Text } from 'asimov'

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

const SelectWrapper = styled.div`
  ${layout}
  width: ${(props) => props.width};
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;

  & input {
    font-family: 'Source Sans Pro';
  }

  & button {
    width: 100%;
  }
`

const Label = styled(Text)`
  flex-shrink: 0;
`

const customStyles = () => ({
  dropdownButton: (provided) => ({
    ...provided,
    fontFamily: 'Source Sans Pro',
    fontSize: '16px',
    background: '#F2F1F0',
    display: 'flex',
  }),
  control: (provided, { selectProps: { width } }) => ({
    ...provided,
    color: 'red',
    marginTop: '-5px',
    width: '100%',
    marginLeft: '-1px',
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '-3px',
    fontFamily: 'Source Sans Pro',
    boxShadow: '-2px -2px 156px -38px rgba(0, 0, 0, 0.50)',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? 'rgba(94, 109, 141, 0.3)'
      : isFocused
      ? 'rgba(94, 109, 141, 0.2)'
      : null,
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled &&
        (isSelected ? 'rgba(94, 109, 141, 0.3)' : 'rgba(94, 109, 141, 0.2)'),
    },
  }),
})

export const CustomSelect = ({ placeholder, data, width, onSelect, value }) => {
  const onChange = (selected) => {
    if (onSelect) onSelect(selected)
  }

  return (
    <SelectWrapper width={width}>
      <Box width="40%">
        <Label mr={2}>{placeholder}:</Label>
      </Box>
      <Box width="60%">
        <ReactMultiSelectCheckboxes
          options={data}
          placeholderButtonLabel={
            value
              ? value.length === 0
                ? 'Select...'
                : `${value.length} selected`
              : 'Select...'
          }
          onChange={onChange}
          value={value}
          styles={customStyles()}
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </Box>
    </SelectWrapper>
  )
}

export default CustomSelect
