import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import Select from 'react-select'
import { Button, Input, Box } from 'asimov'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/useStores'
import UserService from 'services/UserService'
import constants from 'utils/constants'

const Root = styled.div`
  display: block;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: '#A9AAAC',
    width: width,
    backgroundColor: '#F2F1F0',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: '240px',
    fontFamily: 'Source Sans Pro',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const UserManagementEditUserPage = observer(() => {
  const { userManagementStore } = useStores()
  const params = useParams()
  const userID = params.id

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [role, setRole] = useState('')

  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)

  const history = useHistory()

  const roleSelectOption = constants.roleOptions.find((e) => e.value === role)

  useEffect(() => {
    async function fetchUser() {
      await userManagementStore.fetchUser(userID)
      const user = userManagementStore.user
      setFirstname(user.firstname)
      setLastname(user.lastname)
      setRole(user.role)
    }
    fetchUser()
  }, [userManagementStore, userID])

  const updateUser = async () => {
    const userService = new UserService()
    const payload = {
      firstname,
      lastname,
      role,
    }
    await userService.updateUser(userID, payload)
    history.push('/user-management')
  }

  const deleteUser = async () => {
    setDeleteConfirmOpen(true)
    const userService = new UserService()
    await userService.deleteUser(userID)
    setDeleteConfirmOpen(false)
    history.push('/user-management')
  }

  const openDeleteConfirmDialog = () => {
    setDeleteConfirmOpen(true)
  }

  const closeDeleteConfirmDialog = () => {
    setDeleteConfirmOpen(false)
  }

  return (
    <Root>
      <Header type="userManagement" />
      <Container>
        <Box width="500px" bg="white" p={5} my={5}>
          <Box>
            <h2>Edit User</h2>
            <p>First name</p>
            <CustomInput
              width={400}
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <p>Last name</p>
            <CustomInput
              width={400}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
            <p>Role</p>
            <Select
              width="240px"
              value={roleSelectOption}
              color="#5E6D8C"
              styles={customStyles}
              options={constants.roleOptions}
              onChange={(selected) => setRole(selected.value)}
            />
            <Button
              mt={5}
              variant="primary"
              buttonColor="#5E6D8C"
              alignSelf="flex-start"
              width={100}
              onClick={updateUser}
            >
              Update User
            </Button>
          </Box>
          <Box mt={5}>
            <h2>Delete User</h2>
            <p>
              Once you delete this user, there is no going back. Please be
              certain.
            </p>
            <Button
              mt={2}
              buttonColor="#D94E41"
              variant="primary"
              alignSelf="flex-start"
              width={100}
              onClick={openDeleteConfirmDialog}
            >
              Delete User
            </Button>
          </Box>
        </Box>
      </Container>
      <Dialog
        open={deleteConfirmOpen}
        onClose={closeDeleteConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete User'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteUser} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  )
})

export default UserManagementEditUserPage
