import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'asimov'
import { observer } from 'mobx-react-lite'
import Loader from 'react-loader-spinner'
import Select from 'react-select'
import constants from 'utils/constants'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import { useStores } from 'hooks/useStores'

const SectionContainer = styled.div`
  padding: 20px 30px 10px 45px;
  display: block;
  height: calc(100% - 100px);
  overflow: auto;
`
const SectionHeader = styled.div`
  display: flex;
  flex-direction: column;
`
const SectionButton = styled.button`
  width: 90%;
  margin-top: 10px;
  background-color: #ebebe9;
  text-align: left;
  display: block;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 8px 0px;

  &:hover {
    color: #2a8c82;
  }
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const NoLanguage = styled.div`
  margin-top: 10px;
`
const customStyles = {
  control: () => ({
    backgroundColor: 'white',
    borderColor: '#E5E4E1',
    color: 'black',
    display: 'flex',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const SectionSelect = observer(() => {
  const { rtaViewStore } = useStores()

  const handleSectionClick = (index) => {
    const run = async (index) => {
      const selectedSection = rtaViewStore.rtaSections[index]

      if (selectedSection) {
        const sectionID = selectedSection.sectionID
        const pageRange = { pages: { start: 1, end: 10 }, shouldUpdate: true }

        rtaViewStore.setCurrText('')
        rtaViewStore.setSelectedSection({ sectionID: null, sectionName: '' })
        rtaViewStore.setIsTextLoading(true)
        await rtaViewStore.fetchSectionInfo(sectionID).then(() => {
          rtaViewStore.setIsTitleLoading(false)
        })
        rtaViewStore
          .fetchPageRange(pageRange)
          .then(() => rtaViewStore.setIsTextLoading(false))
        rtaViewStore.setPageIndex(0)
        if (rtaViewStore.getKeyword() !== '') {
          rtaViewStore.fetchKeywordMatches(rtaViewStore.getKeyword())
        }
      } else {
        rtaViewStore.setIsTextLoading(false)
        rtaViewStore.setIsTitleLoading(false)
        rtaViewStore.setCurrText('')
        rtaViewStore.setSelectedSection({ sectionID: '', sectionName: '' })
      }
    }
    run(index)
  }

  const handleLangSelect = (lang) => {
    rtaViewStore.setCurrLang(lang)
    rtaViewStore.setIsTextLoading(true)
    rtaViewStore.setIsTitleLoading(true)
    rtaViewStore.setKeyword('')
    rtaViewStore.setKeywordMatches([])
    rtaViewStore.fetchRTAInfo(lang.value).then(() => {
      handleSectionClick(0)
    })
  }

  return (
    <SectionContainer>
      <SectionHeader>
        <Select
          width={'130px'}
          placeholder={'Lang'}
          value={rtaViewStore.currLang}
          options={constants.languageOptions}
          styles={customStyles}
          onChange={(value) => handleLangSelect(value)}
          defaultValue={constants.languageOptions[0]}
          className="basic-multi-select"
          classNamePrefix="select"
        />

        <Text fontSize={'18px'} fontWeight={'bold'} display="block" mt={3}>
          Sections
        </Text>
      </SectionHeader>
      {rtaViewStore.isSectionLoading ? (
        <Box alignItems="center">
          <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
        </Box>
      ) : null}
      {rtaViewStore.rtaSections.length === 0 &&
      !rtaViewStore.isSectionLoading ? (
        <NoLanguage> RTA is not available in this language</NoLanguage>
      ) : (
        rtaViewStore.rtaSections.map((section, index) => (
          <SectionContent key={index}>
            <SectionButton
              key={index}
              onClick={() => handleSectionClick(index)}
            >
              {rtaViewStore.selectedSection.sectionID === section.sectionID ? (
                <Text fontSize={'15px'} color="#2A8C82" fontWeight="bold">
                  {section.sectionName}
                </Text>
              ) : (
                <Text fontSize={'15px'}> {section.sectionName}</Text>
              )}
            </SectionButton>

            <DownloadButton
              title={section.sectionName}
              id={section.sectionID}
              type="section"
            />
          </SectionContent>
        ))
      )}
    </SectionContainer>
  )
})

export default SectionSelect
