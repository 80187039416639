import { makeAutoObservable, toJS } from 'mobx'
import { formatRTADate } from 'utils/dateFormatter'
import constants from 'utils/constants'
import RTAService from 'services/RTAService'

export class SearchStore {
  results = []
  resultsCount = 0
  formFilters = {
    lang: [{ label: 'English', value: 'EN' }],
    CoverageID: [],
    TypeID: null,
    LegalCoverageID: null,
    Region: [],
    RTASignatories: [],
    SignatureDate: [1948, new Date().getFullYear()],
    EntryIntoForceDate: [1948, new Date().getFullYear()],
    is_legal_text: false,
  }
  searchTerm = ''
  isSearchLoading = false
  isExporting = false
  allResults = []
  uiPageNum = 1

  constructor() {
    makeAutoObservable(this)
  }

  search({ page }) {
    this.setResults([])
    this.setIsSearchLoading(true)

    const filters = this.makeSearchFilters(toJS(this.formFilters))

    const rtaService = new RTAService()
    rtaService.search({ query: this.searchTerm, filters, page }).then(
      (response) => {
        this.setIsSearchLoading(false)
        this.setResults(response.data.results)
        this.setResultsCount(response.data.results_count)
      },
      (error) => {
        this.setIsSearchLoading(false)
      }
    )
  }

  exportSearch() {
    this.setAllResults([])

    this.setIsExporting(true)
    const filters = this.makeSearchFilters(toJS(this.formFilters))

    const rtaService = new RTAService()

    rtaService.exportSearch({ query: this.searchTerm, filters, page: 1 }).then(
      (response) => {
        this.setIsExporting(false)

        this.setAllResults(
          this.reformatSearchResult(this.searchTerm, filters, response.data)
        )
      },
      (error) => {
        this.setIsExporting(false)
      }
    )
  }

  reformatSearchResult(query, filters, results) {
    let reformattedList = []
    for (let i = 0; i < results.length; i++) {
      const reformatted = {}
      reformatted['SearchQuery'] = query
      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'lang') {
          reformatted['Filter: Language'] = value
        } else if (key === 'is_legal_text') {
        } else if (key === 'RTASignatories') {
          reformatted[`Filter: ${key}`] = this.reformatFilter(
            value,
            constants.currentSignatoriesOptions,
            key
          )
        } else if (key === 'LegalCoverageID') {
          reformatted[`Filter: Notification Under`] = this.reformatFilter(
            value,
            constants.notificationUnderExactOptions
          )
        } else if (key === 'TypeID') {
          reformatted[`Filter: ${key}`] = this.reformatFilter(
            value,
            constants.typeExactOptions
          )
        } else if (key === 'CoverageID') {
          reformatted[`Filter: ${key}`] = this.reformatFilter(
            value,
            constants.coverageOptions
          )
        } else {
          reformatted[`Filter: ${key}`] = value
        }
      })
      Object.entries(results[i]).forEach(([key, value]) => {
        if (key === 'rta_id') {
          reformatted['RTAID'] = value
        } else if (key === 'name') {
          reformatted['RTAName'] = value
        } else if (key === 'meta') {
          reformatted['RelevanceScore'] = value.score
          reformatted['PreviewText'] = this.shortenSnippet(value.snippet)
        } else if (key === 'section_name') {
          reformatted['SectionName'] = value
        }
        Object.entries(filters).forEach(([filterKey, filterValue]) => {
          if (filterKey === key && filterKey !== 'is_legal_text') {
            reformatted[[key]] = value
          }
        })
      })
      reformatted['EntryIntoForceDate'] = formatRTADate(
        results[i].GEntryIntoForceDate,
        results[i].SEntryIntoForceDate
      )
      reformatted['SignatureDate'] = formatRTADate(
        results[i].GSignatureDate,
        results[i].SSignatureDate
      )
      reformattedList = reformattedList.concat(reformatted)
    }
    return reformattedList
  }

  reformatFilter(list, constantList, filterKey) {
    if (filterKey) {
      return list.reduce((acc, index) => {
        const arr = Object.entries(constantList).filter(
          ([key, value]) => parseInt(value.value) === parseInt(index)
        )
        return acc + arr[0][1].label + ','
      }, '')
    } else {
      if (list.length) {
        return list.reduce((acc, index) => {
          return acc + constantList[parseInt(index) - 1].label + ','
        }, '')
      } else {
        return constantList[parseInt(list) - 1].label
      }
    }
  }

  shortenSnippet(snippet) {
    const words = snippet.split(' ')
    const first100 =
      words.length > 100
        ? words.slice(0, 100)
        : words.slice(0, words.length - 1)
    const snippetText = first100.reduce((acc, text) => {
      return acc + ' ' + text
    }, '')
    return snippetText.replace(/(\r\n|\n|\r)/gm, '')
  }

  makeSearchFilters(formFilters) {
    let searchFilters = {}

    const valueOnly = (item) => {
      return item.value
    }
    searchFilters['lang'] = formFilters['lang'].map(valueOnly)
    searchFilters['CoverageID'] = formFilters['CoverageID'].map(valueOnly)

    if (formFilters['TypeID']) {
      searchFilters['TypeID'] = formFilters['TypeID'].value
    }

    if (formFilters['LegalCoverageID']) {
      searchFilters['LegalCoverageID'] = formFilters['LegalCoverageID'].value
    }

    searchFilters['Region'] = formFilters['Region'].map(valueOnly)
    searchFilters['RTASignatories'] = formFilters['RTASignatories'].map(
      valueOnly
    )
    searchFilters['EntryIntoForceDate'] = formFilters['EntryIntoForceDate']
    searchFilters['SignatureDate'] = formFilters['SignatureDate']
    searchFilters['is_deleted'] = [0]
    searchFilters['is_legal_text'] = formFilters['is_legal_text'] ? [1] : [0]

    return searchFilters
  }

  setResults(results) {
    this.results = results
  }

  setResultsCount(resultsCount) {
    this.resultsCount = resultsCount
  }

  setFormFilters(formFilters) {
    this.formFilters = formFilters
  }

  clearFormFilters() {
    this.formFilters = {
      lang: [],
      CoverageID: [],
      TypeID: null,
      LegalCoverageID: null,
      Region: [],
      RTASignatories: [],
      SignatureDate: [1948, new Date().getFullYear()],
      EntryIntoForceDate: [1948, new Date().getFullYear()],
    }
  }

  setSearchTerm(term) {
    this.searchTerm = term
  }

  setIsSearchLoading(state) {
    this.isSearchLoading = state
  }

  setAllResults(results) {
    this.allResults = results
  }

  setIsExporting(state) {
    this.isExporting = state
  }

  setUiPageNum(pageNum) {
    this.uiPageNum = pageNum
  }

  getPageCount() {
    const pageSize = 10
    return Math.ceil(this.resultsCount / pageSize)
  }

  isSearchDefault() {
    return (
      this.searchTerm === '' &&
      this.formFilters.lang.length === 0 &&
      this.formFilters['CoverageID'].length === 0 &&
      this.formFilters['TypeID'] === null &&
      this.formFilters['LegalCoverageID'] === null &&
      this.formFilters['Region'].length === 0 &&
      this.formFilters['RTASignatories'].length === 0 &&
      this.formFilters['SignatureDate'][0] === 1948 &&
      this.formFilters['SignatureDate'][1] === new Date().getFullYear() &&
      this.formFilters['EntryIntoForceDate'][0] === 1948 &&
      this.formFilters['SignatureDate'][1] === new Date().getFullYear()
    )
  }
}
