import React from 'react'
import { UserStore } from '../stores/UserStore'
import { SearchStore } from '../stores/SearchStore'
import { RTAViewStore } from '../stores/RTAViewStore'
import { RTACompareStore } from '../stores/RTACompareStore'
import { ProjectStore } from '../stores/ProjectStore'
import { RTAManagementStore } from '../stores/RTAManagementStore'
import { UserManagementStore } from 'stores/UserManagementStore'

export const storesContext = React.createContext({
  userStore: new UserStore(),
  searchStore: new SearchStore(),
  rtaViewStore: new RTAViewStore(),
  rtaCompareStore: new RTACompareStore(),
  projectStore: new ProjectStore(),
  rtaManagementStore: new RTAManagementStore(),
  userManagementStore: new UserManagementStore(),
})
