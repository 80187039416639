import React, { useState, useEffect } from 'react'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import { Box, Text, Button, Modal } from 'asimov'
import { Tab, Tabs, TabPanel } from 'components/Tabs'
import RTASectionsTable from 'components/RTASectionsTable'
import RTADeletedSectionsTable from 'components/RTADeletedSectionsTable'
import Snackbar from 'components/Snackbar/Snackbar'
import IndexLinksModal from 'components/IndexLinksModal'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router'
import { useStores } from 'hooks/useStores'
import constants from 'utils/constants'
import RTAService from 'services/RTAService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import qs from 'qs'

const Root = styled.div`
  display: block;
  height: 100vh;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  padding: 40px 50px 0px;
`

const TableContainer = styled.div`
  display: block;
  background-color: white;
  height: calc(100% - 40px);
  padding: 20px;
`

const SaveButton = styled(Button)`
  width: 150px;
  margin-left: auto;
  margin-top: 16px;
`
const StyledButton = styled(Button)`
  text-transform: none;
  color: grey;
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const ValidateButton = styled(Button)`
  margin-left: 10px;
  margin-top: 16px;
  width: 150px;
  height: 40px;
`

const TranslatedContainer = styled.div`
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  padding: 2px 4px;
  border: solid 1px #2a8c82;
  border-radius: 4px;
`

const CustomTab = (props) => <Tab selectedcolor="white" pad="5px" {...props} />

export const RTAManagementDetailPage = observer(() => {
  const history = useHistory()
  const location = useLocation()
  const { rtaManagementStore } = useStores()
  const [tab, setTab] = useState(0)
  const [sectionID, setSectionID] = useState()
  const [openModal, setOpenModal] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const rtaService = new RTAService()
  const errorMsg = `There's an error in updating `

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
    setSectionID(queryParams.section)
    const tabIndex = constants.languageTabs.indexOf(queryParams.lang)
    setTab(tabIndex > -1 ? tabIndex : 0)
    const pathname = location.pathname.split('/')
    const rtaID = pathname[3]
    rtaManagementStore.setCurrRTAMetadata(null)
    rtaManagementStore.setCurrRTAID(rtaID)
    rtaManagementStore.fetchRTAMetadata(rtaID)
    rtaManagementStore.fetchRTASections(rtaID)
  }, [location, rtaManagementStore])

  useEffect(() => {
    if (tab === 4) {
      rtaManagementStore.fetchDeletedSections(rtaManagementStore.currRTAID)
    }
  }, [tab, rtaManagementStore])

  const addSectionByIndexLink = () => {
    rtaManagementStore.fetchRTAIndexLinks()
    rtaManagementStore.setOpenIndexLinkModal(true)
  }

  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return
    setOpenSnackBar(false)
  }

  const save = () => {
    // Filter by language, and send a request per section
    constants.languageOptions.forEach((lang) => {
      rtaManagementStore.sections
        .filter((section) => section.lang === lang.value)
        .forEach(async (section, index) => {
          const { section_id, section_name, link, lang } = section
          if (section_id) {
            await rtaService
              .updateSection(section_id, section_name, link, lang, index + 1)
              .catch((error) =>
                setSnackbarMsg(
                  `${errorMsg} ${rtaManagementStore.currRTAMetadata.name}`
                )
              )
          } else {
            await rtaService
              .createSection(
                rtaManagementStore.currRTAID,
                section_name,
                link,
                lang,
                index + 1
              )
              .catch((error) =>
                setSnackbarMsg(
                  `${errorMsg} ${rtaManagementStore.currRTAMetadata.name}`
                )
              )
          }
        })
    })

    // Check if there are section_ids in original sections
    // that do not exist in the new sections
    rtaManagementStore.originalSections.forEach(async (originalSection) => {
      if (
        !rtaManagementStore.sections.find(
          (section) => section.section_id === originalSection.section_id
        )
      ) {
        await rtaService
          .deleteSection(originalSection.section_id)
          .catch((error) => setSnackbarMsg(errorMsg))
      }
    })

    // Show confirmation of saved item and refresh page
    setOpenSnackBar(true)
    setSnackbarMsg(
      `${rtaManagementStore.currRTAMetadata.name} has been updated.`
    )
  }
  const validate = () => {
    rtaManagementStore.validateRTA(rtaManagementStore.currRTAID)
    closeModal()
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <Root>
      <Header type={'rtaManagement'} />

      <Container>
        <div>
          <Box flexDirection="row" alignItems="center">
            <Text fontSize="22px" ml={2}>
              Configure{' '}
              {rtaManagementStore.currRTAMetadata
                ? rtaManagementStore.currRTAMetadata.name
                : ''}
            </Text>
            {rtaManagementStore.currRTAMetadata && (
              <TranslatedContainer>
                <Text color="#2A8C82" fontSize="14px" fontWeight="bold">
                  {rtaManagementStore.currRTAMetadata.status}
                </Text>
              </TranslatedContainer>
            )}

            <Button
              buttonColor="#3E474F"
              ml="auto"
              onClick={addSectionByIndexLink}
            >
              + Add Index Link
            </Button>
          </Box>
          <StyledButton
            variant="tertiary"
            buttonColor={'black'}
            onClick={() => history.push('/rta-management')}
          >
            <Text fontSize="15px" mr={2}>
              Back to RTA list
            </Text>
            <FontAwesomeIcon icon={faArrowRight} color="grey" />
          </StyledButton>
        </div>

        <Box bg="#DBDBDB" mt="32px">
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <CustomTab label="ALL" />
            <CustomTab label="ENGLISH" />
            <CustomTab label="SPANISH" />
            <CustomTab label="FRENCH" />
            <CustomTab label="ARCHIVED" />
          </Tabs>
        </Box>
        {constants.languageTabs.map((tabSection, index) =>
          tab === index ? (
            <TabPanel
              key={index}
              value={tab}
              index={index}
              height="calc(100% - 180px)"
            >
              <TableContainer>
                <RTASectionsTable
                  tab={tab}
                  langCode={tabSection}
                  sectionID={sectionID}
                  canSort={!(tabSection === 'ALL')}
                />
              </TableContainer>
            </TabPanel>
          ) : null
        )}
        {tab === 4 ? (
          <TabPanel value={tab} index={4} height="calc(100% - 180px)">
            <TableContainer>
              <RTADeletedSectionsTable sectionID={sectionID} />
            </TableContainer>
          </TabPanel>
        ) : null}
        <Snackbar
          open={openSnackBar}
          onClose={handleClose}
          message={snackbarMsg}
        />
        <Buttons>
          {tab !== 4 ? (
            <SaveButton variant="primary" buttonColor="#369DE8" onClick={save}>
              SAVE
            </SaveButton>
          ) : null}
          <ValidateButton
            variant="secondary"
            buttonColor="#369DE8"
            onClick={() => {
              setOpenModal(true)
            }}
          >
            VALIDATE
          </ValidateButton>
        </Buttons>
      </Container>

      {openModal && (
        <Modal onClose={closeModal}>
          <Box bg="white" width="500px" p="4" borderRadius="1">
            <Text fontSize="20">
              Validating this RTA will mark the specified sections as ready to
              be picked up by the ingestion pipeline. Please ensure that the
              provided links are accessible and of type PDF, HTML and DOCX. Are
              you sure you want to continue?
            </Text>
            <Buttons>
              <Button
                onClick={closeModal}
                mt="3"
                buttonColor="#3E474F"
                alignSelf="stretch"
              >
                Close
              </Button>
              <Button
                onClick={validate}
                mt="3"
                ml="3"
                buttonColor="#369DE8"
                alignSelf="stretch"
              >
                Confirm
              </Button>
            </Buttons>
          </Box>
        </Modal>
      )}

      {rtaManagementStore.openIndexLinkModal ? (
        <IndexLinksModal
          onClose={() => rtaManagementStore.setOpenIndexLinkModal(false)}
        />
      ) : null}
    </Root>
  )
})

export default RTAManagementDetailPage
