import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { Box, Button, Text } from 'asimov'
import Loader from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import constants from '../../../utils/constants'
import { useStores } from 'hooks/useStores'

const SectionContainer = styled.div`
  padding: 20px 30px 10px 45px;
`

const ButtonDrop = styled(Button)`
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`

const SimilarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const SimilarDocuments = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 0px;
  padding: 20px 0px 20px 0px;
  border-top: solid 1px #dfdfdd;
  border-bottom: solid 1px #dfdfdd;
`

const ResultsList = styled.div`
  display: block;
  max-height: 250px;
  overflow: auto;
`

const ResultContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
  width: 95%;
  padding: 10px 0px 10px 0px;
`

const SimilarityScore = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 6px;
  min-width: 30px;
  max-width: 30px;
  text-align: center;
  background-color: #2a8c82;
`
const BtnText = styled(Text)`
  display: block;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const FindSimilar = observer(() => {
  const history = useHistory()
  const { rtaViewStore, rtaCompareStore } = useStores()
  const [openSimilar, setOpenSimilar] = useState(true)

  useEffect(() => {
    rtaViewStore.fetchSimilarRTAs(rtaViewStore.rtaID)
  }, [rtaViewStore])

  const viewSelectedRTA = (result) => {
    const sectionID = result.sectionID
    const pageNum = result.pageNum || 1
    rtaViewStore.setCurrLang(constants.languageOptions[0])
    const newLoc = `/rta-view/${result.rtaID}?section_id=${sectionID}&page_num=${pageNum}`
    history.push(newLoc)
    rtaViewStore.setKeyword('')
    rtaViewStore.setKeywordMatches([])
    rtaViewStore.fetchSimilarRTAs(result.rtaID)
  }

  const compareSelectedRTA = (result) => {
    rtaCompareStore.setLang({ label: 'English', value: 'EN' })
    rtaCompareStore.setKeywordMatches1([])
    rtaCompareStore.setKeywordMatches2([])
    history.push(
      `/compare?rta_id_1=${rtaViewStore.rtaID}&rta_id_2=${result.rtaID}`
    )
  }

  return (
    <SectionContainer>
      <ButtonDrop
        variant={'tertiary'}
        buttonColor="black"
        onClick={() => {
          setOpenSimilar(!openSimilar)
        }}
      >
        <Text fontSize={'15px'} fontWeight={'bold'}>
          FIND SIMILAR DOCUMENTS
        </Text>
        {openSimilar ? (
          <FontAwesomeIcon icon={faChevronDown} color="#3E474F" />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} color="#3E474F" />
        )}
      </ButtonDrop>
      {openSimilar ? (
        <SimilarContainer>
          <Text fontSize="15px" pt={2}>
            See a list of documents similar to{' '}
            <strong>{rtaViewStore.rtaInfo.rtaName}</strong>
          </Text>
          <SimilarDocuments>
            <Text fontSize="15px" mb="1">
              <strong>TOP 10</strong> MOST SIMILAR DOCUMENTS
            </Text>
            <Text fontSize="15px" mb="2" color="#A9AAAC">
              Rankings are based on full-text similarity scores
            </Text>
            <ResultsList>
              {rtaViewStore.isSimilarRTAListLoading ? (
                <Box alignItems="center">
                  <Loader
                    type="ThreeDots"
                    color="#5e6d8c"
                    height={50}
                    width={50}
                  />
                </Box>
              ) : (
                rtaViewStore.similarRTAList.map((result, index) => (
                  <ResultContainer key={index}>
                    <Box mr="2">
                      <Text></Text>
                      <BtnText
                        fontSize="15px"
                        color="#3E474F"
                        fontWeight="bold"
                        onClick={() => viewSelectedRTA(result)}
                      >
                        {result.rtaName}
                      </BtnText>

                      <Box flexDirection="row" mt="2">
                        <Button
                          onClick={() => compareSelectedRTA(result)}
                          buttonColor="#5E6D8C"
                          mr="12px"
                          fontSize="10px"
                          size="small"
                        >
                          Compare
                        </Button>
                      </Box>
                    </Box>
                    <SimilarityScore>
                      <Text color="white" fontSize="13px" fontWeight="bold">
                        {result.score.toFixed(2)}
                      </Text>
                    </SimilarityScore>
                  </ResultContainer>
                ))
              )}
            </ResultsList>
          </SimilarDocuments>
        </SimilarContainer>
      ) : null}
    </SectionContainer>
  )
})

export default FindSimilar
