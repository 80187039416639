import React, { useState } from 'react'
import { Input, Button, Box, Text } from 'asimov'
import styled from 'styled-components'
import WTOLogo from 'images/wto-logo.svg'
import Spacer from 'components/Spacer/Spacer'
import UserService from 'services/UserService'
import { useHistory } from 'react-router-dom'

const Root = styled.div`
  display: flex;
  background-color: #f2f1f0;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const history = useHistory()

  const login = (event) => {
    const userSvc = new UserService()
    userSvc.login(email, password).then(
      (response) => {
        userSvc.saveAccessToken(response.data.access_token)
        history.push('/')
      },
      (err) => {
        setErrorMessage('Invalid credentials')
      }
    )
  }

  return (
    <Root>
      <Box width="500px" bg="white" p={5}>
        <Box alignItems="center">
          <img src={WTOLogo} alt="WTO Logo" width="205px" height="61px" />
        </Box>
        <Spacer mb={5} />
        {errorMessage && (
          <Box bg="#D94E41" p="2" mb="3">
            <Text fontWeight="bold" color="white" fontSize="14px">
              {errorMessage}
            </Text>
          </Box>
        )}
        <Text fontSize="18px" letterSpacing={2} fontWeight="bold" mb={2}>
          Your email
        </Text>

        <CustomInput
          id="email"
          type="email"
          bg="#F2F1F0"
          height="50px"
          width="100%"
          placeholder="test@wto.org"
          onChange={(e) => setEmail(e.target.value)}
          border="none"
          pl={3}
        />
        <Spacer mb={3} />
        <Text fontSize="18px" letterSpacing={2} fontWeight="bold" mb={2}>
          Your password
        </Text>

        <CustomInput
          type="password"
          border="none"
          bg="#F2F1F0"
          pl={3}
          height="50px"
          width="100%"
          placeholder="* * * * * *"
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              login()
            }
          }}
        />
        <Spacer mb={4} />
        <a href="/forgot-password">Forgot your password?</a>
        <Spacer mb={6} />
        <Box alignItems="center">
          <Button
            variant="primary"
            buttonColor="#5E6D8C"
            py={3}
            px={6}
            onClick={login}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Root>
  )
}
export default LoginPage
