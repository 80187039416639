import { makeAutoObservable } from 'mobx'
import update from 'immutability-helper'
import RTAService from 'services/RTAService'
import ActionsService from 'services/ActionsService'
import RTAMetadataService from 'services/RTAMetadataService'
import RTAIndexLinksService from 'services/RTAIndexLinksService'

export class RTAManagementStore {
  newRTAList = []
  ingestedRTAList = []
  currRTAID = null
  currRTAMetadata = null
  originalSections = []
  sections = []
  deletedSections = []
  indexLinks = []
  scrapedLinks = []
  openIndexLinkModal = false
  tab = 0

  // Loading variables
  isNewRTALoading = false
  isIngestedRTALoading = false
  isSectionsLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  fetchNewRTAList() {
    const rtaMetadataService = new RTAMetadataService()
    this.setIsNewRTALoading(true)
    rtaMetadataService.getNewRTAMetadata().then((response) => {
      const sorted = response.data.result.sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
      this.setNewRTAList(sorted)
      this.setIsNewRTALoading(false)
    })
  }

  fetchIngestedRTAList() {
    const rtaMetadataService = new RTAMetadataService()
    this.setIsIngestedRTALoading(true)
    rtaMetadataService.getIngestedRTAMetadata().then((response) => {
      const sorted = response.data.result.sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
      this.setIngestedRTAList(sorted)
      this.setIsIngestedRTALoading(false)
    })
  }

  fetchRTAMetadata(rtaID) {
    const rtaMetadataService = new RTAMetadataService()
    rtaMetadataService.getRTAMetadata(rtaID).then((response) => {
      this.setCurrRTAMetadata(response.data.result)
    })
  }

  fetchRTASections(rtaID, lang) {
    const rtaService = new RTAService()
    this.setIsSectionsLoading(true)
    rtaService.getSections(rtaID, lang).then((response) => {
      this.setRTASections(response.data.result)
      this.setOriginalRTASections(response.data.result)
      this.setIsSectionsLoading(false)
    })
  }

  fetchDeletedSections(rtaID) {
    const rtaService = new RTAService()
    this.setIsSectionsLoading(true)
    rtaService.getSections(rtaID, null, true).then((response) => {
      this.setDeletedSections(response.data.result)
      this.setIsSectionsLoading(false)
    })
  }

  fetchRTAIndexLinks() {
    const rtaIndexLinkService = new RTAIndexLinksService()
    rtaIndexLinkService
      .getRTAIndexLinks(this.currRTAID)
      .then((response) => this.setIndexLinks(response.data.result))
  }

  validateRTA(rtaID) {
    const rtaMetadataService = new RTAMetadataService()
    rtaMetadataService.validateRTA(rtaID).then((response) => {
      this.fetchRTAMetadata(rtaID)
    })
  }

  setNewRTAList(rtaList) {
    this.newRTAList = rtaList
  }

  setIngestedRTAList(rtaList) {
    this.ingestedRTAList = rtaList
  }

  setCurrRTAID(rtaID) {
    this.currRTAID = rtaID
  }

  setCurrRTAMetadata(rtaMetadata) {
    this.currRTAMetadata = rtaMetadata
  }

  setRTASections(sections) {
    this.sections = sections
  }

  setOriginalRTASections(sections) {
    this.originalSections = sections
  }

  setDeletedSections(sections) {
    this.deletedSections = sections
  }

  setOpenIndexLinkModal(open) {
    this.openIndexLinkModal = open
  }

  setIndexLinks(links) {
    this.indexLinks = links
  }

  setScrapedLinks(links) {
    this.scrapedLinks = links
  }

  setIsSectionsLoading(loading) {
    this.isSectionsLoading = loading
  }

  setIsNewRTALoading(loading) {
    this.isNewRTALoading = loading
  }

  setIsIngestedRTALoading(loading) {
    this.isIngestedRTALoading = loading
  }

  setTab(tab) {
    this.tab = tab
  }

  scrapeLinks() {
    const actionsService = new ActionsService()
    actionsService
      .scrapeLinks(
        this.indexLinks
          .filter((item) => item.link !== '' || item.link !== null)
          .map((item) => item.link)
      )
      .then((response) => {
        this.setScrapedLinks(response.data.content)
        this.populateWithScrapedLinks(response.data.content)
        this.setOpenIndexLinkModal(false)
      })
  }

  populateWithScrapedLinks(links) {
    links.forEach((link, index) => {
      this.setRTASections(
        update(this.sections, {
          $push: [
            {
              rta_id: this.currRTAID,
              section_name: link.text,
              link: link.doc_link,
              lang: 'EN', // Setting the default to EN
              rank: this.sections.length + index,
            },
          ],
        })
      )
    })
  }
}
