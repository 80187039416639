import React from 'react'
import styled from 'styled-components'

const Table = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
`

const Row = styled.tr`
  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;
  }
`

const ColumnHeader = styled.th`
  color: #403e3f;
  background-color: #dbdbdb;
  top: 0;
  padding: 16px;
  position: sticky;
  text-align: ${(props) => props.align || 'left'};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
`

const ColumnData = styled.td`
  padding: 16px;
  text-align: ${(props) => props.align || 'left'};
  ${(props) => (props.width ? `width: ${props.width};` : '')}
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: black;
  ${(props) =>
    props.disabled
      ? `
    &:hover {
      cursor: auto;
      color: black;
    }
  `
      : `
    color: #369DE8;
  `};
`

const UserTable = ({ users, handleEdit }) => {
  return (
    <Table>
      <tbody>
        <Row>
          <ColumnHeader width="15%">First Name</ColumnHeader>
          <ColumnHeader width="15%">Last Name</ColumnHeader>
          <ColumnHeader width="15%">Email</ColumnHeader>
          <ColumnHeader width="15%">Role</ColumnHeader>
          <ColumnHeader width="15%" align="center">
            Actions
          </ColumnHeader>
        </Row>
        {users.map((row, index) => (
          <Row key={row.id}>
            <ColumnData width="15%">{row.firstname}</ColumnData>
            <ColumnData width="15%">{row.lastname}</ColumnData>
            <ColumnData width="15%">{row.email}</ColumnData>
            <ColumnData width="15%">{row.role}</ColumnData>
            <ColumnData width="15%" align="center">
              <Button onClick={() => handleEdit(row.id)}>Edit</Button>
            </ColumnData>
          </Row>
        ))}
      </tbody>
    </Table>
  )
}

export default UserTable
