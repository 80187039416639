import { default as BaseTabs } from '@material-ui/core/Tabs'
import { withStyles } from '@material-ui/core/styles'

const Tabs = withStyles({
  root: {
    borderBottom: 'none',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(BaseTabs)

export default Tabs
