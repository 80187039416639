import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Button } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Slider as BaseSlider, ClickAwayListener } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 45px;
  padding: 20px;
  width: 301px;
  z-index: 1;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.6);
`
const Root = styled.div`
  display: flex;
  width: 100%;
`

const StyledButton = styled(Button)`
  color: #515151;
  background-color: #f2f1f0 !important;
  text-transform: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: auto;
  margin-right: 5px;
  height: 10px;
`

const Slider = withStyles({
  root: {
    color: '#5E6D8C',
  },
})(BaseSlider)

export const YearSlider = ({ yearRange, setYearRange }) => {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  }
  const handleClickAway = () => {
    setOpen(false)
  }

  const handleChange = (event, newRange) => {
    setYearRange(newRange)
  }

  return (
    <Root>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box flexDirection="row" width="100%">
          <StyledButton
            variant="tertiary"
            buttonColor={'#515151'}
            onClick={handleClick}
          >
            <Text pr={6}>
              {yearRange[0]} - {yearRange[1]}
            </Text>
            <Icon size="xs" icon={faChevronDown} color="#515151" />
          </StyledButton>
          {open ? (
            <PopperContainer>
              <Text id="range-slider" mb="0.35em" textAlign="center">
                {yearRange[0]} - {yearRange[1]}
              </Text>
              <Slider
                value={yearRange}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={1948}
                max={new Date().getFullYear()}
              />
            </PopperContainer>
          ) : null}
        </Box>
      </ClickAwayListener>
    </Root>
  )
}

export default YearSlider
