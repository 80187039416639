import React, { forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import update from 'immutability-helper'
import { observer } from 'mobx-react-lite'
import { Box, Button, Text, Input, Link } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExternalLinkAlt,
  faGripVertical,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { useStores } from 'hooks/useStores'
import DraggableRow from 'components/DraggableRow'

import { default as BaseModal } from '@material-ui/core/Modal'

const ItemTypes = {
  LINK: 'link',
}

const Modal = styled(BaseModal)`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Container = styled(Box)`
  width: 800px;
  height: 500px;
  padding: 30px;
  padding-top: 20px;
  background-color: white;
`

const Note = styled(Box)`
  background-color: #f2f1f0;
  color: #3e474f;
  font-size: 14px;
  padding: 8px 24px;
  margin: 16px 0;
`

const IndexList = styled.div`
  display: block;
  height: 100%;
  overflow: auto;
`

const Row = styled(Box)`
  flex-direction: row;
  cursor: pointer;
  opacity: ${(props) => props.opacity};
`

const RowEntry = styled(Box)`
  padding: 8px 0px;
  width: ${(props) => props.width};
  align-items: center;
  justify-content: center;
  flex-direction: row;
`

const Icon = styled(FontAwesomeIcon)`
  &:hover {
    cursor: ${(props) => props.cursor};
  }
`

const CloseIcon = styled(FontAwesomeIcon)`
  color: #6e7478;
  margin-left: auto;
  &:hover {
    cursor: pointer;
  }
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const IndexLinkRow = forwardRef((props, ref) => {
  const { indexLink, updateLink, index, deleteLink } = props
  return (
    <Row ref={ref} opacity={props.opacity}>
      <RowEntry width="5%">
        <Icon icon={faGripVertical} cursor="move" />
      </RowEntry>
      <RowEntry width="90%">
        <CustomInput
          mr="2"
          width="100%"
          value={indexLink.link}
          onChange={(e) => updateLink(e.target.value, index)}
        />
        <Link href={indexLink.link} target="_blank" color="#3E474F">
          <Icon icon={faExternalLinkAlt} />
        </Link>
      </RowEntry>
      <RowEntry width="5%">
        <Icon
          icon={faTrash}
          cursor="pointer"
          onClick={() => deleteLink(index)}
        />
      </RowEntry>
    </Row>
  )
})

const IndexLinksModal = observer(({ onClose }) => {
  const { rtaManagementStore } = useStores()

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (rtaManagementStore.indexLinks.length === 0) {
        return
      }
      const dragRow = rtaManagementStore.indexLinks[dragIndex]
      rtaManagementStore.setIndexLinks(
        update(rtaManagementStore.indexLinks, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      )
    },
    [rtaManagementStore]
  )

  const addIndexLink = () => {
    rtaManagementStore.setIndexLinks(
      update(rtaManagementStore.indexLinks, {
        $push: [
          {
            link: '',
            is_deleted: false,
            rta_id: rtaManagementStore.currRTAID,
          },
        ],
      })
    )
  }

  const updateLink = (value, index) => {
    rtaManagementStore.setIndexLinks(
      update(rtaManagementStore.indexLinks, {
        [index]: { link: { $set: value } },
      })
    )
  }

  const deleteLink = (index) => {
    rtaManagementStore.setIndexLinks(
      update(rtaManagementStore.indexLinks, {
        $splice: [[index, 1]],
      })
    )
  }

  return (
    <Modal onClose={onClose} open={rtaManagementStore.openIndexLinkModal}>
      <Container>
        <CloseIcon icon={faTimes} onClick={onClose} />
        <Text fontSize="18px">Index Links</Text>
        <Note>
          PDFs and Word documents are automatically scraped and supported. HTML
          pages are supported per website basis (e.g. Canada). Other formats
          must be manually added. The order of the index links on this page will
          determine the order of the RTA sections.
        </Note>
        <IndexList>
          {rtaManagementStore.indexLinks.map((indexLink, index) => (
            <DraggableRow
              key={index}
              index={index}
              id={indexLink.id}
              moveRow={moveRow}
              type={ItemTypes.LINK}
              canSort={true}
            >
              {(ref, opacity) => (
                <IndexLinkRow
                  indexLink={indexLink}
                  index={index}
                  ref={ref}
                  opacity={opacity}
                  updateLink={updateLink}
                  deleteLink={deleteLink}
                />
              )}
            </DraggableRow>
          ))}
        </IndexList>
        <Box flexDirection="row" alignContent="center" mt="16px">
          <Button
            buttonColor="#2A8C82"
            variant="tertiary"
            onClick={addIndexLink}
            alignSelf="flex-start"
            my="auto"
          >
            + Add section
          </Button>
          <Button
            variant="primary"
            buttonColor="#5E6D8C"
            size="medium"
            ml="auto"
            onClick={() => rtaManagementStore.scrapeLinks()}
          >
            Scrape links
          </Button>
        </Box>
      </Container>
    </Modal>
  )
})

export default IndexLinksModal
