import React, { useState, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Header from 'components/Header/Header'
import SearchForm from 'components/SearchForm/SearchForm'
import SearchResults from 'components/SearchResults/SearchResults'
import Sidebar from 'components/Sidebar/Sidebar'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import { useStores } from 'hooks/useStores'

const Root = styled.div`
  display: flex;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 300px);
  padding-top: 40px;
  padding-bottom: 40px;
`

export const SearchPage = observer(() => {
  const { userStore, projectStore } = useStores()
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    setLoading(true)
    userStore.getUser().then(() => {
      setLoading(false)
    })
  }, [userStore, projectStore])

  return (
    <div>
      <Header />
      <Root>
        <Sidebar />
        <Content>
          {!loading ? (
            <>
              <SearchForm />
              <SearchResults />
            </>
          ) : (
            <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
          )}
        </Content>
      </Root>
    </div>
  )
})

export default SearchPage
