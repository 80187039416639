import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Text } from 'asimov'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import RTAMetadataService from 'services/RTAMetadataService'
import RTAService from 'services/RTAService'
import Snackbar from 'components/Snackbar/Snackbar'
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import { Tabs, Tab, Popper, ClickAwayListener } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#5E6D8C',
  },
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    fontFamily: 'Source Sans Pro',
    minWidth: 80,
    '&:hover': {
      color: '#5E6D8C',
      opacity: 1,
    },
    '&$selected': {
      color: '#5E6D8C',
      fontWeight: 'bold',
    },
    '&:focus': {
      color: '#5E6D8C',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />)

const ButtonIcon = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & > svg:hover {
    color: black;
  }
`

const ButtonText = styled(Text)`
  color: #403e3f;
  &:hover {
    text-decoration: underline;
  }
`

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: -120px;
  min-width: 210px;
  padding: 20px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.5);
  z-index: 100;

  ${(props) => (props.anchor ? `margin-left: -10px;` : ``)}
`

const StyledButton = styled(Button)`
  text-transform: none;
  margin-right: 10px;
`

const PopperButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex-direction: row;
`

const StyledAnchor = styled.a`
  text-decoration: none;
  color: ${(props) => props.color};
`

const TabButtons = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const MissingLink = styled.div`
  margin-left: 20px;
`

const DownloadButton = ({ title, id, location, type, className, anchor }) => {
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [downloadLink, setDownloadLink] = useState([])
  const [snackbarMsg, setSnackbarMsg] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [languageLink, setLanguageLink] = useState([])
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    const rtaMetadataService = new RTAMetadataService()
    const rtaService = new RTAService()
    setIsFetching(true)
    if (type && type === 'section') {
      rtaService
        .downloadSection(id)
        .then(
          (response) => {
            setDownloadLink(response.data.result)
            setSnackbarMsg(`${title} has been downloaded`)
          },
          (error) => {
            setDownloadLink('')
            setSnackbarMsg(
              `There's an error in downloading ${title}, please try again!`
            )
          }
        )
        .finally(() => setIsFetching(false))
    } else {
      rtaMetadataService
        .downloadRTA(id)
        .then(
          (response) => {
            setDownloadLink(response.data.result)
            setLanguageLink({
              raw: response.data.result.pdf_en,
              txt: response.data.result.txt_en,
            })
            setSnackbarMsg(`${title} has been downloaded`)
          },
          (error) => {
            setDownloadLink('')
            setSnackbarMsg(
              `There's an error in downloading ${title}, please try again!`
            )
          }
        )
        .finally(() => setIsFetching(false))
    }
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleDownloadClick = () => {
    setAnchorEl(null)
    setOpenSnackBar(true)
  }

  const handleClickAway = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const popperId = open ? 'simple-popper' : undefined
  const [tab, setTab] = useState(0)
  const changeTabs = (tab) => {
    setTab(tab)
    if (tab === 0) {
      setLanguageLink({
        raw: downloadLink.pdf_en,
        txt: downloadLink.txt_en,
      })
    } else if (tab === 1) {
      setLanguageLink({
        raw: downloadLink.pdf_es,
        txt: downloadLink.txt_es,
      })
    } else {
      setLanguageLink({
        raw: downloadLink.pdf_fr,
        txt: downloadLink.txt_fr,
      })
    }
  }

  return (
    <div className={className}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <ButtonIcon onClick={(e) => handleClick(e)}>
            {location && location === 'rta' ? (
              <Box alignItems="center" display="flex" flexDirection="row">
                <FontAwesomeIcon icon={faDownload} color="#403E3F" />
                <ButtonText ml={2} fontSize="14px" fontWeight="bold">
                  Download full text
                </ButtonText>
              </Box>
            ) : (
              <FontAwesomeIcon icon={faDownload} color="#403E3F" />
            )}
          </ButtonIcon>
          <Popper id={popperId} open={open} anchorEl={anchorEl}>
            <PopperContainer anchor={anchor}>
              {isFetching ? (
                <Box alignItems="center">
                  Retrieving links...
                  <Loader
                    type="ThreeDots"
                    color="#5E6D8C"
                    height={50}
                    width={50}
                  />
                </Box>
              ) : (
                <>
                  <Text color="#403E3F" textAlign="center">
                    Download <Text fontWeight="bold">{title}</Text> in
                  </Text>
                  {type === 'section' ? (
                    <PopperButtons>
                      <StyledButton
                        buttonColor="#5E6D8C"
                        onClick={() => {
                          handleDownloadClick()
                        }}
                      >
                        <StyledAnchor
                          href={downloadLink.raw}
                          color="inherit"
                          download
                        >
                          Source
                        </StyledAnchor>
                      </StyledButton>
                      <StyledButton
                        buttonColor="#5E6D8C"
                        onClick={() => {
                          handleDownloadClick()
                        }}
                      >
                        <StyledAnchor
                          href={downloadLink.txt}
                          color="inherit"
                          download
                        >
                          TXT
                        </StyledAnchor>
                      </StyledButton>
                    </PopperButtons>
                  ) : (
                    <div>
                      <AntTabs
                        value={tab}
                        onChange={(e, value) => changeTabs(value)}
                      >
                        <AntTab label="EN" />
                        <AntTab label="ES" />
                        <AntTab label="FR" />
                      </AntTabs>
                      <TabButtons>
                        {languageLink.raw ? (
                          <StyledButton
                            buttonColor="#5E6D8C"
                            onClick={() => {
                              handleDownloadClick()
                            }}
                          >
                            <StyledAnchor
                              href={languageLink.raw}
                              color="inherit"
                              download
                            >
                              {tab === 0
                                ? `English Source`
                                : tab === 1
                                ? `Spanish Source`
                                : `French Source`}
                            </StyledAnchor>
                          </StyledButton>
                        ) : (
                          <MissingLink>No Source Link</MissingLink>
                        )}
                        {languageLink.txt ? (
                          <StyledButton
                            buttonColor="#5E6D8C"
                            onClick={() => {
                              handleDownloadClick()
                            }}
                          >
                            <StyledAnchor
                              href={languageLink.txt}
                              color="inherit"
                              download
                            >
                              {tab === 0
                                ? `English TXT`
                                : tab === 1
                                ? `Spanish TXT`
                                : `French TXT`}
                            </StyledAnchor>
                          </StyledButton>
                        ) : (
                          <MissingLink>No TXT Link</MissingLink>
                        )}
                      </TabButtons>
                    </div>
                  )}
                </>
              )}
            </PopperContainer>
          </Popper>
        </div>
      </ClickAwayListener>
      <Snackbar
        open={openSnackBar}
        onClose={handleCloseSnackbar}
        message={snackbarMsg}
      />
    </div>
  )
}

export default DownloadButton
