import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Input } from 'asimov'

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`
const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
`

const PageText = styled(Text)`
  font-size: 15px;
  color: #3e474f;
`
const SubtitleText = styled(Text)`
  margin-top: 5px;
  font-size: 13px;
  color: grey;
`

const Range = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

export const PageRange = ({
  totalPages,
  startPage,
  setStartPage,
  endPage,
  setEndPage,
  hasReset,
  changePage,
}) => {
  const [tempStart, setTempStart] = useState(startPage)
  const [tempEnd, setTempEnd] = useState(endPage)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTempEnd(endPage)
  }, [totalPages, endPage])

  useEffect(() => {
    setTempEnd(endPage)
    setTempStart(startPage)
  }, [startPage, endPage])

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (tempEnd - tempStart <= 9 && tempEnd - tempStart > 0) {
        setStartPage(tempStart)
        setEndPage(tempEnd)
        changePage(tempStart, tempEnd)
      } else {
        setError(true)
      }
    }
  }

  return (
    <Root>
      <SliderContainer>
        {error && (
          <Text color="red" mb={2}>
            Can only compare a maximum of 10 pages
          </Text>
        )}
        <PageText>
          <Range>
            Page
            <CustomInput
              min={1}
              max={totalPages}
              onChange={(e) => {
                setTempStart(parseInt(e.target.value))
                setError(false)
              }}
              onKeyDown={onKeyDown}
              value={tempStart}
              type={'number'}
              width={'60px'}
              height={'25px'}
              ml={2}
              mr={2}
              disabled={totalPages === 1}
            />
            to
            <CustomInput
              min={tempStart}
              max={10}
              onChange={(e) => {
                setTempEnd(parseInt(e.target.value))
                setError(false)
              }}
              onKeyDown={onKeyDown}
              value={tempEnd}
              type={'number'}
              width={'60px'}
              height={'25px'}
              ml={2}
              mr={2}
              disabled={totalPages === 1}
            />
            / {totalPages}
          </Range>
        </PageText>
        <SubtitleText>
          showing page {startPage} to {endPage}
        </SubtitleText>
      </SliderContainer>
    </Root>
  )
}

export default PageRange
