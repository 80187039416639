import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Text, Box } from 'asimov'
import Select from 'react-select'
import PageRange from 'components/PageRange/PageRange'
import MetaDataBox from 'components/MetaDataBox'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import KeywordResultsList from 'components/KeywordResultsList'
import { TabPanel } from 'components/Tabs'
import { useStores } from 'hooks/useStores'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Loader from 'react-loader-spinner'
import constants from '../../utils/constants'

import qs from 'qs'

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: 'black',
    width: width,
    backgroundColor: 'white',
    marginRight: '10px',
    borderColor: 'black',
    display: 'flex',
  }),
  menu: (provided, { selectProps: { width } }) => ({
    ...provided,
    width: width,
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}
const Spinner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Document = styled.div`
  display: flex;
  flex-direction: column;
  width: 48%;
  :focus {
    background-color: red;
  }
`

const TitleHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const DocumentContent = styled.div`
  overflow-y: auto;
  height: 55vh;
`

const Paper = styled.div`
  display: block;
  overflow: auto;
  background-color: white;
  height: -webkit-fill-available;
  padding: 20px;

  #highlight-1,
  #highlight-2 {
    background-color: yellow;
  }
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;

  & > .rta-select {
    width: 50%;
    margin-right: 8px;
  }
  & > .section-select {
    width: 40%;
    margin-left: auto;
  }
`

const KeywordResultsContainer = styled.div`
  display: block;
  height: 250px;
  border-bottom: solid 1px black;
  padding: 8px;
  overflow: auto;
`

export const CompareViewer = observer(
  ({
    id,
    currTab,
    title,
    onRTASelect,
    onSectionSelect,
    sections,
    text,
    pageCount,
    higlightedText,
    metadata,
    titleIndex,
    rtaId,
    rtaNav,
    keyword,
    loading,
    textLoading,
  }) => {
    const history = useHistory()
    const location = useLocation()
    const [startPage, setStartPage] = useState(1)
    const [endPage, setEndPage] = useState(1)
    const [hasChangedSection, setHasChangedSection] = useState(false)
    const { rtaCompareStore, rtaViewStore } = useStores()

    let selected, setSelected
    if (id === 1) {
      selected = rtaCompareStore.rta1SelectedSection
      setSelected = (value) => rtaCompareStore.setRTA1SelectedSection(value)
    } else {
      selected = rtaCompareStore.rta2SelectedSection
      setSelected = (value) => rtaCompareStore.setRTA2SelectedSection(value)
    }

    function createMarkup(text) {
      return { __html: text }
    }

    useEffect(() => {
      pageCount > 10 ? setEndPage(10) : setEndPage(pageCount)
    }, [pageCount])

    useEffect(() => {
      setStartPage(1)
    }, [rtaNav.sectionID])

    useEffect(() => {
      rtaCompareStore.clearSectionIDs(id)
    }, [rtaId, id, rtaCompareStore])

    const onChange = (startPage, endPage) => {
      if (id === 1) {
        rtaCompareStore.fetchPageRange(
          'RTA_ID_1',
          [startPage, endPage],
          rtaNav.sectionID
        )
      } else {
        rtaCompareStore.fetchPageRange(
          'RTA_ID_2',
          [startPage, endPage],
          rtaNav.sectionID
        )
      }
    }

    const selectRTA = (rtaId) => {
      const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
      const rtaID1 = parseInt(queryParams.rta_id_1)
      const rtaID2 = parseInt(queryParams.rta_id_2)
      rtaCompareStore.setLang(constants.languageOptions[0])
      rtaCompareStore.clearKeywordMatches()
      onRTASelect()
      if (id === 1) {
        history.push(`/compare?rta_id_1=${rtaId}&rta_id_2=${rtaID2}`)
        rtaCompareStore.clearRTAText1()
        rtaCompareStore.setRTAIDs(rtaId, rtaID2)
      } else {
        history.push(`/compare?rta_id_1=${rtaID1}&rta_id_2=${rtaId}`)
        rtaCompareStore.clearRTAText2()
        rtaCompareStore.setRTAIDs(rtaID1, rtaId)
      }
    }

    const selectKeyword = (keyword, id) => {
      const { page_num, start, end } = keyword
      rtaCompareStore.fetchHighlightedRTAText(page_num, start, end, id)
      setStartPage(page_num)
      setEndPage(page_num)
    }

    return (
      <Document>
        {currTab === 2 ? (
          id === 1 ? (
            <Box height="38px" justifyContent="center">
              <Text color="#3E474F" fontWeight="bold" fontSize="25px">
                Metadata Comparison
              </Text>
            </Box>
          ) : (
            <Box height="36px"> </Box>
          )
        ) : null}
        <TitleHeader>
          {!loading ? (
            <TitleContainer>
              <Select
                width="100%"
                placeholder="Select an rta"
                styles={customStyles}
                defaultValue={rtaViewStore.rtaOptions[titleIndex]}
                options={rtaViewStore.rtaOptions}
                onChange={(selected) => {
                  selectRTA(selected.value)
                }}
                className="rta-select"
                classNamePrefix="select"
              />
              <DownloadButton title={title} id={rtaId} anchor="right" />
              <Select
                onChange={(option) => {
                  onSectionSelect(option)
                  setSelected(option)
                  setHasChangedSection(true)
                }}
                width="100%"
                value={selected}
                options={sections}
                placeholder={'Jump to section'}
                styles={customStyles}
                className="section-select"
                classNamePrefix="select"
              />
            </TitleContainer>
          ) : (
            <Box width="100%" alignItems="center" height="60px">
              <Loader type="ThreeDots" color="#5E6D8C" height={56} width={56} />
            </Box>
          )}
        </TitleHeader>
        {currTab > 0 && (
          <div>
            <TabPanel value={currTab} index={2} my="3">
              <MetaDataBox metadata={metadata} />
            </TabPanel>
            <TabPanel value={currTab} index={1} my="3">
              <KeywordResultsContainer>
                <KeywordResultsList
                  results={
                    id === 1
                      ? rtaCompareStore.keywordMatches1
                      : rtaCompareStore.keywordMatches2
                  }
                  handleSelect={(keyword) => selectKeyword(keyword, id)}
                  keyword={keyword}
                  isLoading={null}
                />
              </KeywordResultsContainer>
            </TabPanel>
          </div>
        )}
        <Box height="70px">
          {rtaNav.sectionID && text ? (
            <PageRange
              totalPages={pageCount}
              startPage={startPage}
              setStartPage={setStartPage}
              setEndPage={setEndPage}
              endPage={endPage}
              hasChangedSection={hasChangedSection}
              setHasChangedSection={setHasChangedSection}
              changePage={(start, end) => {
                onChange(start, end)
              }}
              visible={rtaNav.sectionID}
            />
          ) : null}
        </Box>
        <DocumentContent>
          {textLoading ? (
            <Spinner>
              <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
            </Spinner>
          ) : (
            <Paper>
              {!textLoading && !text
                ? 'RTA is not available in this language'
                : null}
              {higlightedText ? (
                <Text dangerouslySetInnerHTML={createMarkup(higlightedText)} />
              ) : (
                <div dangerouslySetInnerHTML={createMarkup(text)} />
              )}
            </Paper>
          )}
        </DocumentContent>
      </Document>
    )
  }
)
export default CompareViewer
