import React, { useState } from 'react'
import { Input, Button, Box } from 'asimov'
import styled from 'styled-components'
import WTOLogo from 'images/wto-logo.svg'
import Spacer from 'components/Spacer/Spacer'
import UserService from 'services/UserService'
import Loader from 'react-loader-spinner'

const Root = styled.div`
  display: flex;
  background-color: #f2f1f0;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const ErrorMessage = styled.p`
  color: red;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

function validateEmail(email) {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true
  }
  return false
}

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('')
  const [UIState, setUIState] = useState('EMAIL_INPUT')
  const [errorMessage, setErrorMessage] = useState('')

  const forgotPassword = (event) => {
    event.preventDefault()
    setErrorMessage('')
    if (!validateEmail(email)) {
      setErrorMessage('Email you entered is invalid')
      return
    }

    setUIState('LOADING')
    const userService = new UserService()
    userService.forgotPassword({ email }).then(
      () => {
        setUIState('SUCCESS_MESSAGE')
      },
      (err) => {
        setUIState('SUCCESS_MESSAGE')
      }
    )
  }

  let currentUI = null

  if (UIState === 'EMAIL_INPUT') {
    currentUI = (
      <React.Fragment>
        <h2>Forgot your password?</h2>
        <label htmlFor="email">Email Address</label>
        <CustomInput
          id="email"
          type="email"
          width="100%"
          height="50px"
          bg="#F2F1F0"
          border="none"
          pl={3}
          mt="2"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Spacer mb={6} />
        <Button
          onClick={forgotPassword}
          buttonColor="#5E6D8C"
          variant="primary"
        >
          Send me instructions
        </Button>
        <Spacer mb={2} />
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </React.Fragment>
    )
  } else if (UIState === 'LOADING') {
    currentUI = (
      <Box alignItems="center">
        <Loader type="ThreeDots" color="#5E6D8C" height={80} width={80} />
      </Box>
    )
  } else if (UIState === 'SUCCESS_MESSAGE') {
    currentUI = (
      <React.Fragment>
        <h2>Instructions sent!</h2>
        <p>Instructions to reset your password have been sent to {email}</p>
        <Spacer mb={2} />
        <p>
          You’ll receive this email within 5 minutes. Be sure to check your spam
          folder, too.
        </p>
      </React.Fragment>
    )
  }

  return (
    <Root>
      <Box width="500px" bg="white" p={5}>
        <Box alignItems="center">
          <img src={WTOLogo} alt="WTO Logo" width="205px" height="61px" />
        </Box>
        {currentUI}
      </Box>
    </Root>
  )
}

export default ForgotPasswordPage
