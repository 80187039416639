import { makeAutoObservable } from 'mobx'
import UserService from 'services/UserService'

export class UserStore {
  user = {}
  users = []

  constructor() {
    makeAutoObservable(this)
  }

  async getUser() {
    const userService = new UserService()
    const response = await userService.me()
    this.setUser(response.data)
  }

  setUser(userData) {
    this.user = { ...userData }
  }

  isSuperUser() {
    return this.user.role === 'SUPERUSER'
  }
}
