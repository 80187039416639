import axios from 'axios'

class ProjectService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }
  createProject(title, userId) {
    const projectEndpoint = `${this.baseURL}/project/create`
    const payload = {
      title,
      user_id: userId,
    }
    return axios.post(projectEndpoint, payload, this.baseConfig)
  }
  getAllProjects(userId) {
    const projectEndpoint = `${this.baseURL}/project?user_id=${userId}`
    return axios.get(projectEndpoint, this.baseConfig)
  }
  getProject(id) {
    const projectEndpoint = `${this.baseURL}/project/${id}`
    return axios.get(projectEndpoint, this.baseConfig)
  }
  deleteProject(id) {
    const projectEndpoint = `${this.baseURL}/project/${id}`
    return axios.delete(projectEndpoint, this.baseConfig)
  }
  editProjectTitle(title, id) {
    const projectEndpoint = `${this.baseURL}/project/${id}`
    const payload = {
      title,
    }
    return axios.patch(projectEndpoint, payload, this.baseConfig)
  }

  addRTAToProject(projectId, rtaId) {
    const projectEndpoint = `${this.baseURL}/project/${projectId}/rta`
    const payload = {
      rta_id: rtaId,
    }
    return axios.post(projectEndpoint, payload, this.baseConfig)
  }

  addFilterToProject(projectId, filterList) {
    const projectEndpoint = `${this.baseURL}/project/${projectId}/filter`
    const payload = {
      filter_list: filterList,
    }
    return axios.post(projectEndpoint, payload, this.baseConfig)
  }
}

export default ProjectService
