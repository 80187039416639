import { makeAutoObservable, runInAction } from 'mobx'
import ProjectService from 'services/ProjectService'
import RTAService from 'services/RTAService'

export class ProjectStore {
  projects = []
  projectDetails = {}
  rtaIdList = []
  rtaListDetails = null
  filters = []
  isFetchingProjectDetails = false
  isFetchingRTADetails = false
  userID = 0
  filteredProjects = []

  constructor() {
    makeAutoObservable(this)
  }

  async getProjects(userID) {
    const projectService = new ProjectService()
    const response = await projectService.getAllProjects(userID)
    runInAction(() => {
      this.userID = userID
      this.setProjects(response.data.projects)
    })
  }

  async getProject(id) {
    const projectService = new ProjectService()
    this.isFetchingProjectDetails = true
    await projectService.getProject(id).then((response) => {
      this.setProjectDetails(response.data)
      this.setRTAIdList(
        Object.keys(response.data.rta_list).map((key) => {
          return response.data.rta_list[key]
        })
      )
      this.setFilters(response.data.filter_list)
      this.isFetchingProjectDetails = false
    })
  }

  createProject(title, userID) {
    const projectService = new ProjectService()
    projectService.createProject(title, userID).then(() => {
      this.getProjects(this.userID)
    })
  }

  deleteProject(id) {
    const projectService = new ProjectService()
    projectService.deleteProject(id).then(() => {
      this.getProjects(this.userID)
    })
  }

  editProjectTitle(title, id) {
    const projectService = new ProjectService()
    projectService.editProjectTitle(title, id).then((response) => {
      this.getProjects(this.userID)
      this.getProject(id)
    })
  }

  filterProjects(keyword) {
    keyword === ''
      ? (this.filteredProjects = this.projects)
      : (this.filteredProjects = this.projects.filter((project) =>
          project.title.toLowerCase().includes(keyword.toLowerCase())
        ))
  }

  addRTAToProject(projectId, rtaId) {
    const projectService = new ProjectService()
    projectService.addRTAToProject(projectId, rtaId).then((response) => {})
  }

  async getRTADetails() {
    const rtaService = new RTAService()
    this.isFetchingRTADetails = true

    const rtaPromises = this.rtaIdList.map((rtaId) =>
      rtaService.getRTAInfo(rtaId)
    )

    const rtas = (await Promise.all(rtaPromises)).map((response) => {
      this.isFetchingRTADetails = false
      return response.data
    })

    this.rtaListDetails = rtas
  }

  addFilterToProject(projectId, filterList) {
    const projectService = new ProjectService()
    projectService
      .addFilterToProject(projectId, filterList)
      .then((response) => {})
  }

  setRTAIdList(rtaIdList) {
    this.rtaIdList = rtaIdList
  }

  setProjects(projects) {
    this.projects = projects
  }

  setProjectDetails(details) {
    this.projectDetails = details
  }

  setFilters(filterList) {
    this.filters = filterList
  }

  clearRTAs() {
    this.rtaIdList = []
    this.rtaListDetails = null
  }
}
