import React from 'react'
import styled from 'styled-components'
import { Box, Text } from 'asimov'
import Loader from 'react-loader-spinner'

const Container = styled.div`
  display: block;
`

const KeywordResult = styled(Box)`
  padding: 14px 8px;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  word-break: break-word;

  &:hover {
    cursor: pointer;
    background-color: #dbdbdb;
    border-radius: 8px;
  }

  &:not(:last-child) {
    border-bottom: solid 1px #dbdbdb;
  }

  &::before,
  &::after {
    content: '...';
  }

  p {
    display: inline-block;
    margin: 0;
  }
`

const KeywordResultsList = ({ results, isLoading, handleSelect, keyword }) => {
  return (
    <Container>
      {!isLoading ? (
        <Text fontSize="12px" mb="2" color="#3E474F">
          Found {results.length} keyword results
        </Text>
      ) : null}
      {isLoading ? (
        <Box alignItems="center" width="100%">
          <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
        </Box>
      ) : null}
      {results.length > 0
        ? results.map((result, index) => (
            <KeywordResult
              key={index}
              onClick={() => handleSelect(result)}
              dangerouslySetInnerHTML={{ __html: result.match_boundary }}
            />
          ))
        : null}
    </Container>
  )
}

export default KeywordResultsList
