import React from 'react'
import { Box } from 'asimov'

const TabPanel = ({ children, value, index, ...rest }) => {
  return (
    <Box role="tabpanel" hidden={value !== index} {...rest}>
      {value === index && children}
    </Box>
  )
}

export default TabPanel
