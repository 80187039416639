import React from 'react'
import styled from 'styled-components'
import { Box, Text, Link } from 'asimov'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/useStores'
import constants from 'utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Loader from 'react-loader-spinner'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ScrollableBox = styled.div`
  display: block;
  overflow: auto;
  height: 100%;
`

const Header = styled.div`
  background-color: #f2f1f0;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-align: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 900;
  text-align: ${(props) => props.align || 'left'};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  opacity: ${(props) => props.opacity};

  &:hover {
    cursor: ${(props) => (props.canSort ? 'move' : 'auto')};
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dbdbdb;
  }
`

const RowEntry = styled(Box)`
  padding: 12px 0px;
  flex-direction: row;
  width: ${(props) => props.width};
  align-items: ${(props) => props.align || 'flex-start'};
  ${(props) => (props.wordBreak ? `word-break: ${props.wordBreak};` : '')}
`

const Button = styled.button`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: #369de8;
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.disabled
      ? `
    color: #DBDBDB;
    &:hover {
      cursor: auto;
    }
  `
      : ''};
`

const Icon = styled(FontAwesomeIcon)`
  display: block;
  &:hover {
    cursor: pointer;
  }
`

const SectionRow = ({ name, status, link, handleEdit }) => {
  return (
    <Row>
      <RowEntry width="30%" align="center">
        <Text px="32px" textAlign="left">
          {name}
        </Text>
      </RowEntry>
      <RowEntry width="45%" wordBreak="break-all" align="center">
        <Text px="32px" textAlign="left">
          {link}
        </Text>
        <Link href={link} target="_blank" color="#3E474F" ml="auto">
          <Icon icon={faExternalLinkAlt} />
        </Link>
      </RowEntry>
      <RowEntry width="15%" align="center">
        <Text
          px="32px"
          textAlign="left"
          color={constants.SECTION_STATUSES[status].color}
        >
          {constants.SECTION_STATUSES[status].label}
        </Text>
      </RowEntry>
      <RowEntry width="10%" align="center">
        <Button
          onClick={handleEdit}
          disabled={status !== constants.SECTION_STATUSES.FAILED_DOWNLOAD.id}
        >
          Edit
        </Button>
      </RowEntry>
    </Row>
  )
}

const StatusTable = observer(({ langCode, handleEdit }) => {
  const { rtaManagementStore } = useStores()

  const sectionsLength = rtaManagementStore.sections.filter(
    (section) => section.lang === langCode
  ).length

  return (
    <Container>
      <ScrollableBox>
        <Header>
          <Box py="8px" px="32px" width="30%" alignSelf="flex-start">
            Section Name
          </Box>
          <Box py="8px" px="32px" width="45%" alignSelf="flex-start">
            Link
          </Box>
          <Box py="8px" px="32px" width="15%" alignSelf="flex-start">
            Status
          </Box>
          <Box py="8px" width="10%">
            <Text textAlign="center">Actions</Text>
          </Box>
        </Header>
        {rtaManagementStore.isSectionsLoading ? (
          <Box width="100%" alignItems="center">
            <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
          </Box>
        ) : null}
        {!rtaManagementStore.isSectionsLoading && sectionsLength === 0 ? (
          <Box alignItems="center" p="16px">
            <Text>No sections found for this language</Text>
          </Box>
        ) : null}
        {!rtaManagementStore.isSectionsLoading &&
          rtaManagementStore.sections.map((section, index) => {
            if (section.lang === langCode) {
              return (
                <SectionRow
                  name={section.section_name}
                  link={section.link}
                  index={index}
                  status={section.status}
                  handleEdit={() => handleEdit(langCode, section.section_id)}
                />
              )
            } else {
              return null
            }
          })}
      </ScrollableBox>
    </Container>
  )
})

export default StatusTable
