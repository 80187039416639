import axios from 'axios'

class RTAIndexLinksService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }

  getRTAIndexLinks(rtaID, isDeleted = false) {
    const indexLinkEndpoint = `${this.baseURL}/rtaindexlinks?rta_id=${rtaID}&is_deleted=${isDeleted}`
    return axios.get(indexLinkEndpoint, this.baseConfig)
  }
}

export default RTAIndexLinksService
