import React, { useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { Box, Text, Input } from 'asimov'
import ProjectCard from '../components/ProjectCard/ProjectCard'
import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import Loader from 'react-loader-spinner'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  display: flex;
  flex-direction: column;
`
const ContentContainer = styled.div`
  display: flex;
`
const Content = styled.div`
  width: 100%;
  padding: 30px 50px 50px 50px;
  background-color: #f2f1f0;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
`
const Rows = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`
const TitleHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const Icon = styled(FontAwesomeIcon)`
  margin-left: 8px;
  &:hover {
    cursor: pointer;
  }
`

export const ProjectsPage = observer(() => {
  const { projectStore, userStore } = useStores()
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)

  const hasResults = projectStore.projects.length && !loading

  useLayoutEffect(() => {
    async function fetchData() {
      setLoading(true)
      await userStore.getUser()
      await projectStore.getProjects(userStore.user.id).then(() => {
        setLoading(false)
      })
    }
    fetchData()
  }, [projectStore, userStore])

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      projectStore.filterProjects(keyword)
    }
  }

  const clearProjectSearch = () => {
    setKeyword('')
    projectStore.filterProjects('')
  }

  return (
    <Root>
      <Header type={'projectFolder'} />
      <ContentContainer>
        <Sidebar />
        <Content>
          <TitleHeader>
            <Text fontSize="28px" fontWeight="bold" color="#403E3F">
              ALL PROJECTS
            </Text>
            <InputContainer>
              <CustomInput
                width="300px"
                backgroundColor="white"
                onKeyDown={onKeyDown}
                value={keyword}
                onChange={(e) => {
                  setKeyword(e.target.value)
                  e.target.value === '' && projectStore.filterProjects('')
                }}
                before={<FontAwesomeIcon icon={faSearch} />}
                after={<Icon icon={faTimes} onClick={clearProjectSearch} />}
                placeholder="Search for a project..."
              />
            </InputContainer>
          </TitleHeader>
          <Rows>
            {loading ? (
              <Box mt="2" height="190px" alignItems="center" width="100%">
                <Loader
                  type="ThreeDots"
                  color="#5e6d8c"
                  height={50}
                  width={50}
                />
              </Box>
            ) : null}
            {(projectStore.projects.length === 0) & !loading ? (
              <Text ml="12px" mt="16px">
                No projects found
              </Text>
            ) : null}
            {hasResults && keyword === ''
              ? projectStore.projects.map((project, index) => (
                  <ProjectCard
                    key={index}
                    backgroundColor="white"
                    projectDetails={project}
                  />
                ))
              : null}
            {hasResults && keyword !== ''
              ? projectStore.filteredProjects.map((project, index) => (
                  <ProjectCard
                    key={index}
                    backgroundColor="white"
                    projectDetails={project}
                  />
                ))
              : null}
          </Rows>
        </Content>
      </ContentContainer>
    </Root>
  )
})

export default ProjectsPage
