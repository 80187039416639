import axios from 'axios'

class RTAService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }

  search({ query, filters, page }) {
    this.cleanEmptyFilters(filters)

    const searchEndpoint = `${this.baseURL}/rtas/search`
    const payload = {
      query,
      filters,
      page,
    }
    return axios.post(searchEndpoint, payload, this.baseConfig)
  }

  exportSearch({ query, filters, page }) {
    this.cleanEmptyFilters(filters)

    const searchEndpoint = `${this.baseURL}/rtas/export_search`
    const payload = {
      query,
      filters,
      page,
    }
    return axios.post(searchEndpoint, payload, this.baseConfig)
  }

  cleanEmptyFilters(filters) {
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key]) && filters[key].length === 0) {
        delete filters[key]
      }
    })
  }

  getRTAInfo(rtaID) {
    const rtaEndpoint = `${this.baseURL}/rtametadata/${rtaID}`
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  getSections(rtaID, lang, isDeleted) {
    let rtaEndpoint = `${this.baseURL}/rtasections?rta_id=${rtaID}`
    if (lang) {
      rtaEndpoint += `&lang=${lang}`
    }
    if (isDeleted) {
      rtaEndpoint += `&is_deleted=${isDeleted}`
    }
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  getSectionInfo(rtaID, sectionID) {
    const rtaEndpoint = `${this.baseURL}/rtasections/${sectionID}?rta_id=${rtaID}`
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  getPageCount(rtaID, sectionID) {
    const rtaEndpoint = `${this.baseURL}/rtas/${rtaID}/sections/${sectionID}`
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  createSection(rtaID, sectionName, link, lang, rank) {
    const sectionEndpoint = `${this.baseURL}/rtasections`
    const payload = {
      rta_id: rtaID,
      section_name: sectionName,
      link,
      lang,
      rank,
    }
    return axios.post(sectionEndpoint, payload, this.baseConfig)
  }

  updateSection(sectionId, sectionName, link, lang, rank) {
    const sectionEndpoint = `${this.baseURL}/rtasections/${sectionId}`
    const payload = {
      section_name: sectionName,
      link,
      lang,
      rank,
    }
    return axios.put(sectionEndpoint, payload, this.baseConfig)
  }

  deleteSection(sectionId) {
    const sectionEndpoint = `${this.baseURL}/rtasections/${sectionId}`
    return axios.delete(sectionEndpoint, this.baseConfig)
  }

  restoreSection(sectionId) {
    const sectionEndpoint = `${this.baseURL}/rtasections/${sectionId}/restore`
    const payload = {
      section_id: sectionId,
    }
    return axios.post(sectionEndpoint, payload, this.baseConfig)
  }

  downloadSection(sectionId) {
    const sectionEndpoint = `${this.baseURL}/rtasections/${sectionId}/download`
    return axios.get(sectionEndpoint, this.baseConfig)
  }

  getAllRTAs() {
    const rtaEndpoint = `${this.baseURL}/rtametadata/ingested`
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  getRTAText(rtaID, sectionID, pageNum) {
    const rtaEndpoint = `${this.baseURL}/rtas/${rtaID}/sections/${sectionID}/text?page_num=${pageNum}`
    return axios.get(rtaEndpoint, this.baseConfig)
  }

  compareSectionRTAs(rta1, rta2) {
    const compareEndpoint = `${this.baseURL}/rtas/compare`
    const payload = {
      rta_1: {
        rta_id: rta1.rtaID,
        section_id: rta1.sectionID,
      },
      rta_2: {
        rta_id: rta2.rtaID,
        section_id: rta2.sectionID,
      },
    }
    return axios.post(compareEndpoint, payload, this.baseConfig)
  }
  compareFullRTAs(rta1, rta2) {
    const compareEndpoint = `${this.baseURL}/rtas/compare`
    const payload = {
      rta_1: {
        rta_id: rta1.rtaID,
      },
      rta_2: {
        rta_id: rta2.rtaID,
      },
    }
    return axios.post(compareEndpoint, payload, this.baseConfig)
  }

  diffRTAs(rtaText1, rtaText2) {
    const highlightEndpoint = `${this.baseURL}/rtas/highlight`
    const payload = {
      text_1: rtaText1,
      text_2: rtaText2,
    }
    return axios.post(highlightEndpoint, payload, this.baseConfig)
  }

  getSimilarRTAs(rtaID, sectionID, size = 10) {
    const recommendEndpoint = `${this.baseURL}/rtas/recommend`
    const payload = {
      rta_id: rtaID,
      section_id: sectionID,
      top_n: size,
    }
    return axios.post(recommendEndpoint, payload, this.baseConfig)
  }

  navigateKeywords(keyword, sectionID, searchFields, boundary) {
    const navigateEndpoint = `${this.baseURL}/rtas/navigate`
    const payload = {
      keyword,
      section_id: sectionID,
      search_fields: searchFields,
      boundary,
    }
    return axios.post(navigateEndpoint, payload, this.baseConfig)
  }
}

export default RTAService
