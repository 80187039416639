import React, { useState } from 'react'
import styled from 'styled-components'
import { Text, Button, Box } from 'asimov'
import { useHistory } from 'react-router-dom'
import { useStores } from 'hooks/useStores'
import DownloadButton from 'components/DownloadButton/DownloadButton'
import Snackbar from 'components/Snackbar/Snackbar'
import RTAService from 'services/RTAService'
import constants from '../../utils/constants'

const Root = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro';
  cursor: pointer;
`
const Result = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 40px 20px 40px;
  margin-right: 20px;
`

const TitleButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const BtnText = styled(Text)`
  display: block;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const ProjectRTADocument = ({ rta }) => {
  const history = useHistory()
  const { rtaViewStore, rtaCompareStore } = useStores()
  const [snackbarMessage, setSnackBarMessage] = useState(
    `${rta.result.name} has been added into compare `
  )
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }
  const handleCompare = () => {
    const rtaDoc = {
      title: rta.result.name,
      id: rta.result.rta_id,
    }
    if (Object.keys(rtaCompareStore.compareBucket1).length === 0) {
      rtaCompareStore.setCompareBucket1(rtaDoc)
      rtaCompareStore.setIsCompareBucketOpen(true)
    } else if (Object.keys(rtaCompareStore.compareBucket2).length === 0) {
      rtaCompareStore.setCompareBucket2(rtaDoc)
      rtaCompareStore.setIsCompareBucketOpen(true)
    } else {
      setSnackBarMessage('Compare bucket full!')
    }
    setOpenSnackBar(true)
  }

  const handleClick = async () => {
    const rtaService = new RTAService()
    const firstSection = await rtaService
      .getSections(rta.result.rta_id)
      .then((response) => response.data.result[0].section_id)
    rtaViewStore.setCurrLang(constants.languageOptions[0])
    const newLoc = `/rta-view/${rta.result.rta_id}?section_id=${firstSection}&page_num=1`
    rtaViewStore.setRTALink(newLoc)
    history.push(newLoc)
  }

  return (
    <div>
      <Root>
        <Result>
          <TitleButtons>
            <BtnText
              fontSize="18px"
              fontWeight="bold"
              color="#403E3F"
              mr="12px"
              onClick={handleClick}
            >
              {rta.result.name}
            </BtnText>

            <Box
              marginLeft="auto"
              display="flex"
              flexDirection="row"
              alignItems="center"
            >
              <DownloadButton title={rta.result.name} id={rta.result.rta_id} />
              <Button
                onClick={handleCompare}
                buttonColor="#5E6D8C"
                ml="12px"
                size="small"
              >
                Compare
              </Button>
            </Box>
          </TitleButtons>
        </Result>
      </Root>
      <Snackbar
        open={openSnackBar}
        onClose={handleClose}
        message={snackbarMessage}
      />
    </div>
  )
}

export default ProjectRTADocument
