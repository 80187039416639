import React from 'react'
import styled from 'styled-components'
import { Text, Button } from 'asimov'
import { useStores } from 'hooks/useStores'
import { useHistory } from 'react-router-dom'
import constants from '../../utils/constants'

const Root = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  font-family: 'Source Sans Pro';
`
const Result = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px 40px 20px 40px;
  margin-right: 20px;
`

const TitleButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const TitleGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
`
const Description = styled.div`
  padding-top: 10px;
  padding-right: 50px;
`

export const ProjectFilterCard = ({ filter }) => {
  const { searchStore } = useStores()
  const history = useHistory()

  const handleClick = () => {
    searchStore.setSearchTerm(filter.query)
    searchStore.setFormFilters(filter.filters)
    history.push('/')
  }

  return (
    <div>
      <Root>
        <Result>
          <Header>
            <TitleGroup>
              <TitleButtons>
                <Text fontSize={1} pt={2} pr={3}>
                  {filter.title}
                </Text>
                <Button
                  onClick={() => handleClick()}
                  buttonColor="#2A8C82"
                  size="small"
                >
                  Use Filter
                </Button>
              </TitleButtons>
            </TitleGroup>
          </Header>
          <Description>
            <Text fontSize="15px">Search Query: {filter.query}</Text>
            {Object.keys(filter.filters).map((key, filterIndex) => (
              <div key={filterIndex}>
                <Text fontSize="15px">{constants.filterLabels[key]}: </Text>
                {filter.filters[key] &&
                  filter.filters[key].length > 0 &&
                  filter.filters[key].map((element, index) => (
                    <React.Fragment key={index}>
                      {typeof element !== 'object' ? (
                        <Text fontSize="15px">{element}</Text>
                      ) : (
                        <Text fontSize="15px">{element.label}</Text>
                      )}
                      {index !== filter.filters[key].length - 1 && (
                        <Text fontSize="15px">, </Text>
                      )}
                    </React.Fragment>
                  ))}
              </div>
            ))}
          </Description>
        </Result>
      </Root>
    </div>
  )
}

export default ProjectFilterCard
