import React from 'react'
import { default as BaseTab } from '@material-ui/core/Tab'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#3E474F',
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    borderRight: 'solid 1px #bfbfbf',
    padding: ({ pad }) => pad || theme.spacing(2.5),
    fontFamily: [
      'Source Sans Pro',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&[aria-selected="true"]': {
      backgroundColor: ({ selectedcolor }) => selectedcolor || '#f2f1f0',
    },
  },
  selected: {},
}))

const Tab = (props) => {
  const { selectedcolor, pad } = props
  const classes = useStyles({ selectedcolor, pad })
  return <BaseTab disableRipple {...props} className={classes.root} />
}

export default Tab
