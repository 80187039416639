import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from 'asimov'

import SectionSelect from './components/SectionSelect'
import CompareSelect from './components/CompareSelect'
import FindSimilar from './components/FindSimilar'

const Sidebar = styled.div`
  background-color: #ebebe9;
  height: calc(100vh - 100px);
  width: 350px;
  min-width: 350px;
`

const TabButton = styled(Button)`
  ${(props) =>
    props.active
      ? `color: #3E474F;
  background-color: #EBEBE9;`
      : `color: #a9aaac;
      background-color: rgba(169, 170, 172, 0.2);`}
  width: 50%;

  text-transform: none;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
`

const TabButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const CompareTabContainer = styled.div`
  display: block;
  height: calc(100% - 64px);
  overflow: auto;
`

export const RTAViewSidebar = () => {
  const [clicked, setClicked] = useState('Navigate')

  return (
    <Sidebar>
      <TabButtonContainer>
        <TabButton
          buttonColor="black"
          variant="tertiary"
          onClick={() => {
            setClicked('Navigate')
          }}
          active={clicked === 'Navigate'}
        >
          Navigate
        </TabButton>
        <TabButton
          buttonColor="black"
          variant="tertiary"
          onClick={() => {
            setClicked('Compare')
          }}
          active={clicked === 'Compare'}
        >
          Compare
        </TabButton>
      </TabButtonContainer>
      {clicked === 'Navigate' ? <SectionSelect /> : null}
      {clicked === 'Compare' ? (
        <CompareTabContainer>
          <FindSimilar />
          <CompareSelect />
        </CompareTabContainer>
      ) : null}
    </Sidebar>
  )
}

export default RTAViewSidebar
