import React from 'react'
import { useParams } from 'react-router'
import Header from 'components/Header/Header'
import RTAViewSidebar from 'components/RTAViewSidebar/RTAViewSidebar'
import RTAViewer from 'components/RTAViewer'
import CollapsibleKeywordBox from 'components/CollapsibleKeywordBox'
import styled from 'styled-components'
import { useStores } from 'hooks/useStores'

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 100px);
`

export const RTAViewPage = () => {
  const params = useParams()
  const rtaID = params.id

  const { rtaViewStore } = useStores()
  rtaViewStore.setRTAID(rtaID)

  return (
    <Root>
      <Header type={'rtaView'} />
      <Container>
        <RTAViewSidebar />
        <RTAViewer />
        <CollapsibleKeywordBox />
      </Container>
    </Root>
  )
}

export default RTAViewPage
