import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Modal, Text, Button, Box } from 'asimov'
import Loader from 'react-loader-spinner'
import SearchResult from './components/SearchResult'
import { useStores } from 'hooks/useStores'
import { default as BasePagination } from '@material-ui/lab/Pagination'
import { withStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

const Root = styled.div`
  display: flex;
  color: black;
  width: 80%;
`
const ResultsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 30px;
`

const ResultsGroup = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Info = styled(Box)`
  margin-left: auto;
  margin-right: 17px;
  color: #403e3f;

  &:hover {
    cursor: pointer;
  }

  &:hover .info-tooltip,
  .info-tooltip:hover {
    display: block;
    position: absolute;
  }
`

const Pagination = withStyles({
  root: {
    '& .MuiPaginationItem-page': {
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
      color: '#403E3F',
    },
    '& .Mui-selected': {
      backgroundColor: '#5e6d8c',
      color: 'white',
      fontFamily: 'Source Sans Pro',
      fontWeight: 'bold',
    },
    '& .Mui-selected:hover': {
      backgroundColor: '#5e6d8c',
      opacity: '0.8',
    },
  },
})(BasePagination)

const RelevanceInfo = styled(({ className }) => (
  <Box className={`info-tooltip ${className}`} p="3">
    <Text fontWeight="bold" color="#5e6d8c" fontSize="12px">
      Relevance Scoring
    </Text>{' '}
    for a given document is based on:
    <ul>
      <li>
        how many times the search term appears in the document;
      </li>
      <li>
        how many words the document contains;
      </li>
      <li>
        how many words in the search query appear in the document, if the query
        has multiple words;
      </li>
      <li>
        whether the word matches found are exact or fuzzy/inexact;
      </li>
      <li>
        how many documents in the whole database contain the search terms;
      </li>
      <li>
        how many words all documents in the database contain, on average
      </li>
    </ul>
  </Box>
))`
  display: none;
  font-size: 12px;
  background-color: white;
  width: 400px;
  margin-top: -150px;
  margin-left: -385px;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.5);

  ul {
    margin-top: 4px;
    padding-left: 24px;
  }
`

export const SearchResults = observer(() => {
  const { searchStore } = useStores()

  const [open, setOpen] = useState(false)
  const close = () => setOpen(false)

  if (searchStore.results.length === 0) {
    if (!searchStore.isSearchLoading) {
      return (
        <Box pt="25px" width="80%">
          <Text fontSize="22px" fontWeight="bold" color="#403E3F">
            {searchStore.resultsCount} results
          </Text>
          <Text fontSize={15} mt={2}>
            There are no RTAs corresponding to the selected filters and/or no
            RTA contains the searched string
          </Text>
        </Box>
      )
    } else {
      return <div></div>
    }
  }

  const handleExport = () => {
    setOpen(true)
    searchStore.exportSearch()
  }

  return (
    <Root>
      <ResultsContainer>
        <Box flexDirection="row" alignItems="center" pt="25px" width="100%">
          <Text fontSize="22px" fontWeight="bold" color="#403E3F">
            {searchStore.resultsCount} results
          </Text>
          {searchStore.results.length > 0 && (
            <>
              <Button
                ml="16px"
                onClick={handleExport}
                buttonColor="#2A8C82"
                size="small"
              >
                Export search results
              </Button>
              <Info>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <RelevanceInfo />
              </Info>
            </>
          )}
        </Box>
        {open ? (
          <Modal onClose={close}>
            <Box bg="white" width="300px" p="4" borderRadius="1">
              {searchStore.isExporting ? (
                <Box alignItems="center">
                  <Text mb={3} mr={2}>
                    Exporting search results...
                  </Text>
                  <Loader
                    type="ThreeDots"
                    color="#5E6D8C"
                    height={50}
                    width={50}
                  />
                </Box>
              ) : (
                <Box alignItems="center">
                  <Text>Search results are ready.</Text>
                  <CSVLink
                    data={searchStore.allResults}
                    filename={'SearchResults.csv'}
                  >
                    <Button
                      mt="3"
                      onClick={() => setOpen(false)}
                      buttonColor="#2A8C82"
                    >
                      Download Search Results
                    </Button>
                  </CSVLink>
                </Box>
              )}
            </Box>
          </Modal>
        ) : null}
        <ResultsGroup>
          {searchStore.results.map((result, index) => {
            return <SearchResult key={index} result={result} />
          })}
        </ResultsGroup>
        <PageContainer>
          <Pagination
            count={searchStore.getPageCount()}
            page={searchStore.uiPageNum}
            onChange={(event, pageNumber) => {
              searchStore.search({ page: pageNumber })
              searchStore.setUiPageNum(pageNumber)
            }}
          />
        </PageContainer>
      </ResultsContainer>
    </Root>
  )
})

export default SearchResults
