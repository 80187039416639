import React, { useCallback, forwardRef, useEffect } from 'react'
import styled from 'styled-components'
import update from 'immutability-helper'
import Select from 'react-select'
import { Box, Input, Button, Text, Link } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faGripVertical,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons'
import DraggableRow from 'components/DraggableRow'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react-lite'
import constants from 'utils/constants'
import Loader from 'react-loader-spinner'

const ItemTypes = {
  ROW: 'row',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const ScrollableBox = styled.div`
  display: block;
  overflow: auto;
  height: 90%;
`

const Header = styled.div`
  background-color: #f2f1f0;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-align: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 900;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  opacity: ${(props) => props.opacity};

  &:hover {
    cursor: ${(props) => (props.canSort ? 'move' : 'auto')};
  }
`

const RowEntry = styled(Box)`
  flex-direction: row;
  padding: 8px 0px;
  width: ${(props) => props.width};
  align-items: center;
  justify-content: center;
`

const Icon = styled(FontAwesomeIcon)`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  &:hover {
    cursor: pointer;
  }
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const customStyles = {
  control: () => ({
    color: '#A9AAAC',
    width: '100px',
    display: 'flex',
    fontFamily: 'Source Sans Pro',
    border: 'solid 1px #acaaab',
    borderRadius: '2px',
    backgroundColor: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    width: '100px',
    fontFamily: 'Source Sans Pro',
    backgroundColor: 'white',
    position: 'absolute',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

const SectionRow = forwardRef((props, ref) => {
  const {
    name,
    lang,
    link,
    handleDelete,
    updateField,
    index,
    id,
    sectionID,
  } = props

  useEffect(() => {
    if (ref.current && ref.current.id === sectionID) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [sectionID, ref])

  return (
    <Row ref={ref} opacity={props.opacity} canSort={props.canSort} id={id}>
      <RowEntry width="5%">
        <Icon icon={faGripVertical} hidden={!props.canSort} />
      </RowEntry>
      <RowEntry width="40%">
        <CustomInput
          width="100%"
          value={name}
          onChange={(e) => updateField('section_name', e.target.value, index)}
        />
      </RowEntry>
      <RowEntry width="15%">
        <Select
          placeholder="-"
          options={constants.languageCodeOptions}
          styles={customStyles}
          defaultValue={constants.languageCodeOptions.filter(
            (code) => code.value === lang
          )}
          onChange={(e) => updateField('lang', e.value, index)}
        />
      </RowEntry>
      <RowEntry width="30%">
        <CustomInput
          mr="2"
          width="100%"
          value={link}
          onChange={(e) => updateField('link', e.target.value, index)}
        />
        <Link href={link} target="_blank" color="#3E474F">
          <Icon icon={faExternalLinkAlt} />
        </Link>
      </RowEntry>
      <RowEntry width="10%" align="center">
        <Icon icon={faTrash} onClick={() => handleDelete(index)} />
      </RowEntry>
    </Row>
  )
})

const RTASectionsTable = observer(
  ({ langCode, canSort = true, tab, sectionID }) => {
    const { rtaManagementStore } = useStores()

    const sectionsLength =
      langCode === 'ALL'
        ? rtaManagementStore.length
        : rtaManagementStore.sections.filter(
            (section) => section.lang === langCode
          ).length

    const moveRow = useCallback(
      (dragIndex, hoverIndex) => {
        if (rtaManagementStore.sections.length === 0) {
          return
        }
        const dragRow = rtaManagementStore.sections[dragIndex]
        rtaManagementStore.setRTASections(
          update(rtaManagementStore.sections, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        )
      },
      [rtaManagementStore]
    )

    const addSection = (lang) => {
      rtaManagementStore.setRTASections(
        update(rtaManagementStore.sections, {
          $push: [
            {
              rta_id: rtaManagementStore.currRTAID,
              section_name: '',
              link: '',
              lang: lang !== 'ALL' ? lang : 'EN', // Set default lang to EN if added from ALL tab
              rank: sectionsLength,
            },
          ],
        })
      )
    }

    const deleteSection = (index) => {
      rtaManagementStore.setRTASections(
        update(rtaManagementStore.sections, {
          $splice: [[index, 1]],
        })
      )
    }

    const updateField = (key, value, index) => {
      rtaManagementStore.setRTASections(
        update(rtaManagementStore.sections, {
          [index]: { [key]: { $set: value } },
        })
      )
    }

    return (
      <Container>
        <ScrollableBox>
          <Header>
            <Box py="8px" width="5%" />
            <Box py="8px" width="40%" color="#403E3F">
              Section Name
            </Box>
            <Box py="8px" width="15%" color="#403E3F">
              Language
            </Box>
            <Box py="8px" width="30%" color="#403E3F">
              Link
            </Box>
            <Box py="8px" width="10%" color="#403E3F">
              Actions
            </Box>
          </Header>
          {rtaManagementStore.isSectionsLoading ? (
            <Box width="100%" alignItems="center">
              <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
            </Box>
          ) : null}
          {!rtaManagementStore.isSectionsLoading && sectionsLength === 0 ? (
            <Box alignItems="center" p="16px">
              <Text>No sections found for this language</Text>
            </Box>
          ) : null}
          {!rtaManagementStore.isSectionsLoading &&
            rtaManagementStore.sections.map((section, index) => {
              if (langCode === 'ALL' || section.lang === langCode) {
                return (
                  <DraggableRow
                    key={index}
                    index={index}
                    id={section.section_id}
                    moveRow={moveRow}
                    canSort={canSort}
                    type={ItemTypes.ROW}
                  >
                    {(ref, opacity) => (
                      <SectionRow
                        ref={ref}
                        id={section.section_id}
                        opacity={opacity}
                        name={section.section_name}
                        lang={section.lang}
                        link={section.link}
                        canSort={canSort}
                        index={index}
                        handleDelete={deleteSection}
                        updateField={updateField}
                        sectionID={sectionID}
                      />
                    )}
                  </DraggableRow>
                )
              } else {
                return null
              }
            })}
        </ScrollableBox>
        <Button
          buttonColor="#369DE8"
          variant="tertiary"
          mt="2"
          onClick={() => addSection(constants.languageTabs[tab])}
          alignSelf="flex-start"
        >
          + Add section
        </Button>
      </Container>
    )
  }
)

export default RTASectionsTable
