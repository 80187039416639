import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Text } from 'asimov'
import { useStores } from 'hooks/useStores'
import { Slider as BaseSlider } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles'

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`
const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const PageText = styled(Text)`
  font-size: 12px;
  color: #3e474f;
`

const Slider = withStyles({
  root: {
    color: '#5E6D8C',
  },
})(BaseSlider)

export const PageSlider = observer(
  ({
    pageIndex,
    setPageIndex,
    totalPages,
    startPage,
    endPage,
    pageSlide,
    hasReset,
  }) => {
    const { rtaViewStore } = useStores()

    const handleChange = (event, newRange) => {
      setPageIndex(newRange)
      pageSlide(newRange)
    }

    const handleReset = () => {
      setPageIndex(0)
      return null
    }
    const end = endPage > totalPages ? totalPages : endPage
    return (
      <Root visible={!!rtaViewStore.currText}>
        <SliderContainer>
          {hasReset ? (
            <PageText> Page 1 of 1</PageText>
          ) : (
            <PageText id="range-slider">
              {totalPages === 1
                ? `Page 1 of ${totalPages}`
                : totalPages < 10
                ? `Pages ${startPage}-${totalPages} of ${totalPages}`
                : `Pages ${startPage}-${end} of ${totalPages}`}
            </PageText>
          )}

          <Slider
            min={0}
            max={Math.ceil(totalPages / 10 - 1)}
            track={false}
            value={hasReset ? handleReset() : pageIndex}
            onChange={handleChange}
            aria-labelledby="continuous-slider"
          />
        </SliderContainer>
      </Root>
    )
  }
)

export default PageSlider
