import axios from 'axios'
import qs from 'querystring'

class UserService {
  constructor() {
    this.baseURL = process.env.REACT_APP_API_URL
    this.accessToken = sessionStorage.getItem('access_token')
    this.baseConfig = {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    }
  }

  login(email, password) {
    const loginEndpoint = `${this.baseURL}/users/login`
    const requestBody = {
      username: email,
      password,
    }
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }

    return axios.post(loginEndpoint, qs.stringify(requestBody), config)
  }

  me() {
    const endpoint = `${this.baseURL}/users/me`
    return axios.get(endpoint, this.baseConfig)
  }

  updateCurrentUser(payload) {
    const endpoint = `${this.baseURL}/users/me`
    return axios.put(endpoint, payload, this.baseConfig)
  }

  getUsers() {
    const endpoint = `${this.baseURL}/users`
    return axios.get(endpoint, this.baseConfig)
  }

  getUser(userID) {
    const endpoint = `${this.baseURL}/users/${userID}`
    return axios.get(endpoint, this.baseConfig)
  }

  createUser(payload) {
    const endpoint = `${this.baseURL}/users`
    return axios.post(endpoint, payload, this.baseConfig)
  }

  updateUser(userID, payload) {
    const endpoint = `${this.baseURL}/users/${userID}`
    return axios.put(endpoint, payload, this.baseConfig)
  }

  deleteUser(userID) {
    const endpoint = `${this.baseURL}/users/${userID}`
    return axios.delete(endpoint, this.baseConfig)
  }

  changePassword(payload) {
    const endpoint = `${this.baseURL}/users/change_password`
    return axios.post(endpoint, payload, this.baseConfig)
  }

  forgotPassword(payload) {
    const endpoint = `${this.baseURL}/users/forgot_password`
    return axios.post(endpoint, payload)
  }

  resetPassword(payload, token) {
    const endpoint = `${this.baseURL}/users/reset_password`
    return axios.post(endpoint, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  }

  isLoggedIn() {
    return this.accessToken == null ? false : true
  }

  saveAccessToken(accessToken) {
    this.accessToken = accessToken
    sessionStorage.setItem('access_token', accessToken)
  }

  logout() {
    sessionStorage.removeItem('access_token')
  }
}

export default UserService
