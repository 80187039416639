import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { Text, Button, Box } from 'asimov'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { useHistory } from 'react-router-dom'

import { useStores } from 'hooks/useStores'

const CompareContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
`

const ButtonDrop = styled(Button)`
  width: 280px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
`

const SectionContainer = styled.div`
  padding: 20px 30px 10px 45px;
  display: flex;
  flex-direction: column;
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    width: width,
    backgroundColor: '#F2F1F0',
    borderColor: '#E5E4E1',
    color: 'black',
    display: 'flex',
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const CompareSelect = observer(() => {
  const { rtaViewStore, rtaCompareStore } = useStores()
  const history = useHistory()

  const [compareID, setCompareID] = useState(null)
  const [openCompare, setOpenCompare] = useState(true)

  useEffect(() => {
    rtaViewStore.fetchRTAs()
  }, [rtaViewStore])

  const handleClick = () => {
    rtaCompareStore.setLang({ label: 'English', value: 'EN' })
    rtaCompareStore.setKeywordMatches1([])
    rtaCompareStore.setKeywordMatches2([])
    history.push(
      `/compare?rta_id_1=${rtaViewStore.rtaID}&rta_id_2=${compareID}`
    )
  }

  return (
    <SectionContainer>
      <ButtonDrop
        variant={'tertiary'}
        buttonColor="black"
        onClick={() => {
          setOpenCompare(!openCompare)
        }}
      >
        <Text fontSize={'15px'} fontWeight={'bold'}>
          COMPARE
        </Text>
        {openCompare ? (
          <FontAwesomeIcon icon={faChevronDown} color="#3E474F" />
        ) : (
          <FontAwesomeIcon icon={faChevronUp} color="#3E474F" />
        )}
      </ButtonDrop>
      {openCompare ? (
        <CompareContainer>
          <Text fontSize="15px" pt={2} pb={2}>
            Compare <strong>{rtaViewStore.rtaInfo.rtaName}</strong> with a
            specific document
          </Text>
          <Select
            width={'100%'}
            placeholder={'Select Document'}
            options={rtaViewStore.rtaOptions}
            styles={customStyles}
            onChange={(e) => {
              setCompareID(e.value)
            }}
            className="basic-multi-select"
            classNamePrefix="select"
          />
          <Box width="120px">
            <Button
              onClick={() => {
                handleClick()
              }}
              buttonColor="#5E6D8C"
              variant="primary"
              mt={3}
              fontSize="15px"
              size="medium"
            >
              COMPARE{' '}
            </Button>
          </Box>
        </CompareContainer>
      ) : null}
    </SectionContainer>
  )
})

export default CompareSelect
