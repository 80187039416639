export const countries = [
  {
    value: '004',
    label: 'Afghanistan',
  },
  {
    value: '008',
    label: 'Albania',
  },
  {
    value: '012',
    label: 'Algeria',
  },
  {
    value: '016',
    label: 'American Samoa',
  },
  {
    value: '020',
    label: 'Andorra',
  },
  {
    value: '024',
    label: 'Angola',
  },
  {
    value: '028',
    label: 'Antigua and Barbuda',
  },
  {
    value: '031',
    label: 'Azerbaijan',
  },
  {
    value: '032',
    label: 'Argentina',
  },
  {
    value: '036',
    label: 'Australia',
  },
  {
    value: '040',
    label: 'Austria',
  },
  {
    value: '044',
    label: 'Bahamas',
  },
  {
    value: '048',
    label: 'Bahrain, Kingdom of',
  },
  {
    value: '050',
    label: 'Bangladesh',
  },
  {
    value: '051',
    label: 'Armenia',
  },
  {
    value: '052',
    label: 'Barbados',
  },
  {
    value: '056',
    label: 'Belgium',
  },
  {
    value: '060',
    label: 'Bermuda',
  },
  {
    value: '064',
    label: 'Bhutan',
  },
  {
    value: '068',
    label: 'Bolivia, Plurinational State of',
  },
  {
    value: '070',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: '072',
    label: 'Botswana',
  },
  {
    value: '074',
    label: 'Bouvet Island',
  },
  {
    value: '076',
    label: 'Brazil',
  },
  {
    value: '084',
    label: 'Belize',
  },
  {
    value: '086',
    label: 'British Indian Ocean Territory',
  },
  {
    value: '090',
    label: 'Solomon Islands',
  },
  {
    value: '092',
    label: 'Virgin Islands, British',
  },
  {
    value: '096',
    label: 'Brunei Darussalam',
  },
  {
    value: '100',
    label: 'Bulgaria',
  },
  {
    value: '104',
    label: 'Myanmar',
  },
  {
    value: '108',
    label: 'Burundi',
  },
  {
    value: '112',
    label: 'Belarus',
  },
  {
    value: '116',
    label: 'Cambodia',
  },
  {
    value: '120',
    label: 'Cameroon',
  },
  {
    value: '124',
    label: 'Canada',
  },
  {
    value: '132',
    label: 'Cabo Verde',
  },
  {
    value: '136',
    label: 'Cayman Islands',
  },
  {
    value: '140',
    label: 'Central African Republic',
  },
  {
    value: '144',
    label: 'Sri Lanka',
  },
  {
    value: '148',
    label: 'Chad',
  },
  {
    value: '152',
    label: 'Chile',
  },
  {
    value: '156',
    label: 'China',
  },
  {
    value: '158',
    label: 'Chinese Taipei',
  },
  {
    value: '162',
    label: 'Christmas Island',
  },
  {
    value: '166',
    label: 'Cocos (Keeling) Islands',
  },
  {
    value: '170',
    label: 'Colombia',
  },
  {
    value: '174',
    label: 'Comoros',
  },
  {
    value: '175',
    label: 'Mayotte',
  },
  {
    value: '178',
    label: 'Congo',
  },
  {
    value: '180',
    label: 'Democratic Republic of the Congo',
  },
  {
    value: '184',
    label: 'Cook Islands',
  },
  {
    value: '188',
    label: 'Costa Rica',
  },
  {
    value: '191',
    label: 'Croatia',
  },
  {
    value: '192',
    label: 'Cuba',
  },
  {
    value: '196',
    label: 'Cyprus',
  },
  {
    value: '203',
    label: 'Czech Republic',
  },
  {
    value: '204',
    label: 'Benin',
  },
  {
    value: '208',
    label: 'Denmark',
  },
  {
    value: '212',
    label: 'Dominica',
  },
  {
    value: '214',
    label: 'Dominican Republic',
  },
  {
    value: '218',
    label: 'Ecuador',
  },
  {
    value: '222',
    label: 'El Salvador',
  },
  {
    value: '226',
    label: 'Equatorial Guinea',
  },
  {
    value: '231',
    label: 'Ethiopia',
  },
  {
    value: '232',
    label: 'Eritrea',
  },
  {
    value: '233',
    label: 'Estonia',
  },
  {
    value: '234',
    label: 'Faeroe Islands',
  },
  {
    value: '238',
    label: 'Falkland Islands (Islas Malvinas)',
  },
  {
    value: '239',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    value: '242',
    label: 'Fiji',
  },
  {
    value: '246',
    label: 'Finland',
  },
  {
    value: '250',
    label: 'France',
  },
  {
    value: '252',
    label: 'French Antilles',
  },
  {
    value: '255',
    label: 'French Oceania',
  },
  {
    value: '258',
    label: 'French Polynesia',
  },
  {
    value: '260',
    label: 'French Southern Territories',
  },
  {
    value: '262',
    label: 'Djibouti',
  },
  {
    value: '266',
    label: 'Gabon',
  },
  {
    value: '268',
    label: 'Georgia',
  },
  {
    value: '270',
    label: 'The Gambia',
  },
  {
    value: '275',
    label: 'Palestine',
  },
  {
    value: '276',
    label: 'Germany',
  },
  {
    value: '288',
    label: 'Ghana',
  },
  {
    value: '292',
    label: 'Gibraltar',
  },
  {
    value: '296',
    label: 'Kiribati',
  },
  {
    value: '300',
    label: 'Greece',
  },
  {
    value: '304',
    label: 'Greenland',
  },
  {
    value: '308',
    label: 'Grenada',
  },
  {
    value: '316',
    label: 'Guam',
  },
  {
    value: '320',
    label: 'Guatemala',
  },
  {
    value: '321',
    label: 'Guernsey',
  },
  {
    value: '324',
    label: 'Guinea',
  },
  {
    value: '328',
    label: 'Guyana',
  },
  {
    value: '332',
    label: 'Haiti',
  },
  {
    value: '334',
    label: 'Heard Island and Mcdonald Islands',
  },
  {
    value: '336',
    label: 'Holy See',
  },
  {
    value: '340',
    label: 'Honduras',
  },
  {
    value: '344',
    label: 'Hong Kong, China',
  },
  {
    value: '348',
    label: 'Hungary',
  },
  {
    value: '352',
    label: 'Iceland',
  },
  {
    value: '356',
    label: 'India',
  },
  {
    value: '360',
    label: 'Indonesia',
  },
  {
    value: '364',
    label: 'Iran',
  },
  {
    value: '368',
    label: 'Iraq',
  },
  {
    value: '372',
    label: 'Ireland',
  },
  {
    value: '376',
    label: 'Israel',
  },
  {
    value: '380',
    label: 'Italy',
  },
  {
    value: '384',
    label: "Côte d'Ivoire",
  },
  {
    value: '388',
    label: 'Jamaica',
  },
  {
    value: '392',
    label: 'Japan',
  },
  {
    value: '393',
    label: 'Jersey',
  },
  {
    value: '398',
    label: 'Kazakhstan',
  },
  {
    value: '400',
    label: 'Jordan',
  },
  {
    value: '404',
    label: 'Kenya',
  },
  {
    value: '408',
    label: "Korea, Democratic People's Republic of",
  },
  {
    value: '410',
    label: 'Korea, Republic of',
  },
  {
    value: '414',
    label: 'Kuwait, the State of',
  },
  {
    value: '417',
    label: 'Kyrgyz Republic',
  },
  {
    value: '418',
    label: "Lao People's Democratic Republic",
  },
  {
    value: '422',
    label: 'Lebanese Republic',
  },
  {
    value: '426',
    label: 'Lesotho',
  },
  {
    value: '428',
    label: 'Latvia',
  },
  {
    value: '430',
    label: 'Liberia',
  },
  {
    value: '434',
    label: 'Libya',
  },
  {
    value: '438',
    label: 'Liechtenstein',
  },
  {
    value: '440',
    label: 'Lithuania',
  },
  {
    value: '442',
    label: 'Luxembourg',
  },
  {
    value: '446',
    label: 'Macao, China',
  },
  {
    value: '450',
    label: 'Madagascar',
  },
  {
    value: '454',
    label: 'Malawi',
  },
  {
    value: '458',
    label: 'Malaysia',
  },
  {
    value: '462',
    label: 'Maldives',
  },
  {
    value: '466',
    label: 'Mali',
  },
  {
    value: '470',
    label: 'Malta',
  },
  {
    value: '471',
    label: 'Isle of Man',
  },
  {
    value: '478',
    label: 'Mauritania',
  },
  {
    value: '480',
    label: 'Mauritius',
  },
  {
    value: '484',
    label: 'Mexico',
  },
  {
    value: '492',
    label: 'Monaco',
  },
  {
    value: '496',
    label: 'Mongolia',
  },
  {
    value: '498',
    label: 'Moldova, Republic of',
  },
  {
    value: '500',
    label: 'Montserrat',
  },
  {
    value: '504',
    label: 'Morocco',
  },
  {
    value: '508',
    label: 'Mozambique',
  },
  {
    value: '512',
    label: 'Oman',
  },
  {
    value: '516',
    label: 'Namibia',
  },
  {
    value: '520',
    label: 'Nauru',
  },
  {
    value: '524',
    label: 'Nepal',
  },
  {
    value: '528',
    label: 'Netherlands',
  },
  {
    value: '530',
    label: 'Netherlands Antilles',
  },
  {
    value: '531',
    label: 'Curaçao',
  },
  {
    value: '532',
    label: 'Netherlands Antilles (incl. Aruba)',
  },
  {
    value: '533',
    label: 'Aruba, the Netherlands with respect to',
  },
  {
    value: '534',
    label: 'Saint Martin',
  },
  {
    value: '540',
    label: 'New Caledonia',
  },
  {
    value: '548',
    label: 'Vanuatu',
  },
  {
    value: '554',
    label: 'New Zealand',
  },
  {
    value: '558',
    label: 'Nicaragua',
  },
  {
    value: '562',
    label: 'Niger',
  },
  {
    value: '566',
    label: 'Nigeria',
  },
  {
    value: '570',
    label: 'Niue',
  },
  {
    value: '574',
    label: 'Norfolk Island',
  },
  {
    value: '578',
    label: 'Norway',
  },
  {
    value: '580',
    label: 'Northern Mariana Islands',
  },
  {
    value: '581',
    label: 'United States Minor Outlying Islands',
  },
  {
    value: '583',
    label: 'Micronesia, Federated States of',
  },
  {
    value: '584',
    label: 'Marshall Islands',
  },
  {
    value: '585',
    label: 'Palau',
  },
  {
    value: '586',
    label: 'Pakistan',
  },
  {
    value: '591',
    label: 'Panama',
  },
  {
    value: '598',
    label: 'Papua New Guinea',
  },
  {
    value: '600',
    label: 'Paraguay',
  },
  {
    value: '601',
    label: 'Trust Territory of the Pacific Islands',
  },
  {
    value: '604',
    label: 'Peru',
  },
  {
    value: '608',
    label: 'Philippines',
  },
  {
    value: '612',
    label: 'Pitcairn',
  },
  {
    value: '616',
    label: 'Poland',
  },
  {
    value: '620',
    label: 'Portugal',
  },
  {
    value: '624',
    label: 'Guinea-Bissau',
  },
  {
    value: '626',
    label: 'Timor-Leste',
  },
  {
    value: '630',
    label: 'Puerto Rico',
  },
  {
    value: '634',
    label: 'Qatar',
  },
  {
    value: '642',
    label: 'Romania',
  },
  {
    value: '643',
    label: 'Russian Federation',
  },
  {
    value: '646',
    label: 'Rwanda',
  },
  {
    value: '654',
    label:
      'British Overseas Territory of Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    value: '659',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: '660',
    label: 'Anguilla',
  },
  {
    value: '662',
    label: 'Saint Lucia',
  },
  {
    value: '666',
    label: 'Saint Pierre and Miquelon',
  },
  {
    value: '670',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: '674',
    label: 'San Marino',
  },
  {
    value: '678',
    label: 'Sao Tomé and Principe',
  },
  {
    value: '682',
    label: 'Saudi Arabia, Kingdom of',
  },
  {
    value: '686',
    label: 'Senegal',
  },
  {
    value: '690',
    label: 'Seychelles',
  },
  {
    value: '694',
    label: 'Sierra Leone',
  },
  {
    value: '702',
    label: 'Singapore',
  },
  {
    value: '703',
    label: 'Slovak Republic',
  },
  {
    value: '704',
    label: 'Viet Nam',
  },
  {
    value: '705',
    label: 'Slovenia',
  },
  {
    value: '706',
    label: 'Somalia',
  },
  {
    value: '710',
    label: 'South Africa',
  },
  {
    value: '716',
    label: 'Zimbabwe',
  },
  {
    value: '724',
    label: 'Spain',
  },
  {
    value: '728',
    label: 'South Sudan',
  },
  {
    value: '732',
    label: 'Western Sahara',
  },
  {
    value: '736',
    label: 'Sudan',
  },
  {
    value: '740',
    label: 'Suriname',
  },
  {
    value: '744',
    label: 'Svalbard and Jan Mayen',
  },
  {
    value: '748',
    label: 'Eswatini',
  },
  {
    value: '752',
    label: 'Sweden',
  },
  {
    value: '756',
    label: 'Switzerland',
  },
  {
    value: '760',
    label: 'Syrian Arab Republic',
  },
  {
    value: '762',
    label: 'Tajikistan',
  },
  {
    value: '764',
    label: 'Thailand',
  },
  {
    value: '768',
    label: 'Togo',
  },
  {
    value: '772',
    label: 'Tokelau',
  },
  {
    value: '776',
    label: 'Tonga',
  },
  {
    value: '780',
    label: 'Trinidad and Tobago',
  },
  {
    value: '784',
    label: 'United Arab Emirates',
  },
  {
    value: '788',
    label: 'Tunisia',
  },
  {
    value: '792',
    label: 'Turkey',
  },
  {
    value: '795',
    label: 'Turkmenistan',
  },
  {
    value: '796',
    label: 'Turks and Caicos Islands',
  },
  {
    value: '798',
    label: 'Tuvalu',
  },
  {
    value: '800',
    label: 'Uganda',
  },
  {
    value: '804',
    label: 'Ukraine',
  },
  {
    value: '807',
    label: 'North Macedonia',
  },
  {
    value: '818',
    label: 'Egypt',
  },
  {
    value: '826',
    label: 'United Kingdom',
  },
  {
    value: '834',
    label: 'Tanzania',
  },
  {
    value: '840',
    label: 'United States of America',
  },
  {
    value: '850',
    label: 'Virgin Islands, US',
  },
  {
    value: '854',
    label: 'Burkina Faso',
  },
  {
    value: '858',
    label: 'Uruguay',
  },
  {
    value: '860',
    label: 'Uzbekistan',
  },
  {
    value: '862',
    label: 'Venezuela, Bolivarian Republic of',
  },
  {
    value: '876',
    label: 'Wallis and Futuna Islands',
  },
  {
    value: '882',
    label: 'Samoa',
  },
  {
    value: '887',
    label: 'Yemen',
  },
  {
    value: '888',
    label: 'UNMIK/Kosovo',
  },
  {
    value: '892',
    label: 'Serbia',
  },
  {
    value: '893',
    label: 'Montenegro',
  },
  {
    value: '894',
    label: 'Zambia',
  },
  {
    value: '918',
    label: 'European Union',
  },
]
