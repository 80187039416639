import React, { useState, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Box, Button, Text, Input } from 'asimov'
import { ClickAwayListener } from '@material-ui/core/'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CompareBucket from 'components/CompareBucket/CompareBucket'
import { faFolder } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useStores } from 'hooks/useStores'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Snackbar from 'components/Snackbar/Snackbar'
import Popper from '@material-ui/core/Popper'
import Loader from 'react-loader-spinner'

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`

const Root = styled.div`
  width: 300px;
  flex-shrink: 0;
  background-color: #ebebe9;
  min-height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionContainer = styled.div`
  padding: 35px 20px;
`

const StyledButton = styled(Button)`
  width: 200px;
  text-transform: none;
`

const AddButton = styled(Button)`
  text-align: left;
  width: 100%;
  margin-right: 20px;
  margin-left: 4px;
`

const AddButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
const ProjectButton = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Projects = styled.div`
  overflow-y: auto;
  max-height: 190px;
  min-height: 190px;
  display: block;
  margin-top: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
`

const StyledLink = styled(Link)`
  color: #2a8c82;
  font-size: 15px;
  margin-left: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Icon = styled(FontAwesomeIcon)`
  color: #3e474f;
  margin-right: 8px;
`

const PopperContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 10px;
  margin-left: -120px;
  width: 301px;
  padding: 20px;
  position: absolute;
  box-shadow: -2px -2px 156px -38px rgba(0, 0, 0, 0.5);
  z-index: 100;

  ${(props) => (props.anchor ? `margin-left: -10px;` : ``)}
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

export const Sidebar = observer(() => {
  const { projectStore, userStore } = useStores()

  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    setLoading(true)
    if (userStore.user.id) {
      projectStore.getProjects(userStore.user.id).then(() => {
        setLoading(false)
      })
    }
  }, [userStore.user.id, projectStore])

  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const open = Boolean(anchorEl)
  const popperId = open ? 'simple-popper' : undefined
  const handleClickAway = () => {
    setAnchorEl(null)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenSnackBar(false)
  }

  const saveProjectFolder = () => {
    projectStore.createProject(projectTitle, userStore.user.id)
    setOpenSnackBar(true)
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter') {
      saveProjectFolder()
    }
  }
  const [projectTitle, setProjectTitle] = useState('')
  const history = useHistory()

  return (
    <Root>
      <SectionContainer>
        <Section>
          <Text
            fontSize="18px"
            fontWeight="bold"
            mb="1"
            pl="12px"
            color="#403E3F"
          >
            Recent Projects
          </Text>
          <StyledLink to="/projects">See all projects →</StyledLink>
          {loading ? (
            <Box
              mt="2"
              height="190px"
              alignItems="center"
              justifyContent="center"
            >
              <Loader type="ThreeDots" color="#5e6d8c" height={50} width={50} />
            </Box>
          ) : null}
          {projectStore.projects.length === 0 && !loading ? (
            <Text ml="12px" mt="16px">
              No projects found
            </Text>
          ) : null}
          {projectStore.projects.length && !loading ? (
            <Projects>
              {projectStore.projects.map((project, index) => (
                <div key={index}>
                  <StyledButton
                    variant="tertiary"
                    buttonColor="black"
                    fontWeight={1}
                    ml={1}
                    onClick={() => {
                      projectStore.clearRTAs()
                      history.push(`/project/${project.id}`)
                    }}
                  >
                    <ProjectButton>
                      <Text>
                        <Icon icon={faFolder} />
                        {project.title}
                      </Text>
                    </ProjectButton>
                  </StyledButton>
                </div>
              ))}
            </Projects>
          ) : null}
          <ClickAwayListener onClickAway={handleClickAway}>
            <AddButtonContainer>
              <AddButton
                variant="tertiary"
                buttonColor="#2A8C82"
                mb={1}
                mt={2}
                onClick={handleOpenPopper}
              >
                <Text fontWeight={'bold'}>+ Add new project</Text>
              </AddButton>

              <Popper id={popperId} open={open} anchorEl={anchorEl}>
                <PopperContainer>
                  <Text
                    mb={3}
                    fontSize="16px"
                    fontWeight="bold"
                    color="#2A8C82"
                  >
                    ADD NEW PROJECT
                  </Text>
                  <CustomInput
                    placeholder="Add project title..."
                    width="100%"
                    onChange={(e) => {
                      setProjectTitle(e.target.value)
                    }}
                    onKeyDown={handleInputKeyDown}
                  />
                  <ButtonContainer>
                    <Button
                      buttonColor="#403E3F"
                      variant="tertiary"
                      onClick={handleOpenPopper}
                    >
                      Cancel
                    </Button>
                    <Button
                      ml={2}
                      variant="primary"
                      buttonColor="#2A8C82"
                      onClick={() => {
                        saveProjectFolder()
                      }}
                    >
                      Save
                    </Button>
                  </ButtonContainer>
                </PopperContainer>
              </Popper>
            </AddButtonContainer>
          </ClickAwayListener>
          <Snackbar
            open={openSnackBar}
            onClose={handleClose}
            message="New Project Added"
          />
        </Section>
        <CompareBucket />
      </SectionContainer>
    </Root>
  )
})

export default Sidebar
