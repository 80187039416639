import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Box, Input } from 'asimov'
import KeywordResultsList from 'components/KeywordResultsList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { useStores } from 'hooks/useStores'

const Container = styled(Box)`
  height: calc(100vh - 100px);
  padding: 15px;
  background-color: #ebebe9;
  margin-left: auto;

  ${({ isOpen }) =>
    isOpen
      ? `
    position: relative;
    left: auto;
    width: 300px;
    padding: 15px;
  `
      : `
    position: absolute;
    left: 100%;
    width: 0;
    padding: 0px;
  `}
`

const Button = styled.button`
  background-color: #879dc9;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  color: white;
  position: absolute;
  right: 100%;
  top: 15px;
  padding: 8px 15px;
`

const Icon = styled(FontAwesomeIcon)`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  &:hover {
    cursor: pointer;
  }
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const KeywordContainer = observer(({ isOpen, toggleOpen }) => {
  const { rtaViewStore } = useStores()
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    rtaViewStore.setKeywordMatches([])
    setLoading(true)
    if (rtaViewStore.keyword && rtaViewStore.selectedSection.sectionID) {
      rtaViewStore
        .fetchKeywordMatches(rtaViewStore.keyword)
        .then(() => setLoading(false))
    } else {
      setLoading(false)
    } // eslint-disable-next-line
  }, [rtaViewStore.selectedSection])

  const handleOnChange = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setLoading(true)
      rtaViewStore
        .fetchKeywordMatches(e.target.value)
        .then(() => setLoading(false))
    }
  }

  const selectKeyword = (keyword) => {
    const { page_num, start, end } = keyword
    rtaViewStore.fetchHighlightedRTAText(page_num, start, end)
    const pageIndex = rtaViewStore.computePageIndex(page_num)
    rtaViewStore.setPageIndex(pageIndex)
  }

  return (
    <Container isOpen={isOpen}>
      <Button onClick={toggleOpen}>
        <Icon icon={isOpen ? faChevronRight : faChevronLeft} />
      </Button>
      <Box p="2" width="100%" overflow="auto">
        <CustomInput
          value={rtaViewStore.keyword}
          onChange={(e) => rtaViewStore.setKeyword(e.target.value)}
          onKeyDown={handleOnChange}
          before={<Icon icon={faSearch} />}
          placeholder="Search for a keyword"
          width="100%"
          mb="16px"
        />
        <KeywordResultsList
          results={rtaViewStore.keywordMatches}
          handleSelect={selectKeyword}
          keyword={rtaViewStore.keyword}
          isLoading={isLoading}
        />
      </Box>
    </Container>
  )
})

const CollapsibleKeywordBox = () => {
  const [isOpen, setOpen] = useState(true)
  const toggleOpen = () => {
    setOpen(!isOpen)
  }
  return <KeywordContainer isOpen={isOpen} toggleOpen={toggleOpen} />
}

export default CollapsibleKeywordBox
