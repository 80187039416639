import React from 'react'
import UserService from 'services/UserService'
import { Redirect, Route } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useStores } from 'hooks/useStores'

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const userSvc = new UserService()
  const { userStore } = useStores()
  const isLoggedIn = userSvc.isLoggedIn()

  return (
    <Route
      render={(props) => {
        if (isLoggedIn) {
          if (rest.path === '/rta-management') {
            return userStore.isSuperUser() ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            )
          } else {
            return <Component {...props} />
          }
        } else {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      }}
      {...rest}
    />
  )
})

export default PrivateRoute
