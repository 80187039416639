import React, { useEffect, useState, useLayoutEffect } from 'react'
import { observer } from 'mobx-react-lite'
import Header from 'components/Header/Header'
import styled from 'styled-components'
import { Box, Text, Button, Input } from 'asimov'
import CompareViewer from 'components/CompareViewer'
import RecommendationTab from 'components/RecommendationTab/RecommendationTab'
import { Tab, Tabs, TabPanel } from 'components/Tabs'
import Select from 'react-select'
import constants from 'utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { useLocation } from 'react-router'

import qs from 'qs'
import Loader from 'react-loader-spinner'

import { useStores } from 'hooks/useStores'
import RTAService from 'services/RTAService'

const Root = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Content = styled.div`
  padding: 30px 50px 0px 50px;
  display: flex;
  flex-direction: column;
`

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
`
const TextHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
`

const StyledButton = styled(Button)`
  text-transform: none;
`

const Score = styled.div`
  background-color: ${(props) => `${props.bg}`};
  color: white;
  font-size: 18px;
  padding: 4px;
  display: flex;
  flex-direction: row;
  min-width: 120px;
  height: 20px;
`

const ScoreSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 450px;
  margin-left: auto;
  justify-content: space-between;
`

const SelectContainer = styled.div`
  width: 150px;
`

const Icon = styled(FontAwesomeIcon)`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  &:hover {
    cursor: pointer;
  }
`

const TabTextHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${(props) => props.height || ''};
`

const CustomInput = styled(Input)`
  input {
    font-family: 'Source Sans Pro';
  }
`

const customStyles = {
  control: (_, { selectProps: { width } }) => ({
    color: 'black',
    width: width,
    backgroundColor: 'white',
    marginRight: '25px',
    borderColor: 'black',
    display: 'flex',
  }),
  menu: (provided, { selectProps: { width } }) => ({
    ...provided,
    width: width,
  }),
  option: (styles, { isDisabled, isSelected, isFocused }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? '#5E6D8C'
      : isFocused
      ? 'rgba(94, 109, 141, 0.3)'
      : null,
    color: isDisabled ? '#ccc' : isSelected ? 'white' : '#403E3F',
    ':active': {
      ...styles[':active'],
      backgroundColor:
        !isDisabled && (isSelected ? '#5E6D8C' : 'rgba(94, 109, 141, 0.3)'),
    },
  }),
}

export const ComparePage = observer(() => {
  const location = useLocation()

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })
  const rtaID1 = parseInt(queryParams.rta_id_1)
  const rtaID2 = parseInt(queryParams.rta_id_2)

  const { rtaCompareStore, rtaViewStore } = useStores()
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState(0)

  const rtaText1 = rtaCompareStore.getRTAText1()
  const rtaText2 = rtaCompareStore.getRTAText2()

  const rtaSections1 = rtaCompareStore.getSectionOptions('RTA_ID_1')
  const rtaSections2 = rtaCompareStore.getSectionOptions('RTA_ID_2')

  const rtaPageCount1 = rtaCompareStore.rtaPageCount1
  const rtaPageCount2 = rtaCompareStore.rtaPageCount2

  const metadata1 = rtaCompareStore.metadata1
  const metadata2 = rtaCompareStore.metadata2

  const [diffHTML, setDiffHTML] = useState('')
  const rtaNav1 = rtaCompareStore.rtaNav1
  const rtaNav2 = rtaCompareStore.rtaNav2

  const sectionScore = rtaCompareStore.sectionScore
  const fullScore = rtaCompareStore.fullScore

  const [keyword, setKeyword] = useState('')

  const [rta1Loading, setRTA1Loading] = useState(false)
  const [rta2Loading, setRTA2Loading] = useState(false)
  const [rtaText1Loading, setRTAText1Loading] = useState(false)
  const [rtaText2Loading, setRTAText2Loading] = useState(false)

  const fetchDiff = () => {
    setIsLoading(true)

    const rtaService = new RTAService()
    rtaService.diffRTAs(rtaText1, rtaText2).then(
      (response) => {
        setDiffHTML([
          rtaCompareStore.reformatText(response.data[0]),
          rtaCompareStore.reformatText(response.data[1]),
        ])
        setIsLoading(false)
      },
      (error) => {
        console.error(error)
        setIsLoading(false)
      }
    )
  }

  const displaySimilarity = () => {
    fetchDiff()
  }

  const clearSimilarity = () => {
    setDiffHTML([])
  }

  const fetchRTATexts = (sections) => {
    if (sections[0]) {
      rtaCompareStore
        .fetchPageRange('RTA_ID_1', [1, 10], sections[0].sectionID)
        .then(() => setRTAText1Loading(false))
    } else {
      rtaCompareStore.clearRTAText1()
      setRTAText1Loading(false)
    }

    if (sections[1]) {
      rtaCompareStore
        .fetchPageRange('RTA_ID_2', [1, 10], sections[1].sectionID)
        .then(() => setRTAText2Loading(false))
    } else {
      rtaCompareStore.clearRTAText2()
      setRTAText2Loading(false)
    }
  }

  const changeLanguage = async (lang) => {
    setRTA1Loading(true)
    setRTA2Loading(true)
    setRTAText1Loading(true)
    setRTAText2Loading(true)

    setKeyword('')
    rtaCompareStore.setLang(
      constants.languageOptions.find((langOption) => langOption.value === lang)
    )
    rtaCompareStore.setKeywordMatches1([])
    rtaCompareStore.setKeywordMatches2([])

    const sections = await rtaCompareStore
      .fetchRTASections(rtaCompareStore.rtaID1, rtaCompareStore.rtaID2, lang)
      .then((res) => {
        if (res[0]) {
          rtaCompareStore.setRTA1SelectedSection({
            value: res[0].sectionID,
            label: res[0].sectionName,
          })
        } else {
          rtaCompareStore.setRTA1SelectedSection({ value: null, label: null })
          rtaCompareStore.setRTANav1({ sectionID: null, pageNum: 1 })
        }
        if (res[1]) {
          rtaCompareStore.setRTA2SelectedSection({
            value: res[1].sectionID,
            label: res[1].sectionName,
          })
        } else {
          rtaCompareStore.setRTA2SelectedSection({ value: null, label: null })
          rtaCompareStore.setRTANav2({ sectionID: null, pageNum: 1 })
        }
        setRTA1Loading(false)
        setRTA2Loading(false)
        return res
      })
    await fetchRTATexts(sections)
    if (sections[0] && sections[1]) {
      await rtaCompareStore.fetchSimilarityScore(
        sections[0].sectionID,
        sections[1].sectionID
      )
    } else {
      rtaCompareStore.setFullScore(null)
      rtaCompareStore.setSectionScore(null)
    }
  }

  useEffect(() => {
    rtaViewStore.fetchRTAs()
  }, [rtaViewStore])

  useEffect(() => {
    rtaCompareStore.clearMetaData()
    rtaCompareStore.clearRTAText()
    rtaCompareStore.clearRTAScores()
  }, [rtaCompareStore])

  useLayoutEffect(() => {
    async function fetchData() {
      // Enable all loading indicators
      setRTA1Loading(true)
      setRTA2Loading(true)
      rtaCompareStore.setFullScoreLoading(true)
      rtaCompareStore.setSectionScoreLoading(true)
      setRTAText1Loading(true)
      setRTAText2Loading(true)

      rtaCompareStore.setRTAIDs(rtaID1, rtaID2)
      rtaViewStore.fetchSimilarRTAs(rtaID1)
      await rtaCompareStore.fetchMetadata(rtaID1, rtaID2)
      const sections = await rtaCompareStore
        .fetchRTASections(rtaID1, rtaID2, 'EN')
        .then((res) => {
          if (res[0]) {
            rtaCompareStore.setRTA1SelectedSection({
              value: res[0].sectionID,
              label: res[0].sectionName,
            })
          }
          if (res[1]) {
            rtaCompareStore.setRTA2SelectedSection({
              value: res[1].sectionID,
              label: res[1].sectionName,
            })
          }
          setRTA1Loading(false)
          setRTA2Loading(false)
          return res
        })
      if (sections[0] && sections[1]) {
        await rtaCompareStore.fetchSimilarityScore(
          sections[0].sectionID,
          sections[1].sectionID
        )
      } else {
        rtaCompareStore.setFullScore(null)
        rtaCompareStore.setSectionScore(null)
      }
      await fetchRTATexts(sections)
    }
    fetchData() // eslint-disable-next-line
  }, [rtaCompareStore, rtaID1, rtaID2, rtaViewStore, location])

  useEffect(() => {
    setDiffHTML([])
  }, [rtaText1, rtaText2])

  useEffect(() => {
    const el1 = document.getElementById('highlight-1')
    const el2 = document.getElementById('highlight-2')
    if (el1) {
      el1.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    if (el2) {
      el2.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [rtaCompareStore, rtaText1, rtaText2])

  const handleOnChange = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      rtaCompareStore.fetchKeywordMatches(e.target.value)
    }
  }

  if (!metadata1 || !metadata2) {
    return <div> Loading... </div>
  }

  return (
    <Root>
      <Header type={'compare'} />
      <Box bg="#DBDBDB" pl="5">
        <Tabs value={tab} onChange={(e, value) => setTab(value)}>
          <Tab label="Text Comparison" />
          <Tab label="Keyword Search" />
          <Tab label="Metadata Comparison" />
          <Tab label="Recommendations" />
        </Tabs>
      </Box>
      <Content>
        {tab === 3 ? (
          <RecommendationTab rtaId={rtaCompareStore.rtaID1} setTab={setTab} />
        ) : (
          <div>
            <TextHeader>
              <TabPanel value={tab} index={0} width="100%">
                <TabTextHeader height={tab === 0 ? '38px' : ''}>
                  <Text
                    color="#3E474F"
                    fontWeight="bold"
                    fontSize="25px"
                    pr={2}
                  >
                    Compare
                  </Text>
                  <SelectContainer>
                    <Select
                      styles={customStyles}
                      width={'120px'}
                      placeholder={'Language'}
                      options={constants.languageOptions}
                      value={rtaCompareStore.lang}
                      defaultValue={constants.languageOptions[0]}
                      onChange={(e) => changeLanguage(e.value)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </SelectContainer>
                  {fullScore !== null && sectionScore !== null ? (
                    <ScoreSection>
                      <Text
                        fontWeight="bold"
                        color="#3E474F"
                        letterSpacing={1}
                        fontSize={'18px'}
                      >
                        Similarity Score
                      </Text>
                      <Score bg="#879DC9">
                        <Text fontWeight="bold">Full Text:</Text>
                        <Box alignItems="center" width="47%">
                          {rtaCompareStore.isFullScoreLoading ? (
                            <Loader
                              type="ThreeDots"
                              color="white"
                              height={16}
                              width={16}
                            />
                          ) : (
                            <Text fontWeight="bold">
                              {fullScore.toFixed(2)}
                            </Text>
                          )}
                        </Box>
                      </Score>
                      <Score bg="#5e6d8c">
                        <Text fontWeight="bold">Section:</Text>
                        <Box alignItems="center" width="47%">
                          {rtaCompareStore.isSectionScoreLoading ? (
                            <Loader
                              type="ThreeDots"
                              color="white"
                              height={20}
                              width={20}
                            />
                          ) : (
                            <Text fontWeight="bold">
                              {sectionScore.toFixed(2)}
                            </Text>
                          )}
                        </Box>
                      </Score>
                    </ScoreSection>
                  ) : null}
                </TabTextHeader>
              </TabPanel>
            </TextHeader>
            <TabPanel value={tab} index={1}>
              <CustomInput
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                onKeyDown={handleOnChange}
                before={<Icon icon={faSearch} />}
                placeholder="Search for a keyword"
                width="100%"
              />
            </TabPanel>
            <DocumentContainer>
              <CompareViewer
                id={1}
                currTab={tab}
                title={metadata1.name}
                sections={rtaSections1}
                text={rtaCompareStore.reformatText(rtaText1)}
                currPageNum={rtaNav1.pageNum}
                pageCount={rtaPageCount1}
                higlightedText={diffHTML[0]}
                isLoading={isLoading}
                metadata={metadata1}
                onRTASelect={() => setKeyword('')}
                onSectionSelect={(option) => {
                  setRTAText1Loading(true)
                  rtaCompareStore.setSectionScoreLoading(true)
                  rtaCompareStore
                    .fetchPageRange('RTA_ID_1', [1, 10], option.value)
                    .then(() => {
                      setRTAText1Loading(false)
                      rtaCompareStore.fetchSectionScore(
                        rtaCompareStore.rtaNav1.sectionID,
                        rtaCompareStore.rtaNav2.sectionID
                      )
                    })
                  rtaCompareStore.clearKeywordMatches()
                }}
                rtaNav={rtaNav1}
                rtaId={rtaCompareStore.rtaID1}
                titleIndex={rtaViewStore.rtaOptions.findIndex(
                  (rta) => rta.label === metadata1.name
                )}
                keyword={keyword}
                loading={rta1Loading}
                textLoading={rtaText1Loading}
              />
              <CompareViewer
                id={2}
                isLoading={isLoading}
                currTab={tab}
                title={metadata2.name}
                sectionScore={sectionScore}
                fullScore={fullScore}
                sections={rtaSections2}
                text={rtaCompareStore.reformatText(rtaText2)}
                currPageNum={rtaNav2.pageNum}
                pageCount={rtaPageCount2}
                metadata={metadata2}
                higlightedText={diffHTML[1]}
                onRTASelect={() => setKeyword('')}
                onSectionSelect={(option) => {
                  setRTAText2Loading(true)
                  rtaCompareStore.setSectionScoreLoading(true)
                  rtaCompareStore
                    .fetchPageRange('RTA_ID_2', [1, 10], option.value)
                    .then(() => {
                      setRTAText2Loading(false)
                      rtaCompareStore.fetchSectionScore(
                        rtaCompareStore.rtaNav1.sectionID,
                        rtaCompareStore.rtaNav2.sectionID
                      )
                    })
                  rtaCompareStore.clearKeywordMatches()
                }}
                rtaNav={rtaNav2}
                rtaId={rtaCompareStore.rtaID2}
                titleIndex={rtaViewStore.rtaOptions.findIndex(
                  (rta) => rta.label === metadata2.name
                )}
                keyword={keyword}
                loading={rta2Loading}
                textLoading={rtaText2Loading}
              />
            </DocumentContainer>
            <Footer>
              <StyledButton
                variant={'primary'}
                buttonColor={'#5E6D8C'}
                mb={4}
                mr={3}
                onClick={displaySimilarity}
              >
                {isLoading ? (
                  <Box flexDirection="row" alignItems="center">
                    <Text mr={2} fontWeight={'bold'}>
                      {' '}
                      Calculating
                    </Text>
                    <Loader
                      type="ThreeDots"
                      color="white"
                      height={20}
                      width={20}
                    />
                  </Box>
                ) : (
                  `Display Similarities`
                )}
              </StyledButton>
              <StyledButton
                mb={4}
                buttonColor={'#5E6D8C'}
                onClick={clearSimilarity}
              >
                Clear Differences
              </StyledButton>
            </Footer>
          </div>
        )}
      </Content>
    </Root>
  )
})

export default ComparePage
